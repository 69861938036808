import {
  EButton,
  EModal,
  ESectionDivider,
  ETextInput,
} from "../../../../../libs/ui";
import useInvestigation from "../hooks/useInvestigation";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { HiOutlineArrowUpTray } from "react-icons/hi2";
import { MdOutlinePermMedia } from "react-icons/md";
import moment from "moment";
import { DateInput, DateTimePicker } from "@mantine/dates";
import EMediaItem from "../../../../../libs/ui/src/EMediaItem";

export default function InvestigationForm() {
  const {
    haematology,
    handleChangeHaematology,
    lft,
    handleChangeLft,
    bioChemistry,
    handleChangeBioChemistry,
    setUploadFileModalIsOpen,
    uploadFileModalIsOpen,
    handleOpenMediaInNewTab,
    handleDeleteImageFromAws,
    setFiles,
    handleUploadFiles,
    mediaFiles,
    isMediaBtnLoading,
    files,
    dateTime,
    setDateTime,
    isOpenDeleteConfirmModal,
    setIsOpenDeleteConfirmModal,
  } = useInvestigation();

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-9 border-r pr-3">
        <DateInput
          size="md"
          className="w-1/4"
          label="Date - Time"
          placeholder="Select date"
          value={dateTime ? new Date(dateTime) : undefined}
          onChange={(value) =>
            setDateTime(moment(value).format("YYYY-MM-DD HH:mm"))
          }
        />
        <ESectionDivider title="HAEMATOLOGY" />
        <div className="flex flex-wrap gap-4 items-end">
          {haematology.map((e, index) =>
            e?.key == "dlc" ? (
              <div className="flex gap-2 items-end" key={e?.key + index}>
                <ETextInput
                  size="md"
                  label={e?.name}
                  placeholder={e?.placeholder || ""}
                  value={e?.value1}
                  maxLength={14}
                  // error={
                  //   e?.value1?.split("-")?.length
                  //     ? e?.value1
                  //         ?.split("-")
                  //         ?.reduce(
                  //           (accumulator, currentValue) =>
                  //             accumulator + Number(currentValue),
                  //           0
                  //         ) > 100
                  //       ? "(P+L+M+B+E=100) Limit Exceeded!"
                  //       : ""
                  //     : ""
                  // }
                  onChange={(event) => {
                    //  if(!isNaN(+event?.target?.value)) {
                    if (event.target.value?.length < e?.value1?.length) {
                      event.target.value.split("-")[
                        event.target.value.split("-").length - 1
                      ]?.length == 0
                        ? handleChangeHaematology(
                          e.key,
                          event.target.value.slice(
                            0,
                            event?.target?.value.length - 1
                          ),
                          "first"
                        )
                        : handleChangeHaematology(
                          e.key,
                          event.target.value,
                          "first"
                        );
                    } else {
                      event.target.value.split("-").length < 5 &&
                        event.target.value.split("-")[
                          event.target.value.split("-").length - 1
                        ]?.length == 2
                        ? handleChangeHaematology(
                          e.key,
                          event.target.value + "-",
                          "first"
                        )
                        : handleChangeHaematology(
                          e.key,
                          event.target.value,
                          "first"
                        );
                      //  event.target.value.split("-").length > 5 || event.target.value.split("-")[event.target.value.split("-").length -1]?.length > 2 ? console.log(" ") : handleChangeHaematology(e.key , event.target.value,"first")
                    }
                  }}
                // }
                />
                <ETextInput
                  size="md"
                  label=" "
                  maxLength={14}
                  placeholder={e?.placeholder || ""}
                  // error={
                  //   e?.value2?.split("-")?.length
                  //     ? e?.value2
                  //         ?.split("-")
                  //         ?.reduce(
                  //           (accumulator, currentValue) =>
                  //             accumulator + Number(currentValue),
                  //           0
                  //         ) >= 100
                  //       ? "(P+L+M+B+E=100) Limit Exceeded!"
                  //       : ""
                  //     : ""
                  // }
                  value={e?.value2}
                  onChange={(event) => {
                    if (event.target.value?.length < e?.value2?.length) {
                      event.target.value.split("-")[
                        event.target.value.split("-").length - 1
                      ]?.length == 0
                        ? handleChangeHaematology(
                          e.key,
                          event.target.value.slice(
                            0,
                            event?.target?.value.length - 1
                          ),
                          "second"
                        )
                        : handleChangeHaematology(
                          e.key,
                          event.target.value,
                          "second"
                        );
                    } else {
                      event.target.value.split("-").length < 5 &&
                        event.target.value.split("-")[
                          event.target.value.split("-").length - 1
                        ]?.length == 2
                        ? handleChangeHaematology(
                          e.key,
                          event.target.value + "-",
                          "second"
                        )
                        : handleChangeHaematology(
                          e.key,
                          event.target.value,
                          "second"
                        );
                      //  event.target.value.split("-").length > 5 || event.target.value.split("-")[event.target.value.split("-").length -1]?.length > 2 ? console.log(" ") : handleChangeHaematology(e.key , event.target.value,"first")
                    }
                  }}
                />
              </div>
            ) : (
              <div className="flex gap-2 items-end" key={e?.key + index}>
                <ETextInput
                  size="md"
                  label={e?.name}
                  placeholder={e?.placeholder || ""}
                  value={e?.value1}
                  maxLength={
                   7
                  }
                  onChange={(event) => {
                    handleChangeHaematology(e.key, event.target.value, "first");
                  }}
                />
                <ETextInput
                  size="md"
                  label=" "
                  maxLength={
                    7
                  }
                  placeholder={e?.placeholder || ""}
                  value={e?.value2}
                  onChange={(event) => {
                    handleChangeHaematology(
                      e.key,
                      event.target.value,
                      "second"
                    );
                  }}
                />
              </div>
            )
          )}
        </div>
        <ESectionDivider title="LIVER FUNCTION TESTS (LFT)" classes="mt-5" />
        <div className="flex flex-wrap gap-4">
          {lft.map((e, index) => (
            <div className="flex gap-2" key={e?.key + index}>
              <ETextInput
                size="md"
                label={e?.name}
                maxLength={7}
                placeholder={e?.placeholder || ""}
                value={e?.value1}
                onChange={(event) =>
                  handleChangeLft(e.key, event.target.value, "first")
                }
              />
              <ETextInput
                size="md"
                label=" "
                maxLength={7}
                placeholder={e?.placeholder || ""}
                value={e?.value2}
                onChange={(event) =>
                  handleChangeLft(e.key, event.target.value, "second")
                }
              />
            </div>
          ))}
        </div>

        <ESectionDivider title="BIOCHEMISTRY" classes="mt-5" />
        <div className="flex flex-wrap gap-4">
          {bioChemistry.map((e, index) => (
            <div className="flex gap-2" key={e?.key + index}>
              <ETextInput
                size="md"
                label={e?.name}
                maxLength={7}
                placeholder={e?.placeholder || ""}
                value={e?.value1}
                onChange={(event) =>
                  handleChangeBioChemistry(e.key, event.target.value, "first")
                }
              />
              <ETextInput
                size="md"
                label=" "
                maxLength={7}
                placeholder={e?.placeholder || ""}
                value={e?.value2}
                onChange={(event) =>
                  handleChangeBioChemistry(e.key, event.target.value, "second")
                }
              />
            </div>
          ))}
        </div>
      </div>

      <div className="col-span-3 px-3 ">
        <div
          className=" mx-5 p-5 w-full flex flex-col items-center justify-center border border-slate-400 border-dashed  rounded-md cursor-pointer"
          onClick={() => {
            setUploadFileModalIsOpen(true);
          }}
        >
          <MdOutlinePermMedia size={33} />
          <div className="mt-2 text-lg font-semibold">Upload Image / PDF</div>
        </div>

        <div className="mt-5">
          {mediaFiles.length > 0 ? (
            <ESectionDivider title="Uploaded Media" />
          ) : (
            <></>
          )}
          {mediaFiles?.map((item, index) => {
            return (
              <EMediaItem
                key={"media" + index}
                item={item}
                handleOpenMediaInNewTab={handleOpenMediaInNewTab}
                setIsOpenDeleteConfirmModal={setIsOpenDeleteConfirmModal}
                isOpenDeleteConfirmModal={isOpenDeleteConfirmModal}
                handleDeleteImageFromAws={handleDeleteImageFromAws}
              />
            );
          })}
        </div>
      </div>

      <EModal
        isOpen={uploadFileModalIsOpen}
        title={"Upload Media"}
        size="sm"
        setIsOpen={() => {
          setUploadFileModalIsOpen(false);
        }}
        onClose={()=>{
          setFiles([]);
        }}
      >
        <div className="flex justify-end">
          <div className="flex-row">
            <Dropzone
              className="w-80"
              onDrop={(file) => {
                setFiles(file);
              }}
              accept={[
                MIME_TYPES.png,
                MIME_TYPES.jpeg,
                MIME_TYPES.webp,
                MIME_TYPES.pdf,
                MIME_TYPES.heic
              ]}
            >
              <div className="flex justify-center flex-col items-center text-center">
                <MdOutlinePermMedia size={33} />
                {files?.length > 0 ? (
                  <p>Upload Again Picture / pdf</p>
                ) : (
                  <p>Upload Picture / pdf</p>
                )}
              </div>
            </Dropzone>

            <div className="mt-4 max-h-44 overflow-y-scroll">
              {files?.map((file, index) => {
                return (
                  <div
                    key={index + "file"}
                    className="gap-2 lowercase text-sm font-medium"
                  >
                    {index + 1 + ". " + file.name}
                  </div>
                );
              })}
            </div>

            {!files?.length ? (
              <></>
            ) : (
              <div className=" flex gap-3 justify-around mt-8">
                <EButton
                  variant="secondary"
                  className="mt-4"
                  onClick={() => {
                    setFiles([]);
                    setUploadFileModalIsOpen(false);
                  }}
                >
                  Cancel
                </EButton>
                <EButton
                  className="mt-4"
                  loading={isMediaBtnLoading}
                  onClick={() => handleUploadFiles(files)}
                >
                  <HiOutlineArrowUpTray className="mr-2" />
                  Upload Media
                </EButton>
              </div>
            )}
          </div>
        </div>
      </EModal>
    </div>
  );
}
