import React, { useContext, useEffect, useState } from "react";
import { IPayloadForGBCPresentation } from "../../../../../types";
import { useDirectusApi } from "../../../../../libs/data-access";
import { showToast } from "../../../../../utils/utility";
import { PatientNewVisitContext } from "../../../PatientsContext";
import { useSearchParams } from "react-router-dom";

export default function useGBCClinicalPresentation() {
  const { postDirectData, fetchDirectData, patchDirectData } = useDirectusApi();
  const { activeTab,
    isBtnNextClick,
    setIsBtnLoading,
    handleNextTab,
    isFormUpdating,
    setIsFormUpdating } = useContext(PatientNewVisitContext);

  const [searchParams] = useSearchParams();
  const patient_id = searchParams.get("patient_id");
  const visit_id = searchParams.get("visit_id");

  const [payloadForGBCClinicalPresentation, setPayloadForGBCClinicalPresentation,] = useState<IPayloadForGBCPresentation>(
    {
    status: "published",
    user_id: patient_id + "",
    visit_id: visit_id + "",
    past_history_of_gsd_x_value: "",
    past_history_of_gsd_desc: "",
    duration_of_gsd_x_value: "",
    duration_of_gsd_desc: "",
    past_history_of_biliary_colic_x_value: "",
    past_history_of_biliary_colic_desc: "",
    pain_x_value: "",
    jaundice_x_value: "",
    pain_desc: "",
    jaundice_desc: "",
    pruritis_x_value: "",
    pruritis_desc: "",
    fever_x_value: "",
    fever_desc: "",
    cholangitis_x_value: "",
    cholangitis_desc: "",
    abd_distension_x_value: "",
    abd_distension_desc: "",
    features_of_goo_x_value: "",
    features_of_goo_desc: "",
    anorexia_x_value: "",
    ho_lump_x_value: "",
    ho_lump_desc: "",
    wt_loss_x_value: "",
    anorexia_desc: "",
    hepatomegaly_x_value: "",
    wt_loss_desc: "",
    gb_lump_size_x_value: "",
    gb_lump_size_desc: "",
    hepatomegaly_desc: "",
    supraclavicular_ln_x_value: "",
    supraclavicular_ln_desc: "",
    post_cholecystectomy_scar_x_value: "",
    post_cholecystectomy_scar_desc: "",
    ascites_x_value: "",
    ascites_desc: "",
    tenderness_x_value: "",
    tenderness_desc: "",
  });

  useEffect(() => {
    if (isBtnNextClick && activeTab == "gbc_clinical_presentation") {
      postGBCClinicalPresentation()
    }
  }, [isBtnNextClick])
  
  useEffect(()=>{
    if(activeTab == "gbc_clinical_presentation"){
    getDetailsByVisitId()
  }
  },[activeTab])
  
  const handleChangePayload = (key: keyof IPayloadForGBCPresentation , value: string) => {
    setPayloadForGBCClinicalPresentation((prev) => ({ ...prev, [key]: value }));
  }

  // API FUNCTION
  const getDetailsByVisitId = async () => {
    const res = await fetchDirectData(`items/gbc_clinical_presentation?filter[visit_id][_eq]=${visit_id}`);
    if (res.isSuccess) {
      if (res?.data?.length) {
        setPayloadForGBCClinicalPresentation(res.data[0]);
        setIsFormUpdating(true);
      }
      else{
        setIsFormUpdating(false)
      }
    }
    else{
      showToast({
        type: "error",
        message: "Something went wrong"
      })
    }
  }

  // const postGBCClinicalPresentation = async () => {
  //   const res = await postDirectData("items/gbc_clinical_presentation", payloadForGBCClinicalPresentation);
  //   if (res.isSuccess) {
  //     showToast({
  //       type: "success",
  //       message: "Radiology form submitted successfully",
  //     });
  //     handleNextTab();
  //   } else {
  //     showToast({
  //       type: "error",
  //       title: "Please try again",
  //       message: res.message || "Error while try to upload radiology",
  //     });
  //   }
  // };

  const postGBCClinicalPresentation = async () => {
      setIsBtnLoading(true);
      if(isFormUpdating){ 
        const res = await patchDirectData(`items/gbc_clinical_presentation/${payloadForGBCClinicalPresentation?.id}`, payloadForGBCClinicalPresentation);
      if (res.isSuccess) {
        showToast({
          type: "success",
          title: "GBC Clinical updated successfully",
          message: "GBC Clinical Updated",
        });
        handleNextTab()
      } else {
        showToast({
          type: "error",
          message: res?.message || "Failed to add GBC Clinical",
        });
      }}
      else{ 
        const res = await postDirectData(`items/gbc_clinical_presentation`, payloadForGBCClinicalPresentation);
      if (res.isSuccess) {
        showToast({
          type: "success",
          title: "Added GBC Clinical successfully",
          message: "GBC Clinical added",
        });
        handleNextTab()
      } else {
        showToast({
          type: "error",
          message: res?.message || "Failed to add GBC Clinical",
        });
      }}
      setIsBtnLoading(false);
  };

  return {
    payloadForGBCClinicalPresentation,
    setPayloadForGBCClinicalPresentation,
    handleChangePayload,
  };
}
