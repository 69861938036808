import { Grid, Space } from "@mantine/core";
import React from "react";
import "../../../../App.scss";

interface IProps {
  children: React.ReactNode;
  footer?: React.ReactNode;
  header?: React.ReactNode;
  rightPanel?: React.ReactNode;
  leftPanel?: React.ReactNode;
}

export default function SecondaryPageLayout({
  children,
  header,
  rightPanel,
  leftPanel,
  footer,
}: IProps) {
  return (
    <Grid>
      <Grid.Col className="" span={leftPanel ? 3 : 0}>
        {leftPanel}
      </Grid.Col>

      {/* <Grid.Col span={leftPanel ? 6 : 12}> */}
      <Grid.Col
        className="mb-16"
        span={{ base: 12, md: leftPanel ? 9 : 12, lg: leftPanel ? 8 : 10 }}
      >
        {children}
      </Grid.Col>

      {footer && (
        <div className="h-14 bg-white absolute bottom-0 flex flex-row justify-end right-0 border-t w-full">
          {footer}
        </div>
      )}
    </Grid>
  );
}
