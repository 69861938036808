import React, { useContext, useEffect } from "react";
import { EButton, EEmpty, EPageTitle, ETable, ETextInput } from "../../libs/ui";
import { Pagination, SegmentedControl, Skeleton, Table } from "@mantine/core";
import usePatientList from "./hook/usePatientList";
import { useNavigate } from "react-router-dom";
import PageLayout from "../../PageLayout";
import { EStat } from "../../libs/ui/src/Stat/EStat";
import useStats from "../../hooks/useStats";
import useReport from "../../hooks/useReport";
import { AppContext } from "../../AppContext";
import { BsSearch } from "react-icons/bs";
import { showToast } from "../../utils/utility";
import { FaUserPlus } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";

export default function PatientList() {
  const {
    isLoading,
    patientList,
    handleGetPatientList,
    currentPage,
    totalPage,
    filterValue,
    setFilterValue,
    setSelectedFilter,
    selectedFilter,
  } = usePatientList();
  const { setColorScheme } = useContext(AppContext)
  const { stats, handleFetchStats } = useStats();
  const { handleDownloadBaseLineReport, handleDownloadFollowUpReport } = useReport();

  useEffect(() => {
    setColorScheme({ head: "", body: "", text: "", footer: "" })
    handleFetchStats();
  }, []);

  const { role, user } = useContext(AppContext);
  // const centerId = localStorage.getItem("centerId");
  const navigate = useNavigate();

  useEffect(() => {
    if (role?.includes("Center")) {
      if (!user.id) {
        showToast({
          type: "error",
          title: "Something went wrong",
          message: "Please re-login and try again",
        })
      }
      else {
        handleGetPatientList(1, user?.id);
      }
    } else {
      handleGetPatientList(1);
    }
  }, [filterValue, selectedFilter]);
  return (
    <PageLayout
      header={
        <EPageTitle
          title="Patients"
          end={
            <>
              {
                role?.includes("Center") && !user?.id ?
                  <></>
                  :
                  <>
                    <EButton
                      className="btn-blue"
                      size="md"
                      variant="secondary"
                      onClick={() => {
                        handleDownloadFollowUpReport(role?.includes("Center") ? (user?.id ?? "") : undefined);
                      }}
                    >
                      <FiDownload className="" />  Download Followup Report
                    </EButton>
                    <EButton
                      className="btn-blue"
                      size="md"
                      variant="secondary"
                      onClick={() => {
                        handleDownloadBaseLineReport(role?.includes("Center") ? (user?.id ?? "") : undefined);
                      }}
                    >
                      <FiDownload className="" /> Download Base Line Report
                    </EButton>
                  </>
              }

              {role?.includes("Center") ?

                <EButton
                  className="btn-blue"
                  size="md"
                  onClick={() => {
                    navigate("/index/new-patient");
                  }}
                >
                  <FaUserPlus className="" /> Add New Patient
                </EButton>


                : <></>}



            </>
          }
        />
      }
    >
      <>
        <div>

          {/* <div className="mb-10 text-5xl font-bold text-emerald-800 text-center">
          {"To prevent Gall Bladder Cancer"}
        </div> */}

          <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
            {/* <EStat
              stat={stats?.total_participants ?? 0}
              label="Participants"
              iconSrc={require("../../assets/icons/icon-participant.png")}
            /> */}
            <EStat
              stat={stats?.symptomatic_gallstone_patients ?? 0}
              label="Symptomatic Gallstone"
              // iconSrc={require("../../assets/icons/icon-patients.png")}
              text={"SGS"}
              onBaselineDownload={role?.includes("Center") && !user?.id ? undefined : () => {
                handleDownloadBaseLineReport(user?.id ? user?.id : undefined, "symptomatic_gallstone")
              }}

              onFollowupDownload={role?.includes("Center") && !user?.id ? undefined : () => {
                handleDownloadFollowUpReport(user?.id ? user?.id : undefined, "symptomatic_gallstone")
              }}
            />{" "}
            <EStat
              stat={stats?.asymptomatic_gallstone_patients ?? 0}
              label="Asymptomatic Gallstone"

              text={"AGS"}
              onBaselineDownload={role?.includes("Center") && !user?.id ? undefined : () => {
                handleDownloadBaseLineReport(user?.id ? user?.id : undefined, "asymptomatic_gallstone")
              }}

              onFollowupDownload={role?.includes("Center") && !user?.id ? undefined : () => {
                handleDownloadFollowUpReport(user?.id ? user?.id : undefined, "asymptomatic_gallstone")
              }}

            />{" "}
            <EStat
              stat={stats?.total_patients ?? 0}
              label="Total Patient"
              iconSrc={require("../../assets/icons/icon-patients.png")}
              onBaselineDownload={() =>
                handleDownloadBaseLineReport()
              }
              onFollowupDownload={() =>
                handleDownloadFollowUpReport()
              }

            />
            <EStat
              stat={stats?.gallbladder_cancer_patients ?? 0}
              label="Gallbladder Cancer"
              // iconSrc={require("../../assets/icons/icon-patients.png")}
              text={"GBC"}
              onBaselineDownload={role?.includes("Center") && !user?.id ? undefined : () => {
                handleDownloadBaseLineReport(user?.id ? user?.id : undefined, "gallbladder_cancer")
              }}

              onFollowupDownload={role?.includes("Center") && !user?.id ? undefined : () => {
                handleDownloadFollowUpReport(user?.id ? user?.id : undefined, "gallbladder_cancer")
              }}
            />{" "}
            <EStat
              stat={stats?.non_gallstone_control_patients ?? 0}
              label="Non Gallstone Control"
              // iconSrc={require("../../assets/icons/icon-patients.png")}
              text={"NGC"}
              onBaselineDownload={role?.includes("Center") && !user?.id ? undefined : () => {
                handleDownloadBaseLineReport(user?.id ? user?.id : undefined, "non_gallstone_control")
              }}

              onFollowupDownload={role?.includes("Center") && !user?.id ? undefined : () => {
                handleDownloadFollowUpReport(user?.id ? user?.id : undefined, "non_gallstone_control")
              }}
            />
          </div>
        </div>

        <div>
          <div className="flex justify-end my-3 gap-5">
            <SegmentedControl
              value={selectedFilter}
              onChange={(e) => setSelectedFilter(e === 'included' ? "included" : e === 'excluded' ? 'excluded' : 'all')}
              data={[
                { label: <span className="px-8">All</span>, value: 'all', },
                { label: <span className="px-8">Included</span>, value: 'included' },
                { label: <span className="px-8">Excluded</span>, value: 'excluded' },
              ]}
            />
            <ETextInput
              className="w-1/3"
              placeholder="Search by name/ mobile number / hospital number"
              rightSection={
                <BsSearch
                  className="cursor-pointer text-blue-600"
                  size={22}
                  onClick={() => {
                    if (role?.includes("Center")) {
                      if (!user.id) {
                        showToast({
                          type: "error",
                          title: "Something went wrong",
                          message: "Please re-login and try again",
                        })
                      }
                      else {
                        handleGetPatientList(1, user?.id);
                      }
                    } else {
                      handleGetPatientList(1);
                    }
                  }}
                />
              }
              value={filterValue}
              onChange={(e) => setFilterValue(e?.target?.value)}
            />
          </div>
          {
            isLoading ?
              <ETable>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>Name</Table.Th>
                    <Table.Th>Mobile Number</Table.Th>
                    <Table.Th>Alternate Mobile Number</Table.Th>
                    <Table.Th>Hospital Number</Table.Th>
                    <Table.Th>Recruitment Date</Table.Th>
                    <Table.Th>Address</Table.Th>
                    <Table.Th>Father Name</Table.Th>
                    <Table.Th>Mother Name</Table.Th>
                  </Table.Tr>
                  <Table.Tr>{
                    [1, 2, 3, 4, 5, 6, 7, 8].map(e =>
                      <Table.Td>
                        <Skeleton height={40} />
                      </Table.Td>
                    )}
                  </Table.Tr>
                  <Table.Tr>{
                    [1, 2, 3, 4, 5, 6, 7, 8].map(e =>
                      <Table.Td>
                        <Skeleton height={40} />
                      </Table.Td>
                    )}
                  </Table.Tr>
                </Table.Thead>
              </ETable>
              :
              patientList?.length ? (
                <ETable>
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th>Name</Table.Th>
                      <Table.Th>Mobile Number</Table.Th>
                      <Table.Th>Alternate Mobile Number</Table.Th>
                      <Table.Th>Hospital Number</Table.Th>
                      <Table.Th>Recruitment Date</Table.Th>
                      <Table.Th>Address</Table.Th>
                      <Table.Th>Father Name</Table.Th>
                      <Table.Th>Mother Name</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {patientList?.map((e) => (
                      <Table.Tr
                        key={e.center_id}
                        className={`${role?.includes("Center") ? "cursor-pointer" : ""}`}
                        onClick={() =>
                          role?.includes("Center") && navigate(`/index/patient/details?patient_id=${e?.user_id}`)
                        }
                      >
                        <Table.Td>
                          <div>
                            {e?.first_name +
                              " " +
                              e?.last_name +
                              ` (${e?.age}, ${e?.gender?.charAt(0).toUpperCase()})`}
                          </div>
                        </Table.Td>
                        <Table.Td>{e?.primary_contact_no}</Table.Td>
                        <Table.Td>{e?.alternate_contact_no}</Table.Td>
                        <Table.Td>{e?.hospital_no}</Table.Td>
                        <Table.Td>{e?.recruitment_date}</Table.Td>
                        <Table.Td>{e?.address}</Table.Td>
                        <Table.Td>{e?.father_name}</Table.Td>
                        <Table.Td>{e?.mother_name}</Table.Td>
                      </Table.Tr>
                    ))}
                  </Table.Tbody>
                </ETable>
              ) : (
                <EEmpty title="Not found any patient" description="Please add a new Patient" />
              )}
        </div>

        <div className="flex justify-between">
          <div></div>
          <Pagination
            size="sm"
            value={currentPage}
            onChange={(e) => {
              if (role?.includes("Center")) {
                if (!user.id) {
                  showToast({
                    type: "error",
                    title: "Something went wrong",
                    message: "Please re-login and try again",
                  })
                }
                else {
                  handleGetPatientList(e, user?.id);
                }
              } else {
                handleGetPatientList(e);
              }
            }}
            total={totalPage}
          />
        </div>
      </>
    </PageLayout>
  );
}
