import { NumberInput, NumberInputProps } from '@mantine/core'
import React from 'react'

export default function EAgeInput(props: NumberInputProps) {
  return (
    <NumberInput
            label={props.label}
            size={props.size}
            required={props.required}
            className={props.className}
            description={props.description}
            hideControls={props.hideControls ? props.hideControls : true }
            value={props.value || ""}
            min={props.min ? props.min :  0}
            max={props.max ? props.max :  120}
            maxLength={3}
            placeholder={props.placeholder}
            error={props.error}
            onChange={props.onChange}
        />
  )
}
