import { Button, Checkbox, Modal } from "@mantine/core";
import {
  ENumberInput,
  ESectionDivider,
  ETextInput,
  ETimeInput,
  ETextArea,
  ESelect,
  FormSectionDivider,
  EMultiSelect,
} from "../../../../libs/ui";
import useDietaryDeterminants from "./hooks/useDietaryDeterminants";
import { nonVegDietMaster } from "../../../../libs/data-access/utility";
import { DefaultINewDiet } from "../../../../DefaultValue";
import { useEffect } from "react";
import { IFoodType } from "../../../../types";
import { EMultiSelectToString } from "../../../../libs/ui/src/MultiSelect/EMultiSelectToString";
import EMultiCheckBoxToString from "../../../../libs/ui/src/CheckBox/EMultiCheckBoxToString";
import { ETextInputWithSelect } from "../../../../libs/ui/src/TextInput/ETextInputWithSelect";

type IProps = {
  viewOnly?: boolean;
};

export default function DietaryDeterminants({ viewOnly }: IProps) {

  const {
    isOpenNewDietModal,
    setIsOpenNewDietModal,
    diet,
    setDiet,
    newDiet,
    setNewDiet,
    stapleDiet,
    setStapleDiet,
    typeOfFats,
    setTypeOfFats,
    textInputs,
    setTextInputs,
    useOfRepeatedlyHeatedOil,
    setUseOfRepeatedlyHeatedOil,
    dailyConsumptionOfFood,
    setDailyConsumptionOfFood,
    sourceOfFood,
    setSourceOfFood,
    storage,
    setStorage,
    weeklyFoodConsumption,
    setWeeklyFoodConsumption,
    redChili,
    greenChili,
    peanut,
    coffee,
    tea,
    setRedChili,
    setGreenChili,
    setPeanut,
    setCoffee,
    setTea,
    types,
    setTypes,
  } = useDietaryDeterminants();

  // useEffect(() => {

  //   console.log(weeklyFoodConsumption, "Weekly food conjuption  ");
  // }, [])

  return (
    <>
      <div>
        <h3 className="mb-3">Dietary Determinants</h3>

        <ESectionDivider title="Diet" classes="mt-8" />
        <div className="flex flex-wrap gap-10">
          {diet?.map((each, index) => (
            <Checkbox
              key={index} // Adding a unique key for each checkbox
              size="md"
              className="w-1/6"
              checked={each?.selected}
              label={each?.label}
              onChange={(e) => {
                const updateDiet = [...diet];
                updateDiet[index] = {
                  ...updateDiet[index],
                  value: null,
                  selected: e.target.checked,
                  frequency: "",
                  quantity: "",
                  type: ""
                }
                // updateDiet.forEach((item: any, index: number) => {
                //   item.selected = false;
                // });
                setDiet(updateDiet);
              }}
            />
          ))}
        </div>

        {diet[2].selected ? (
          <div className="flex flex-wrap">
            <ETextInputWithSelect
              size="md"
              className="mt-2 w-1/2 px-1"
              label={`Number of eggs`}
              placeholder="Number of eggs"
              splitValue={"per "}
              options={[
                { label: "Week", value: "per week" },
                { label: "Month", value: "per month" },
                { label: "Year", value: "per year" },
              ]}
              value={diet[2]?.selected
                ? diet[2]?.frequency
                : ""}
              onChange={(value) => {
                const updateDiet = [...diet];
                updateDiet[2].frequency = value;

                setDiet(updateDiet);
              }}
            />
            {/* <ETextInput
              size="md"
              className="mt-2 w-1/2 px-1"
              label="Number of eggs/weeks"
              placeholder="Number of eggs/weeks"
              value={
                   diet[2]?.selected
                    ? diet[2]?.frequency
                    : ""
              }
              onChange={(e) => {
                const updateDiet = [...diet];
                  updateDiet[2].frequency = e.target.value;
                
                setDiet(updateDiet);
              }}
            /> */}
          </div>
        ) : (
          <></>
        )}
        {diet[3].selected ? (
          <div className="flex flex-wrap">
            <ETextInputWithSelect
              size="md"
              type="number"
              className="mt-2 w-1/2 px-1"
              label="Number of times (Non-Veg.)"
              placeholder="Number of times"
              splitValue={"per "}
              options={[
                { label: "Week", value: "per week" },
                { label: "Month", value: "per month" },
                { label: "Year", value: "per year" },
              ]}
              value={diet[3]?.selected
                ? diet[3]?.frequency
                : ""}
              onChange={(value) => {
                const updateDiet = [...diet];
                updateDiet[3].frequency = value;

                setDiet(updateDiet);
              }}
            />

            {/* <ETextInput
              size="md"
              className="mt-2 w-1/2 px-1"
              label="Number of times/weeks (Non-Veg.)"
              placeholder="Number of times/weeks"
              value={
                diet[3]?.selected
                  ? diet[3]?.frequency
                  : diet[2]?.selected
                    ? diet[2]?.frequency
                    : ""
              }
              onChange={(e) => {
                const updateDiet = [...diet];
                if (updateDiet[3].selected) {
                  updateDiet[3].frequency = e.target.value;
                } else if (updateDiet[2].selected) {
                  updateDiet[2].frequency = e.target.value;
                }
                setDiet(updateDiet);
              }}
            /> */}
            {diet[3].selected ? (
              <ETextInput
                size="md"
                className="mt-2 w-1/2 px-1"
                label="Amount each time (Non-Veg.)"
                placeholder="Amount each time"
                value={
                  diet[3]?.selected
                    ? diet[3]?.quantity
                    : diet[2]?.selected
                      ? diet[2]?.quantity
                      : ""
                }
                onChange={(e) => {
                  const updateDiet = [...diet];
                  if (updateDiet[3].selected) {
                    updateDiet[3].quantity = e.target.value;
                  } else if (updateDiet[2].selected) {
                    updateDiet[2].quantity = e.target.value;
                  }
                  setDiet(updateDiet);
                }}
              />
            ) : (
              <></>
            )}
            {diet[3].selected ? (
              <EMultiSelectToString
                size="md"
                className="mt-2 w-1/2 px-1"
                label="Type of non-veg"
                data={nonVegDietMaster}
                placeholder="Select"
                value={
                  diet[3]?.selected
                    ? diet[3]?.type ?? ''
                    : diet[2]?.selected
                      ? diet[2]?.type ?? ''
                      : ""
                }
                onChange={(e) => {
                  const updateDiet = [...diet];
                  if (updateDiet[3].selected) {
                    updateDiet[3].type = e ? e : undefined;
                  } else if (updateDiet[2].selected) {
                    updateDiet[2].type = e ? e : undefined;
                  }
                  setDiet(updateDiet);
                }}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}

        <ESectionDivider title="Staple Diet" classes="mt-8" />
        <div className="flex flex-wrap items-end">
          {stapleDiet?.map((each, index) => (
            <Checkbox
              key={index} // Adding a unique key for each checkbox
              className="w-1/6"
              size="md"
              checked={each?.selected}
              label={each?.label}
              onChange={(e) => {
                const updatedStapleDiet = [...stapleDiet];
                let isItTrue = updatedStapleDiet[index].selected;
                updatedStapleDiet.forEach((item: any, index: number) => {
                  item.selected = false;
                });
                updatedStapleDiet[index].selected = !isItTrue;
                updatedStapleDiet[index].remarks = "";
                setStapleDiet(updatedStapleDiet);
              }}
            />
          ))}

          {stapleDiet[3].selected ? (
            <ETextInput
              className="w-1/6"
              placeholder="Other staple diet"
              value={stapleDiet[3]?.remarks}
              onChange={(e) => {
                const updatedStapleDiet = [...stapleDiet];
                if (updatedStapleDiet[3].selected) {
                  updatedStapleDiet[3].remarks = e.target.value;
                } else if (updatedStapleDiet[2].selected) {
                  updatedStapleDiet[2].remarks = e.target.value;
                }
                setStapleDiet(updatedStapleDiet);
              }}
            />
          ) : (
            <></>
          )}
        </div>

        <ESectionDivider title="Type of fats" classes="mt-8" />
        <div className="flex flex-wrap">
          {typeOfFats?.map((each, index) => (
            <>
              <div className="flex gap-3 px-2 justify-between mt-2 w-1/2">
                <Checkbox
                  size="md"
                  key={index}
                  checked={each?.selected}
                  label={each?.label}
                  onChange={() => {
                    const updatedTypeOfFats = [...typeOfFats];
                    //   let isItTrue = updatedTypeOfFats[index].selected;
                    //   updatedTypeOfFats.forEach((item: any, index: number) => {
                    //     item.selected = false;
                    //   });
                    updatedTypeOfFats[index].selected =
                      !updatedTypeOfFats[index].selected;
                    updatedTypeOfFats[index].value = "";
                    setTypeOfFats(updatedTypeOfFats);
                  }}
                />
                {each?.selected ? (
                  each?.label == "Others" ? (
                    <ETextInput
                      size="xs"
                      className="w-1/2"
                      placeholder={
                        each?.label == "Others"
                          ? "Enter fat Details and Quantity"
                          : ""
                      }
                      value={each?.value || ""}
                      onChange={(e) => {
                        const updatedTypeOfFats = [...typeOfFats];
                        //   let isItTrue = updatedTypeOfFats[index].selected;
                        //   updatedTypeOfFats.forEach((item: any, index: number) => {
                        //     item.selected = false;
                        //   });
                        updatedTypeOfFats[index].value = e?.target.value;
                        setTypeOfFats(updatedTypeOfFats);
                      }}
                    />
                  ) : (
                    <ENumberInput
                      size="xs"
                      className="w-1/2"
                      maxLength={5}
                      placeholder={"Quantity (ml per month)"}
                      value={+each?.value || ""}
                      onChange={(e) => {
                        const updatedTypeOfFats = [...typeOfFats];
                        //   let isItTrue = updatedTypeOfFats[index].selected;
                        //   updatedTypeOfFats.forEach((item: any, index: number) => {
                        //     item.selected = false;
                        //   });
                        updatedTypeOfFats[index].value = e ? String(e) : "";
                        setTypeOfFats(updatedTypeOfFats);
                      }}
                    />
                  )
                ) : (
                  <></>
                )}
              </div>
              {/* <Checkbox
              key={index} // Adding a unique key for each checkbox
              className="w-1/4"
              size="md"
              checked={each?.selected}
              label={each?.label}
              onChange={(e) => {
                const updatedTypeOfFats = [...typeOfFats];
                //   let isItTrue = updatedTypeOfFats[index].selected;
                //   updatedTypeOfFats.forEach((item: any, index: number) => {
                //     item.selected = false;
                //   });
                updatedTypeOfFats[index].selected =
                  !updatedTypeOfFats[index].selected;
                setTypeOfFats(updatedTypeOfFats);
              }}
            /> */}
            </>
          ))}
        </div>

        <ESectionDivider title="Use of repeatedly heated oil:" classes="mt-8" />
        <div className="flex flex-wrap ">
          {useOfRepeatedlyHeatedOil?.map((each, index) => (
            <Checkbox
              key={index} // Adding a unique key for each checkbox
              className="w-1/6"
              size="md"
              checked={each?.selected}
              label={each?.label}
              onChange={(e) => {
                const updatedUseOfRepeatedlyHeatedOil = [
                  ...useOfRepeatedlyHeatedOil,
                ];
                let isItTrue = updatedUseOfRepeatedlyHeatedOil[index].selected;
                updatedUseOfRepeatedlyHeatedOil.forEach(
                  (item: any, index: number) => {
                    item.selected = false;
                  }
                );
                updatedUseOfRepeatedlyHeatedOil[index].selected = !isItTrue;

                setUseOfRepeatedlyHeatedOil(updatedUseOfRepeatedlyHeatedOil);
              }}
            />
          ))}
        </div>

        {/* <ESectionDivider
          title="Daily Consumption of food"
          classes="mt-8"
          subTitle="24 hrs. recall method when you were healthy"
          end={
            <EButton
              className="btn-primary"
              onClick={() => {
                setIsOpenNewDietModal(true)
              }}>
              Add Diet
            </EButton>
          }
        />
        {
          !dailyConsumptionOfFood?.length ?
            <>
              <EEmpty
                title="Empty diet table"
                description="Please add a diet for daily consumption of food"
              /> </> :
            <Table
              highlightOnHover
              verticalSpacing={"xs"}
              striped
              className="e-table">
              <thead>
                <tr>
                  <th>Time</th>
                  <th>Meal</th>
                  <th>What</th>
                  <th>How much</th>
                  <th>Quantity (g/ml)</th>
                </tr>
              </thead>
              <tbody>
                {dailyConsumptionOfFood?.map(e => (
                  <tr className="text-center">
                    <td>{e?.time || ""}</td>
                    <td>{e?.meal || ""}</td>
                    <td>{e?.what || ""}</td>
                    <td>{e?.howMuch || ""}</td>
                    <td>{e?.quantity || ""}</td>
                  </tr>
                ))}
              </tbody>
            </Table>} */}

        {/* <ESectionDivider title="Predominant source of food" classes="mt-8" />
        <div className="flex gap-5 flex-wrap mt-2">
          {sourceOfFood?.map((item, index) => (
            <Checkbox
              key={index + "sourceOfFood"} // Adding a unique key for each checkbox
              // className="w-1/4"
              size="md"
              checked={item?.selected}
              label={item?.label}
              onChange={(e) => {
                const updatedSourceOfFood = [...sourceOfFood];
                // let isItTrue = updatedSourceOfFood[index].selected;
                // updatedSourceOfFood.forEach(
                //   (item: any, index: number) => {
                //     item.selected = false;
                //   }
                // );
                updatedSourceOfFood[index].selected =
                  !updatedSourceOfFood[index].selected;

                setSourceOfFood(updatedSourceOfFood);
              }}
            />
          ))}
        </div> */}

        {/* <ESectionDivider title="Storage" classes="mt-2" />
        <div className="flex flex-wrap">
          {storage?.map((item, index) => (
            <Checkbox
              key={index + "Storage"} // Adding a unique key for each checkbox
              className="w-1/6"
              size="md"
              name="Storage"
              checked={item?.selected}
              label={item?.label}
              onChange={(e) => {
                const updatedStorage = [...storage];
                let isItTrue = updatedStorage[index].selected;
                updatedStorage.forEach((item: any, index: number) => {
                  item.selected = false;
                });
                updatedStorage[index].selected = !isItTrue;
                setStorage(updatedStorage);
              }}
            />
          ))}
        </div> */}

        <ESectionDivider title=" Weekly food consumption" classes="mt-2" />
        <div className="flex flex-wrap gap-5 pb-8">
          {weeklyFoodConsumption?.map((each, index) => (
            <ENumberInput
              className="w-1/6"
              size="md"
              maxLength={5}
              placeholder="No. of times a week"
              label={each?.label}
              value={each?.noOfTimes}
              onChange={(e) => {
                let data: IFoodType[] = JSON.parse(JSON.stringify(weeklyFoodConsumption));
                data[index] = {
                  label: data[index]?.label,
                  noOfTimes: e ? e + "" : "",
                };
                setWeeklyFoodConsumption(data);
              }}
            />
          ))}
        </div>

        <ESectionDivider title="Types" classes="mt-2" />
        <div className="">
          {types?.map((each, index) => (
            <div className="grid grid-cols-4 gap-3 mb-4 items-end">
              <p className="text-lg text-center">{each?.label ?? ""}</p>
              <EMultiSelect
                size="md"
                placeholder="Select"
                label="Type"
                value={each?.source ? each?.source.split(",") ? each?.source?.split(",") : [each?.source] : []}
                data={["Supermarket", "Local Shops", "Farms", "Self-produced"]}
                onChange={(e) => {
                  let data: any[] = [...types];
                  data[index].source = e?.join(",");
                  setTypes(data);
                }}
              />
              <ESelect
                size="md"
                placeholder="Select"
                label="Is Packed"
                value={each?.is_packed}
                data={["Packed", "UnPacked", "Both"]}
                onChange={(e) => {
                  let data: any[] = [...types];
                  data[index].is_packed = e;
                  setTypes(data);
                }}
              />
            </div>
          ))}
        </div>
      </div>

      <ESectionDivider title="Food Consumption" />

      <FormSectionDivider titleCenter title={"Red Chili"} />
      <div className="flex gap-6 justify-center">
        <ESelect
          label="Frequency"
          placeholder="Select"
          size="md"
          data={["Never", "Current", "Former"]}
          value={redChili?.n_f_c}
          onChange={(e) =>
            setRedChili((prev) => ({ ...prev, n_f_c: e ?? "", is_regular: "", type_of_food: "" }))
          }
        />
        <ESelect
          label="Regular/ Occasional"
          placeholder="Select"
          size="md"
          data={["Regular", "Occasional"]}
          disabled={redChili?.n_f_c === "Never"}
          value={redChili?.is_regular ?  redChili?.is_regular :null }
         onChange={(e) =>
            setRedChili((prev) => ({ ...prev, is_regular: e ?? "" }))
          }
        />
        <EMultiSelect
          label="Type of food"
          placeholder="Select"
          size="md"
          disabled={redChili?.n_f_c === "Never"}
          data={["Fresh", "Dried", "Powder (homemade)", "Powder (purchased)"]}
          value={redChili?.type_of_food ? redChili?.type_of_food.split(",") : []}
          onChange={(e) =>
            setRedChili((prev) => ({ ...prev, type_of_food: e.join(",") }))
          }
        />
      </div>

      <FormSectionDivider titleCenter title={"Green Chili"} />
      <div className="flex gap-6 justify-center">
        <ESelect
          label="Frequency"
          placeholder="Select"
          size="md"
          data={["Never", "Current", "Former"]}
          value={greenChili?.n_f_c}
          onChange={(e) =>
            setGreenChili((prev) => ({ ...prev, n_f_c: e ?? "", is_regular: "", type_of_food: "" }))
          }
        />
        <ESelect
          label="Regular/ Occasional"
          placeholder="Select"
          size="md"
          data={["Regular", "Occasional"]}
          disabled={greenChili?.n_f_c === "Never"}
          value={greenChili?.is_regular ? greenChili?.is_regular :null }
          onChange={(e) =>
            setGreenChili((prev) => ({ ...prev, is_regular: e ?? "" }))
          }
        />

        <EMultiSelect
          label="Type of food"
          placeholder="Select"
          size="md"
          data={["Fresh", "Dried"]}
          disabled={greenChili?.n_f_c === "Never"}
          value={greenChili?.type_of_food ? greenChili?.type_of_food.split(",") : []}
          onChange={(e) =>
            setGreenChili((prev) => ({ ...prev, type_of_food: e.join(",") }))
          }
        />
      </div>

      <FormSectionDivider titleCenter title={"Peanuts"} />
      <div className="flex gap-6 justify-center">
        <ESelect
          label="Frequency"
          placeholder="Select"
          size="md"
          data={["Never", "Current", "Former"]}
          value={peanut?.n_f_c}
          onChange={(e) =>
            setPeanut((prev) => ({ ...prev, n_f_c: e ?? "" , is_regular: "", type_of_food: ""}))
          }
        />
        <ESelect
          label="Regular/ Occasional"
          placeholder="Select"
          size="md"
          data={["Regular", "Occasional"]}
          disabled={peanut?.n_f_c === "Never"}
          value={peanut?.is_regular ? peanut?.is_regular :null }
          onChange={(e) =>
            setPeanut((prev) => ({ ...prev, is_regular: e ?? "" }))
          }

        />

        <EMultiSelect
          label="Type of food"
          placeholder="Select"
          size="md"
          data={["Raw", "Roasted", "Packed"]}
          disabled={peanut?.n_f_c === "Never"}
          value={peanut?.type_of_food ? peanut?.type_of_food.split(",") : []}
          onChange={(e) =>
            setPeanut((prev) => ({ ...prev, type_of_food: e?.join(",") }))
          }
        />
      </div>

      {/* <FormSectionDivider
        titleCenter
        title={"Tea"}
      />
      <div className="flex gap-6 justify-center">
      <ESelect
        label="Frequency"
        placeholder="Select"
       size="md"
       data={["Never","Former","Current"]}
       value={tea?.n_f_c}
       onChange={(e)=>  setTea(prev=>({...prev,n_f_c: e ??""}))}
      />
      <ESelect
       label="Regular/ Occasional"
       placeholder="Select"
       size="md"
       data={["Regular","Occasional"]} 
       value={tea?.is_regular}
       onChange={(e)=>  setTea(prev=>({...prev,is_regular: e ??""}))}
      />
      
      <ESelect
      label="Type of tea"
      placeholder="Select"
      size="md"
      data={["Regular tea","Black tea","Green tea"]}
      value={tea?.type_of_food}
      onChange={(e)=>  setTea(prev=>({...prev,type_of_food: e ??""}))}
      />
      </div> */}

      {/* <FormSectionDivider
        titleCenter
        title={"Coffee"}
      />
      <div className="flex gap-6 justify-center">
      <ESelect
        label="Frequency"
        placeholder="Select"
       size="md"
       data={["Never","Former","Current"]}
       value={coffee?.n_f_c}
       onChange={(e)=>  setCoffee(prev=>({...prev,n_f_c: e ?? ""}))}
      />
      <ESelect
       label="Regular/ Occasional"
       placeholder="Select"
       size="md"
       data={["Regular","Occasional"]} 
       value={coffee?.is_regular}
       onChange={(e)=>  setCoffee(prev=>({...prev,is_regular: e ??""}))}
      />
      </div> */}

      <Modal
        opened={isOpenNewDietModal}
        title="Add Daily Consumption of food"
        size={"lg"}
        onClose={() => {
          setDailyConsumptionOfFood([]);
          setIsOpenNewDietModal(false);
          setNewDiet(DefaultINewDiet);
        }}
      >
        <form
          onSubmit={(e) => {
            e?.preventDefault();
            setDailyConsumptionOfFood((prev) => [...prev, newDiet]);
            setIsOpenNewDietModal(false);
            setNewDiet(DefaultINewDiet);
          }}
        >
          <div className="flex justify-between">
            <ETimeInput
              className="w-1/4"
              label="Time"
              value={newDiet?.time ?? ""}
              onChange={(e) => {
                setNewDiet((prev) => ({ ...prev, time: e?.target.value }));
              }}
            />
            <ESelect
              label="Meal"
              data={[
                "Early Morning",
                "Breakfast",
                "Mid Morning",
                "Lunch",
                "Evening",
                "Dinner",
                "Post Dinner",
              ]}
              placeholder="Select Meal"
              value={newDiet?.meal}
              onChange={(e) =>
                setNewDiet((prev) => ({ ...prev, meal: e ?? "" }))
              }
            />
          </div>
          <ETextArea
            className="mt-2"
            placeholder="What"
            withAsterisk
            value={newDiet?.what ?? ""}
            onChange={(e) => {
              setNewDiet((prev) => ({ ...prev, what: e?.target.value }));
            }}
          />
          <ETextArea
            className="mt-2"
            placeholder="How much"
            maxLength={16}
            withAsterisk
            value={newDiet?.howMuch ?? ""}
            onChange={(e) => {
              setNewDiet((prev) => ({ ...prev, howMuch: e?.target.value }));
            }}
          />
          <ETextInput
            className="mt-2"
            placeholder="Quantity (eg.20 g / 20 ml )"
            maxLength={6}
            withAsterisk
            value={newDiet?.quantity ?? ""}
            onChange={(e) => {
              setNewDiet((prev) => ({ ...prev, quantity: e?.target.value }));
            }}
          />
          <div className="flex justify-end mt-4">
            <Button type="submit" className="btn-primary">
              Submit
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}
