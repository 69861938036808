import {
  EButton,
  ECheckBox,
  EModal,
  ENumberInput,
  ESectionDivider,
  ESelect,
  ETextInput,
  FormSectionDivider,
} from "../../../../../libs/ui";
import { Checkbox, Grid } from "@mantine/core";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { MdOutlinePermMedia } from "react-icons/md";
import useStoneAnalysis from "../hooks/useStoneAnalysis";
import { HiOutlineArrowUpTray } from "react-icons/hi2";
import { DateInput, DateTimePicker } from "@mantine/dates";
import moment from "moment";
import EMediaItem from "../../../../../libs/ui/src/EMediaItem";
export default function StoneAnalysis() {
  const {
    isMediaBtnLoading,
    mediaFiles,
    stoneAnalysis,
    uploadFileModalIsOpen,
    files,
    handleUploadFiles,
    setFiles,
    handleChangeForm,
    setUploadFileModalIsOpen,
    handleOpenMediaInNewTab,
    handleDeleteImageFromAws,
    payloadForStoneAnalysis,
    setPayloadForStoneAnalysis,
    isOpenDeleteConfirmModal,
    setIsOpenDeleteConfirmModal,
  } = useStoneAnalysis();

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-9 border-r pr-3">
        <div className="flex justify-start px-3">
          <DateInput
            size="md"
            label="Date - Time"
            placeholder="Select date"
            value={
              payloadForStoneAnalysis?.date_time
                ? new Date(payloadForStoneAnalysis?.date_time)
                : undefined
            }
            onChange={(value) =>
              setPayloadForStoneAnalysis((prev) => ({
                ...prev,
                date_time: moment(value).format("YYYY-MM-DD HH:mm"),
              }))
            }
          />
        </div>
        <div className="flex flex-wrap ">
          {stoneAnalysis?.map((each, sectionIndex) => (
            <div className="flex flex-col w-1/2 px-3" key={sectionIndex + "sa"}>
              <FormSectionDivider title={each?.sectionTitle} classes="mb-3" />
              <Grid>
                {each?.body?.map((e, index) => (
                  <Grid.Col
                    key={index + "body"}
                    span={e?.label?.length >= 50 ? 12 : 6}
                    className="flex items-end"
                  >
                    {each?.sectionKey === "size_of_stones" ? (
                      <ENumberInput
                        label={e?.label + ""}
                        placeholder={e?.placeholder}
                        rightSectionWidth={"auto"}
                        rightSection={
                          <select
                            value={
                              payloadForStoneAnalysis?.size_of_stones?.toString().split("-") ?
                                payloadForStoneAnalysis?.size_of_stones?.toString().split("-")[1]
                                : "cm"
                            }
                            style={{ height: "100%", borderRadius: "4px" }}
                            onChange={e =>
                              setPayloadForStoneAnalysis((prev) => ({
                                ...prev,
                                size_of_stones: `${payloadForStoneAnalysis?.size_of_stones?.split("-") ? payloadForStoneAnalysis?.size_of_stones?.split("-")[0] : ""}-${e.target.value}`,
                              }))
                            }
                          >
                            <option value="cm"><p>cm</p></option>
                            <option value="inches"><p>mm</p></option>
                            <option value="inches"><p>inch</p></option>
                          </select>
                        }
                        value={
                          payloadForStoneAnalysis?.size_of_stones?.toString().split("-") ?
                            payloadForStoneAnalysis?.size_of_stones?.toString().split("-")[0]
                            : ""
                        }
                        onChange={(e) => {
                          setPayloadForStoneAnalysis((prev) => ({
                            ...prev,
                            size_of_stones: `${payloadForStoneAnalysis?.size_of_stones?.toString()?.split("-")
                              ?
                              `${e}-${payloadForStoneAnalysis?.size_of_stones?.toString()?.split("-")[1] ?? "cm"}`
                              :
                              e + "-cm"
                            }`,
                          }))
                        }}
                      />
                    ) :
                      e?.placeholder == "Color of stones" ? (
                        <ESelect
                          label={e?.label + ""}
                          value={"" + e?.value}
                          data={e?.data || ["No options"]}
                          searchable={false}
                          placeholder={e?.placeholder}
                          onChange={(event) => {
                            handleChangeForm(
                              each?.sectionTitle,
                              e?.label,
                              event ?? "",
                              sectionIndex,
                              index,
                              e?.name || ""
                            );
                          }}
                        />
                      ) : each?.sectionKey == "weight_of_stone" ? (
                        <div className="flex gap-3">
                          <ENumberInput
                            label={""}
                            value={e?.value ? Number(e?.value) : ""}
                            maxLength={5}
                            miw={"60%"}
                            hideControls
                            placeholder={e?.placeholder}
                            onChange={(event) =>
                              handleChangeForm(
                                each?.sectionTitle,
                                each?.sectionKey,
                                event ?? 0,
                                sectionIndex,
                                index,
                                e?.name || ""
                              )
                            }
                          />
                          <ECheckBox
                            label={"Single"}
                            checked={
                              payloadForStoneAnalysis?.is_weight_of_stone_for_single === true
                            }
                            onChange={(event) =>
                              setPayloadForStoneAnalysis((prev) => ({
                                ...prev,
                                is_weight_of_stone_for_single:
                                  event.target.checked ? true : null,
                              }))
                            }
                          />
                          <ECheckBox
                            label={"Multiple"}
                            checked={
                              payloadForStoneAnalysis?.is_weight_of_stone_for_single ===
                              false
                            }
                            onChange={(event) => {
                              setPayloadForStoneAnalysis((prev) => ({
                                ...prev,
                                is_weight_of_stone_for_single:
                                  event.target.checked ? false : null,
                              }));
                            }}
                          />
                        </div>
                      ) : each?.sectionKey == "stone_cs" ? (
                        <div className="flex flex-wrap gap-2 w-[200%]">
                          <ECheckBox
                            label={"Negative"}
                            checked={e?.value == "Negative"}
                            onChange={(event) =>
                              handleChangeForm(
                                each?.sectionTitle,
                                each?.sectionKey,
                                event.target.checked ? "Negative" : "",
                                sectionIndex,
                                index,
                                e?.name || ""
                              )
                            }
                          />
                          <ECheckBox
                            label={"Positive"}
                            checked={String(e?.value)?.includes("Positive")}
                            onChange={(event) =>
                              handleChangeForm(
                                each?.sectionTitle,
                                each?.sectionKey,
                                event.target.checked ? "Positive" : "",
                                sectionIndex,
                                index,
                                e?.name || ""
                              )
                            }
                          />
                          {String(e?.value)?.includes("Positive") ? (
                            <div>
                              <ECheckBox
                                label={"Single"}
                                checked={e?.value == "Positive-Single"}
                                onChange={(event) =>
                                  handleChangeForm(
                                    each?.sectionTitle,
                                    each?.sectionKey,
                                    event.target.checked ? "Positive-Single" : "Positive",
                                    sectionIndex,
                                    index,
                                    e?.name || ""
                                  )
                                }
                              />

                              <ECheckBox
                                className="mt-2"
                                label={"Multiple"}
                                checked={e?.value === "Positive-Multiple"}
                                onChange={(event) =>
                                  handleChangeForm(
                                    each?.sectionTitle,
                                    each?.sectionKey,
                                    event.target.checked ? "Positive-Multiple" : "",
                                    sectionIndex,
                                    index,
                                    e?.name || ""
                                  )
                                }
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : e?.type == "number" ? (
                        <ENumberInput
                          label={e?.label}
                          value={e?.value ? Number(e?.value) : ""}
                          maxLength={5}
                          hideControls
                          placeholder={e?.placeholder}
                          onChange={(event) =>
                            handleChangeForm(
                              each?.sectionTitle,
                              each?.sectionKey,
                              event ?? 0,
                              sectionIndex,
                              index,
                              e?.name || ""
                            )
                          }
                        />
                      ) : e?.type == "text" ? (
                        <ETextInput
                          label={e?.label + ""}
                          value={"" + e?.value}
                          placeholder={e?.placeholder}
                          onChange={(event) => {
                            handleChangeForm(
                              each?.sectionTitle,
                              e?.label,
                              event?.target.value,
                              sectionIndex,
                              index,
                              e?.name || ""
                            );
                          }}
                        />
                      ) : (
                        <Checkbox
                          label={e?.label}
                          checked={Boolean(e?.value)}
                          onChange={(event) =>
                            handleChangeForm(
                              each?.sectionTitle,
                              each?.sectionKey,
                              event?.target?.checked ? true : false,
                              sectionIndex,
                              index,
                              e?.name || ""
                            )
                          }
                        />
                      )}
                  </Grid.Col>
                ))}
              </Grid>
            </div>
          ))}
        </div>
      </div>
      <div className="col-span-3 px-3 ">
        <div
          className=" mx-5 p-5 w-full flex flex-col items-center justify-center border border-slate-400 border-dashed  rounded-md cursor-pointer"
          onClick={() => {
            setUploadFileModalIsOpen(true);
          }}
        >
          <MdOutlinePermMedia size={33} />
          <div className="mt-2 text-lg font-semibold">Upload Image / PDF</div>
        </div>

        <div className="mt-5">
          {mediaFiles.length > 0 ? (
            <ESectionDivider title="Uploaded Media" />
          ) : (
            <></>
          )}
          {mediaFiles?.map((item, index) => {
            return (
              <EMediaItem
                item={item}
                handleOpenMediaInNewTab={handleOpenMediaInNewTab}
                setIsOpenDeleteConfirmModal={setIsOpenDeleteConfirmModal}
                isOpenDeleteConfirmModal={isOpenDeleteConfirmModal}
                handleDeleteImageFromAws={handleDeleteImageFromAws}
              />
            );
          })}
        </div>
      </div>

      <EModal
        isOpen={uploadFileModalIsOpen}
        title={"Upload Media"}
        size="sm"
        setIsOpen={() => {
          setUploadFileModalIsOpen(false);
        }}
        onClose={()=>{
          setFiles([]);
        }}
      >
        <div className="flex justify-end">
          <div className="flex-row">
            <Dropzone
              className="w-80"
              onDrop={(file) => {
                setFiles(file);
              }}
              accept={[
                MIME_TYPES.png,
                MIME_TYPES.jpeg,
                MIME_TYPES.webp,
                MIME_TYPES.pdf,
                MIME_TYPES.heic
              ]}
            >
              <div className="flex justify-center flex-col items-center text-center">
                <MdOutlinePermMedia size={33} />
                {files?.length > 0 ? (
                  <p>Upload Again Picture / pdf</p>
                ) : (
                  <p>Upload Picture / pdf</p>
                )}
              </div>
            </Dropzone>

            <div className="mt-4 max-h-44 overflow-y-scroll">
              {files?.map((file, index) => {
                return (
                  <div
                    key={index}
                    className="gap-2 lowercase text-sm font-medium"
                  >
                    {index + 1 + ". " + file.name}
                  </div>
                );
              })}
            </div>

            {!files?.length ? (
              <></>
            ) : (
              <div className=" flex gap-3 justify-around mt-8">
                <EButton
                  variant="secondary"
                  className="mt-4"
                  onClick={() => {
                    setFiles([]);
                    setUploadFileModalIsOpen(false);
                  }}
                >
                  Cancel
                </EButton>
                <EButton
                  className="mt-4"
                  loading={isMediaBtnLoading}
                  onClick={() => handleUploadFiles(files)}
                >
                  <HiOutlineArrowUpTray className="mr-2" />
                  Upload Media
                </EButton>
              </div>
            )}
          </div>
        </div>
      </EModal>
    </div>
  );
}
