import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { initialUser } from "../../initialStates";
import { IColorScheme, IDirectusUser} from "../../types";
import { IRoute } from "../../Routes/routes";

export default function useApp() {

    const navigate = useNavigate();

    const [user, setUser] = useState<IDirectusUser>(initialUser);
    const [token, setToken] = useState<string | undefined>(undefined);
    const [role, setRole] = useState<string[] | undefined>(undefined);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean | undefined>(undefined);
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean | undefined>(true);
    const [currentRotes, setCurrentRoutes] = useState<IRoute[]>([]);
    const [colorScheme, setColorScheme] = useState<IColorScheme>({} as IColorScheme);
    const [activeTab, setActiveTab] = useState<string>("");


    /* HANDLER FUNCTIONS */
    const handleUser = (value: IDirectusUser) => {
        setUser(value)
    };

    const handleUserDetails = ()=>{
    // (
    //     key: keyof IUserDetails,
    //     value: any | undefined
    // ) => {
    //     const userDetails: IUserDetails = JSON.parse(JSON.stringify(user.details));
    //     setUser(prev => ({
    //         ...prev,
    //         details: {
    //             ...userDetails,
    //             [key]: value
    //         }
    //     }));
    };

    const handleContextStateUpdateOnMount = () => {
        localStorage.getItem("isSidebarCollapsed") &&
            setIsSidebarCollapsed(
                JSON.parse(localStorage.getItem("isSidebarCollapsed") as string)
            );

        localStorage.getItem("token") &&
            setToken(
                localStorage.getItem("token") as string
            );

        localStorage.getItem("isLoggedIn") &&
            setIsLoggedIn(
                JSON.parse(localStorage.getItem("isLoggedIn") as string)
            );

        localStorage.getItem("role") &&
            setRole(
                JSON.parse(localStorage.getItem("role") as string)
            );

        localStorage.getItem("user") &&
            setUser(
                JSON.parse(localStorage.getItem("user") as string)
            );
    };

    const handleContextStateUpdateOnStateChange = () => {
        if (isSidebarCollapsed !== undefined) {
            localStorage.setItem(
                "isSidebarCollapsed",
                JSON.stringify(isSidebarCollapsed)
            );
        };

        if (token) {
            setIsLoggedIn(true);
            localStorage.setItem("token", token);
        };

        if (isLoggedIn) {
            localStorage.setItem("isLoggedIn", JSON.stringify(isLoggedIn));
        };

        if (role) {
            localStorage.setItem("role", JSON.stringify(role));
        };

        if (user.id) {
            localStorage.setItem("user", JSON.stringify(user));
        };
    };

    const handleLogout = () => {
        
        setUser(initialUser);
        setIsSidebarCollapsed(false);
        setIsLoggedIn(false);
        setToken(undefined);
        setRole(undefined);
        localStorage.clear();
        navigate("/");
    };

    return {
        user,
        handleUser,
        handleUserDetails,
        isSidebarCollapsed,
        setIsSidebarCollapsed,
        isLoggedIn,
        setIsLoggedIn,
        token,
        setToken,
        role,
        setRole,
        handleLogout,
        handleContextStateUpdateOnMount,
        handleContextStateUpdateOnStateChange,
        currentRotes,
        setCurrentRoutes,
        colorScheme,
        setColorScheme,
        activeTab,
        setActiveTab
    };
}
