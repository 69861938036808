import { useEffect } from "react";
import { MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/dropzone/styles.css";

import { EAppShell, ENavbar, ETopbar } from "./libs/ui";
import ErrorBoundaryWrapper from "./ErrorBoundaryWrapper";

import "./App.scss";

import { AppContext } from "./AppContext";
import useApp from "./Pages/Hooks/useApp";
import RootRoutes from "./Routes/RootRoutes";
import axios from "axios";
import { Notifications } from "@mantine/notifications";

function App() {
  const {
    user,
    handleUser,
    handleUserDetails,
    isSidebarCollapsed,
    setIsSidebarCollapsed,
    isLoggedIn,
    setIsLoggedIn,
    token,
    setToken,
    role,
    setRole,
    handleLogout,
    handleContextStateUpdateOnMount,
    handleContextStateUpdateOnStateChange,
    currentRotes,
    setCurrentRoutes,
    colorScheme,
    setColorScheme,
    activeTab,
    setActiveTab,
  } = useApp();

  useEffect(() => {
    handleContextStateUpdateOnMount();
  }, []);

  useEffect(() => {
    handleContextStateUpdateOnStateChange();
  }, [user, isSidebarCollapsed, isLoggedIn, token, role]);

  const getToken = () => {
    let tkn = localStorage.getItem("token");
    return tkn || token;
  };

  // AXIOS INTERCEPTOR START
  // -- interceptor for each requests --
  axios.interceptors.request.use(
    (request: any) => {
      if (
        request.url.includes("googleapis") ||
        request.url.includes("pgi-videos")
      ) {
        return request;
      }
      if (!request.url.includes("auth")) {
        request.headers["Authorization"] = `Bearer ${getToken()}`;
      }
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // -- interceptor for each responce --
  axios.interceptors.response.use(
    undefined,
    function axiosRetryIntercptor(err) {
      if (err?.response?.status === 401) {
        handleLogout();
        console.error("Oops...!", "You are unauthorized");
        console.log(err.response);
      }
      return Promise.reject(err);
    }
  );
  // AXIOS INTERCEPTOR ENDS

  return (
    // AppContext Wrapper
    <AppContext.Provider
      value={{
        user,
        handleUser,
        handleUserDetails,
        isSidebarCollapsed,
        setIsSidebarCollapsed,
        isLoggedIn,
        setIsLoggedIn,
        token,
        setToken,
        handleLogout,
        role,
        setRole,
        currentRotes,
        setCurrentRoutes,
        colorScheme,
        setColorScheme,
        activeTab,
        setActiveTab,
      }}
    >
      {/* Mantine Wrapper */}
      <MantineProvider
        // withGlobalStyles
        // withNormalizeCSS
        theme={{}}
      >
        {/* Mantine Notification Wrapper */}
        {/* <NotificationsProvider position={"top-right"}> */}
        {/* App Level Error Boundary */}
        <Notifications />
        <ErrorBoundaryWrapper>
          {isLoggedIn && token ? (
            <EAppShell
              navbar={<ENavbar />}
              topbar={<ETopbar />}
              // footer={}
            >
              <RootRoutes />
            </EAppShell>
          ) : (
            <RootRoutes />
          )}
        </ErrorBoundaryWrapper>
        {/* </NotificationsProvider> */}
      </MantineProvider>
    </AppContext.Provider>
  );
}

export default App;
