import { useContext, useState } from "react";
import IStats from "../libs/data-access/types";
import axios from "axios";
import { API } from "../libs/data-access/constant";
import { showToast } from "../utils/utility";
import { AppContext } from "../AppContext";

export default function useStats() {
  const [stats, setStats] = useState<IStats>({} as IStats);
  const { user,role } = useContext(AppContext);

  // center_id=${user?.id}
  const handleFetchStats = () => {
    setStats({} as IStats);
    axios
      .get(`${API}custom/stats${role?.includes("Center") ? `?center_id=${user?.id}` : ``}`)
      .then((res) => {
        setStats(res.data.data);
      })
      .catch((err) => {
        console.log("Error in fetching stats", err);
      });
  };

  return {
    stats,
    handleFetchStats,
  };
}
