import { Space, Pagination, Table } from "@mantine/core";
import { useEffect } from "react";
import { FaUserPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import PageLayout from "../../../PageLayout";
import { EPageTitle, EButton, ETable, ETextInput } from "../../../libs/ui";
import useCenter from "./Hooks/useCenter";
import { BsSearch } from "react-icons/bs";

export default function CenterList() {
  const { currentPage, filterValue, setFilterValue, totalPage, totalCenter, centerList, handleFetchCenterList } =
    useCenter();

  const navigate = useNavigate();

  useEffect(() => {
    handleFetchCenterList(1);
  }, []);
  return (
    <PageLayout
      header={
        <EPageTitle
          title="Center List"
          end={
            <>
              <EButton
                size="md"
                variant="primary"
                onClick={() => {
                  navigate("/center-list/add-new-center");
                }}
              >
                <FaUserPlus className="mr-2" /> Add New Center
              </EButton>
            </>
          }
        />
      }
      // footer={
      //   <div className="p-2 w-full justify-center flex">
      //     <Pagination
      //       value={currentPage} onChange={handleFetchCenterList} total={totalPage} size={"lg"} />
      //   </div>
      // }
    >
      <div>
        <Space h={"md"} />
        <div className="flex justify-end mb-2">
        <ETextInput
          placeholder="Search"
          rightSection={
            <BsSearch
              className="cursor-pointer text-blue-600"
              size={22}
              onClick={() => {handleFetchCenterList(1)}}
              />}
              value={filterValue}
              onChange={(e)=>setFilterValue(e?.target?.value)}
        />
      </div>
        {centerList?.length ? (
          <ETable>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Center Name</Table.Th>
                <Table.Th>Center Id</Table.Th>
                <Table.Th>State</Table.Th>
                <Table.Th>Address</Table.Th>
                <Table.Th>Contact Number</Table.Th>
                <Table.Th>Whatsapp Number</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {centerList?.map((element) => (
                <Table.Tr
                  key={element.center_name}
                  className="cursor-pointer"
                  onClick={() =>
                    navigate(`/admin-patient-list?center_id=${element?.user_id?.id}&center_name=${element?.center_name}&center_state=${element?.state}`)
                  }
                >
                  <Table.Td>{element.center_name}</Table.Td>
                  <Table.Td>{element.user_id?.email}</Table.Td>
                  <Table.Td>{element.state}</Table.Td>
                  <Table.Td>{element.address}</Table.Td>
                  <Table.Td>{element.contact_no}</Table.Td>
                  <Table.Td>{element.whatsapp_no}</Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </ETable>
        ) : (
          <div className="flex justify-center items-center py-5 h-full w-full">
            <p className="mt-5 text-2xl">No data found</p>
          </div>
        )}

        <div className="flex justify-between items-center mt-0 border-t  pt-3 pb-8 w-full">
          <div className="count text-sm text-slate-600">
            {totalCenter ?? 0} records found
          </div>
          <Pagination
            size="sm"
            value={currentPage}
            onChange={handleFetchCenterList}
            total={totalPage}
          />
        </div>
      </div>
    </PageLayout>
  );
}
