import { useContext, useEffect, useState } from "react";
import { useDirectusApi } from "../../../../../libs/data-access";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { PatientContext } from "../../../PatientsContext";
import { showToast } from "../../../../../utils/utility";
import { IQualityOfLife } from "../../../../../libs/data-access/types";

export default function useQualityOfLife() {
  const { fetchDirectData, postDirectData, patchDirectData } = useDirectusApi();
  const [searchParams] = useSearchParams();
  const patient_id = searchParams.get("patient_id");
  const visit_id = searchParams.get("visit_id");
  const navigate = useNavigate();
  const location = useLocation();

  const {
    activeTab,
    isBtnNextClick,
    setIsBtnLoading,
    setIsBtnDisable,
    isFormUpdating,
    handleNextTab,
    setIsFormUpdating,
  } = useContext(PatientContext);

  const [isLoading, setIsLoading] = useState(false);
  const [qualityOfLife, setQualityOfLife] = useState<IQualityOfLife>(
    {} as IQualityOfLife
  );
  useEffect(() => {
    setIsBtnDisable(false);
    if (isBtnNextClick && activeTab == "quality_of_life") {
      handlePostQualityOfLife();
    }
  }, [isBtnNextClick]);

  useEffect(() => {
    if (activeTab === "quality_of_life") {
      getCostAnalysis();
    }
  }, [activeTab]);

  const handlePostQualityOfLife = async () => {
    setIsBtnLoading(true);
    let payload = {
      ...qualityOfLife,
      visit_id: visit_id,
    };
    if (isFormUpdating) {
      const res = await patchDirectData(
        `items/quality_of_life/${qualityOfLife.id}`,
        payload
      );
      if (res?.isSuccess) {
        showToast({
          type: "success",
          title: "Successfully",
          message: "Quality of life updated successfully",
        });
        //  handleNextTab()
        navigate(`/index/patient/details?patient_id=${patient_id}`);
      } else {
        showToast({
          type: "error",
          title: "error",
          message:
            res.message || "Error encountered while updating clinical history.",
        });
      }
    } else {
      const res = await postDirectData(`items/quality_of_life`, payload);
      if (res?.isSuccess) {
        showToast({
          type: "success",
          title: "Successfully",
          message: "Quality of life updated successfully",
        });
        handleNextTab();
      } else {
        showToast({
          type: "error",
          title: "error",
          message:
            res.message || "Error encountered while updating clinical history.",
        });
      }
    }
    setIsBtnLoading(false);
  };

  const getCostAnalysis = async () => {
    setIsLoading(true);
    const res = await fetchDirectData(
      `items/quality_of_life?filter[visit_id][_eq]=${visit_id}`
    );
    if (res.isSuccess) {
      if (res?.data?.length) {
        setQualityOfLife(res.data[0]);
        setIsFormUpdating(true);
      } else {
        setIsFormUpdating(false);
      }
    } else {
      showToast({
        type: "error",
        message: "Something went wrong",
      });
      setIsFormUpdating(false);
    }
    setIsLoading(false);
  };

  return {
    isLoading,
    handlePostQualityOfLife,
    qualityOfLife,
    setQualityOfLife,
  };
}
