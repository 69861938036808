import React, { useContext, useState } from 'react'
import { useDirectusApi } from '../../../libs/data-access';
import { ICenterDetails, IPatientInformation } from '../../../libs/data-access/types';
import { isValidPhone, showToast } from '../../../utils/utility';
import { useSearchParams } from 'react-router-dom';
import { IPatientInformationError } from '../../../utils/typeForValidorError';
import { AppContext } from '../../../AppContext';
import useUploadFiles from '../../Hooks/useUploadFiles';
import { FileWithPath } from '@mantine/dropzone';

const DefaultError: IPatientInformationError = {
    name: "",
    gender: "",
    age: "",
    address: "",
    houseNo: "",
    city: "",
    locality: "",
    state: "",
    postalCode: "",
    mobileNo: "",
    secondaryNo: "",
    alternateNo: "",
    HospitalNo: "",
    disease: "",
};

const DefaultInfo: IPatientInformation = {
    first_name: "",
    last_name: "",
    age: null,
    disease: [],
    primary_contact_no: null,
    alternate_contact_no: null,
    secondary_alternate_contact_no: null,
    email: "",
    gender: "male",
    father_name: "",
    mother_name: "",
    address: "",
    house_no: "",
    locality: "",
    city: "",
    state: "",
    pin_code: null,
    whatsapp_no: "",
    hospital_no: "",
    admission_no: "",
    ward_no: "",
    bed_no: "",
    physician_in_charge: "",
    surgeon_in_charge: "",
    radiology_in_charge: "",
    follow_ups: [],
    ultrasound_evidence_of_gs: "",
    ultrasound_evidence_of_gbc: "",
    definitive_diagnosis: "",
    severe_comorbidities: "no",
    unwilling_for_fu: "no",
    recruitment_date: "",
};


export default function useEditPatientInformation() {

    const { fetchDirectData, postDirectData, patchDirectData } = useDirectusApi();

    const { handleUploadMedia, handleDeleteAwsImage, handleGetPreviewUrl } =
        useUploadFiles();
    const { user, setColorScheme } = useContext(AppContext);

    const [searchParams] = useSearchParams();
    const patient_id = searchParams.get("patient_id");

    const [isLoading, setIsLoading] = React.useState(false);

    const [patientDetails, setPatientDetails] = React.useState<IPatientInformation>(DefaultInfo);
    const [error, setError] = useState<IPatientInformationError>(DefaultError);
    const [ageEligibility, setAgeEligibility] = useState<boolean | undefined>(
        undefined
    );
    const [isBtnDisable, setIsBtnDisable] = useState<boolean>(true);
    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
    const [uniqueNessCheck, setUniqueNessCheck] = useState<{
        mobileNo: string;
        alternateNo: string;
        secondaryNo: string;
    }>({
        mobileNo: "not_checked",
        alternateNo: "not_checked",
        secondaryNo: "not_checked",
    });

    const [centerDetails, setCenterDetails] = useState<ICenterDetails>(
        {} as ICenterDetails
    );

    const [consentFormUrl, setConsentFormUrl] = useState<string>();
    const [isOpenDeleteConfirmModal, setIsOpenDeleteConfirmModal] =
        useState<boolean>(false);

    const [uploadFileModalIsOpen, setUploadFileModalIsOpen] = useState(false);
    const [newConsentForm, setNewConsentForm] = useState<any>();
    const [isMediaBtnLoading, setIsMediaBtnLoading] = useState<boolean>(false);
    const [inclusionStatus, setInclusionStatus] = useState<"included" | "excluded" | "">("");
    const [updateInclusionStatus, setUpdateInclusionStatus] = useState<"included" | "excluded" | "">("");
    const [isOpenInfoModal, setIsOpenInfoModal] = useState<boolean>(false);



    const handleError = (key: keyof IPatientInformationError, value: string) => {
        setError((prev) => ({ ...prev, [key]: value }));
    };

    const handleGetPatientInformationDetails = async () => {
        setIsLoading(true);
        const res = await fetchDirectData(
            `items/patiant_profile?fields=id,first_name,last_name,age,disease_case,primary_contact_no,
              alternate_contact_no,secondary_alternate_contact_no,email,gender,father_name,
              mother_name,address,house_no,locality,city,state,pin_code,whatsapp_no,
              hospital_no,admission_no,ward_no,bed_no,physician_in_charge,surgeon_in_charge,
              radiology_in_charge,follow_ups,ultrasound_evidence_of_gs,ultrasound_evidence_of_gbc,
              definitive_diagnosis,severe_comorbidities,unwilling_for_fu,recruitment_date,consent_from_url,inclusion_status
              &filter[user_id]=${patient_id}`
        );
        if (res.isSuccess) {
            const patientData = res.data[0];
            const updatedPatientDetails = {
                ...patientData,
                disease: patientData.disease_case // Store disease_case in disease
            };
            setConsentFormUrl(patientData?.consent_from_url);
            setPatientDetails(updatedPatientDetails);
            setColorScheme({ head: "", body: "#c9ebc3", text: "", footer: "" });
            setInclusionStatus(patientData?.inclusion_status);
        } else {
            showToast({
                type: "error",
                title: "error",
                message: res.message || "Profile Details Not Found.",
            });
        }
        setIsLoading(false);
    }

    const handleChangeInfo = (
        key: keyof IPatientInformation,
        value: number | string | string[] | null
    ) => {
        if (key === "age") {
            if (Number(value) >= 18 && Number(value) <= 80) {
                setAgeEligibility(true);
            } else {
                setAgeEligibility(false);
            }
        }

        setPatientDetails((prev) => ({ ...prev, [key]: value }));

        let data = { ...patientDetails, [key]: value };
        if (
            data?.first_name &&
            data?.age &&
            data?.primary_contact_no &&
            data?.alternate_contact_no &&
            data?.hospital_no &&
            data?.address
        ) {
            setIsBtnDisable(false);
            setColorScheme({ head: "", body: "#c9ebc3", text: "", footer: "" });
        } else {
            setIsBtnDisable(true);
            setColorScheme({ head: "", body: "", text: "", footer: "" });
        }
    };

    const checkUniqueNumber = async (
        number: string | number,
        type: "mobileNo" | "alternateNo" | "secondaryNo"
    ) => {
        if (!isValidPhone(String(number))) {
            handleError(type, "Invalid mobile no.");
            return;
        } else {
            handleError(type, "");
        }
        const res = await fetchDirectData(
            `items/patiant_profile?filter={ "_or": [ { "primary_contact_no": { "_eq": "${number}" } }, { "alternate_contact_no": { "_eq": ${number} } }, { "secondary_alternate_contact_no": { "_eq": "${number}" } } ] }`
        );
        if (res.isSuccess) {
            if (res?.data?.length > 0) {
                handleError(type, "Mobile no. already exists.");
                setUniqueNessCheck({ ...uniqueNessCheck, [type]: "notUnique" });
            } else {
                setUniqueNessCheck({ ...uniqueNessCheck, [type]: "unique" });
            }
        } else {
            handleError(type, "Something went wrong. Please try again.");
            setUniqueNessCheck({ ...uniqueNessCheck, [type]: "error" });
        }
    };

    const getCenterDetails = async () => {
        const res = await fetchDirectData(
            `items/center_profile?filter[user_id][_eq]=${user?.id}`
        );
        if (res?.isSuccess) {
            setCenterDetails(res?.data[0]);
        }
    };

    const handleValidPatientInformation = (patientInfo: IPatientInformation) => {
        let isValidated = true;

        if (!patientInfo.age) {
            handleError("age", "Enter Valid age");
            isValidated = false;
        }
        if (!patientInfo.address.trim()) {
            handleError("address", "Invalid address");
            isValidated = false;
        }
        if (!patientInfo.primary_contact_no) {
            handleError("mobileNo", "Required field mobile");
            isValidated = false;
        }
        if (!isValidPhone(String(patientInfo.primary_contact_no))) {
            handleError("mobileNo", "Invalid mobile no.");
            isValidated = false;
        }
        if (!isValidPhone(String(patientInfo.alternate_contact_no))) {
            handleError("alternateNo", "Invalid mobile no.");
            isValidated = false;
        }
        if (patientInfo.secondary_alternate_contact_no && !isValidPhone(String(patientInfo.secondary_alternate_contact_no))) {
            handleError("secondaryNo", "Invalid mobile no.");
            isValidated = false;
        }
        if (!patientInfo.hospital_no) {
            handleError("HospitalNo", "Required field");
            isValidated = false;
        }
        if (!patientInfo.disease) {
            handleError("disease", "Required field");
            isValidated = false;
        }

        if (!isValidated) {
            // Display the generic error message
            showToast({
                type: "warning",
                message: " Please fill all required fields",
            });
        }
        return isValidated;
    };

    const handelUpdate = () => {
        if (
            uniqueNessCheck.mobileNo === "notUnique" ||
            uniqueNessCheck.alternateNo === "notUnique" ||
            (uniqueNessCheck.secondaryNo && uniqueNessCheck.secondaryNo === "notUnique")
        ) {
            showToast({
                type: "warning",
                message: " Please fill all required fields",
            });
            return;
        }

        let payload: IPatientInformation = {
            ...patientDetails,
            // center_id: user?.role?.id ?? "",
        };
        /* TODO: Center id 3 for center PGIMER */
        const valid = handleValidPatientInformation(payload);

        // if (!pdf) {
        //   const confirmUpload = window.confirm("Consent form not uploaded. Do you want to continue?");
        //   if (!confirmUpload) {
        //     return; // Exit the function if the user presses "Cancel"
        //   }
        // }

        if (valid) {
            setIsBtnLoading(true);
            patchPatientInfo(payload);
        }
    };

    const patchPatientInfo = async (payload: IPatientInformation) => {
        let updatedPayload = {
            ...payload,
            disease_case: payload.disease,
        }
        const res = await patchDirectData(`items/patiant_profile/${patientDetails?.id}`, updatedPayload);
        if (res?.isSuccess) {
            showToast({
                type: "success",
                title: "Successfully",
                message: "Patient information updated successfully",
            });
        } else {
            showToast({
                type: "error",
                title: "Error",
                message: res?.message || "Error while updating patient information",
            });
        }
        setIsBtnLoading(false);
    }

    const handleOpenMediaInNewTab = (fileName: string) => {
        let url = handleGetPreviewUrl(
            fileName,
            "base_line",
            patientDetails?.id + "",
            "consent_form",
        );
        window.open(url, "_blank");
    };

    const handleDeleteImageFromAws = async (fileName: string) => {
        const status = await handleDeleteAwsImage(
            fileName,
            "base_line",
            patientDetails?.id + "",
            "consent_form"
        );
        if (status) {
            setIsOpenDeleteConfirmModal(false);
            handleDeleteMediaFromDb(fileName);
        } else {
            showToast({
                type: "error",
                title: "error",
                message: "Error encountered while deleting media",
            });
        }
    };


    const handleDeleteMediaFromDb = async (fileName: string) => {
        let payload = {
            consent_from_url: "",
        };
        const res = await patchDirectData(
            `items/patiant_profile/${patientDetails?.id}`,
            payload
        );
        if (res?.isSuccess) {
            showToast({
                type: "success",
                title: "Successfully",
                message: "Consent deleted successfully",
            });
            handleGetPatientInformationDetails();
        } else {
            showToast({
                type: "error",
                title: "error",
                message: res.message || "Error encountered while deleting media",
            });
        }
    };

    const handleUploadFiles = async (files: FileWithPath[]) => {
        setIsMediaBtnLoading(true);
        const uploadedFiles = await handleUploadMedia(
            "base_line",
            patientDetails?.id + "",
            "consent_form",
            files
        );
        if (uploadedFiles?.length > 0) {
            handleUploadFileNamesInDb(uploadedFiles[0]);
        } else {
            showToast({
                type: "error",
                title: "Error",
                message: "Error uploading media files",
            });
        }
    };

    const handleUploadFileNamesInDb = async (uploadedFilesUrl: string) => {
        let payload = {
            consent_from_url: uploadedFilesUrl
        }
        const res = await patchDirectData(`items/patiant_profile/${patientDetails?.id}`, payload);
        if (res?.isSuccess) {
            console.log("uploaded media file", res?.data)
        }
        else {
            console.log("uploaded media file error", res?.message)
        }
        setIsMediaBtnLoading(false);
        setNewConsentForm("");
        setNewConsentForm(null);
        setUploadFileModalIsOpen(false);
        handleGetPatientInformationDetails();
    }

    const handleDownloadPDF = async (language: string) => {
        const filePath = require("../../../assets/pdf/" +
            language.toLowerCase() +
            "_consent.pdf");

        const link = document.createElement("a");
        link.href = process.env.PUBLIC_URL + filePath;
        link.download = `consent_${language}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleUpdateInclusionStatus = async () => {
        setIsBtnLoading(true);
        let payload = {
            inclusion_status: updateInclusionStatus
        }
        const res = await patchDirectData(`items/patiant_profile/${patientDetails?.id}`, payload);
        if (res?.isSuccess) {
            setIsOpenInfoModal(false);
            setInclusionStatus(res?.data?.inclusion_status);
            showToast({
                type: "success",
                title: "Successfully",
                message: "Inclusion status updated successfully",
            });
        }
        else {
            showToast({
                type: "error",
                title: "Error",
                message: res?.message || "Error while updating inclusion status",
            })
            console.log("uploaded media file error", res?.message)
        }
        setIsBtnLoading(false);
    }

    


    return {
        error,
        patient_id,
        isBtnLoading,
        isBtnDisable,
        centerDetails,
        consentFormUrl,
        patientDetails,
        newConsentForm,
        inclusionStatus,
        uniqueNessCheck,
        isMediaBtnLoading,
        isOpenDeleteConfirmModal,
        uploadFileModalIsOpen,
        setColorScheme,
        handleGetPatientInformationDetails,
        getCenterDetails,
        handleError,
        handelUpdate,
        handleChangeInfo,
        handleDownloadPDF,
        handleUploadFiles,
        setNewConsentForm,
        checkUniqueNumber,
        setInclusionStatus,
        setUniqueNessCheck,
        setIsMediaBtnLoading,
        handleOpenMediaInNewTab,
        setUploadFileModalIsOpen,
        handleDeleteImageFromAws,
        setIsOpenDeleteConfirmModal,
        handleUpdateInclusionStatus,
        setIsOpenInfoModal,
        isOpenInfoModal,
        updateInclusionStatus,
        setUpdateInclusionStatus
    }

}
