import { useContext, useState } from "react";
import { IPayloadCreateFollowUpRequest } from "../../../../types";
import { useDirectusApi } from "../../../../libs/data-access";
import { showToast } from "../../../../utils/utility";
import { IPatientInformation } from "../../../../libs/data-access/types";
import { AppContext } from "../../../../AppContext";
import usePatientList from "../../../Patients/hook/usePatientList";
import { useSearchParams } from "react-router-dom";

export default function useAdminPatients() {
  const [searchParams] = useSearchParams();
  const { user } = useContext(AppContext);
  const { postDirectData } = useDirectusApi();
  const centerId = searchParams.get("center_id");

  const {
    patientList,
    totalPage,
    currentPage,
    handleGetPatientList,
    setFilterValue,
    filterValue,
  } = usePatientList();

  const [isFollowUpModalOpen, setIsFollowUpModalOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState<IPatientInformation>(
    {} as IPatientInformation
  );

  const [payloadForAddFollowUpRequest, setPayloadForAddFollowUpRequest] =
    useState<IPayloadCreateFollowUpRequest>({
      user_created: "",
      date_created: new Date(),
      patient_id: "",
      user_created_name: "PGI MR",
      remarks: "",
    });

  const handleUpdatePayload = (
    e: keyof IPayloadCreateFollowUpRequest,
    value: any
  ) => {
    setPayloadForAddFollowUpRequest((prev) => ({ ...prev, [e]: value }));
  };

  const handleAddFollowUpRequest = async () => {
    let payload: IPayloadCreateFollowUpRequest = {
      ...payloadForAddFollowUpRequest,
      patient_id: "" + selectedPatient?.id,
      user_created: user?.id,
    };

    const res = await postDirectData(`items/follow_up`, payload);
    if (res?.isSuccess) {
      showToast({
        type: "success",
        title: "Successfully",
        message: "Tele Followup request added successfully",
      });
      handleGetPatientList(currentPage, centerId ? centerId : "");
      setIsFollowUpModalOpen(false);
    } else {
      showToast({
        type: "error",
        title: "Error",
        message:
          res?.message ||
          "Error encountered while adding  Tele Followup request.",
      });
    }
  };

  return {
    filterValue,
    setFilterValue,
    totalPage,
    currentPage,
    payloadForAddFollowUpRequest,
    selectedPatient,
    isFollowUpModalOpen,
    patientList,
    setIsFollowUpModalOpen,
    setSelectedPatient,
    handleUpdatePayload,
    handleAddFollowUpRequest,
    handleGetPatientList,
  };
}
