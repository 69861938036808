import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";

interface IProps {
  label: string;
  value: string | ReactJSXElement;
}

export function ELabelView({ label, value }: IProps) {
  return (
    <div>
      <p className="text-slate-600 font-medium text-base my-1">{label}</p>
      <p className="text-slate-600 font-normal text-lg">{value}</p>
    </div>
  );
}
