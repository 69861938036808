import { NumberInput, NumberInputProps } from '@mantine/core'
import React from 'react'

export default function EPhoneInput(props: NumberInputProps) {
  return (
    <NumberInput
    label={props.label}
    size={props.size}
    className={props.className}
    description={props.description}
    required={props.required}
    hideControls={props.hideControls ? props.hideControls : true }
    value={props.value || ""}
    leftSection={<p className="text-end">+91</p>}
    rightSection={props.rightSection}
    min={props.min ? props.min :  0}
    maxLength={10}
    placeholder={props.placeholder}
    error={props.error}
    onChange={props.onChange}
/>
  )
}