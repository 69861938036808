import React, { useEffect } from "react";
import PageLayout from "../../PageLayout";
import { EPageTitle, EButton, ETable, EModal, ETextInput, ETextArea } from "../../libs/ui";
import { Table, Skeleton, Pagination } from "@mantine/core";
import useRequestCallback from "./hooks/useRequestCallback";
import moment from "moment";

export default function RequestCallback() {
  const { 
     isLoading,
     requestCallbackData,
    getListByCenterId,
    totalPage,
    activePage,
    setActivePage,
    isOpenFeedBackModal,
    setIsOpenFeedBackModal,
    selectedPatient,
    setSelectedPatient,
    centerFeedback,
    setCenterFeedback,
    handleSubmitFeedBack
   } =
    useRequestCallback();

  useEffect(() => {
    getListByCenterId();
  }, [activePage]);

  return (
    <PageLayout header={<EPageTitle title="Request Callback" />}>
      <div className="border rounded-md ">
        <div className="p-5 text-emerald-800 bg-emerald-400 shadow">
          <p className="text-lg font-semibold">Patient List</p>
        </div>
        <ETable>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Date</Table.Th>
              <Table.Th>First Name</Table.Th>
              <Table.Th>Last Name</Table.Th>
              <Table.Th>Mobile Number</Table.Th>
              <Table.Th>Alternate Mobile Number</Table.Th>
              <Table.Th>Secondary Alternate Mobile Number</Table.Th>
              <Table.Th>Remarks</Table.Th>
              <Table.Th>Audio</Table.Th>
              <Table.Th>Feedback By Center</Table.Th>
            </Table.Tr>
          </Table.Thead>

          <Table.Tbody>
            {isLoading ? (
              <>
                <Table.Tr>
                  {[1, 2, 3, 4, 5, 6].map((e) => (
                    <Table.Td>
                      <Skeleton height={40} />
                    </Table.Td>
                  ))}
                </Table.Tr>
                <Table.Tr>
                  {[1, 2, 3, 4, 5, 6].map((e) => (
                    <Table.Td>
                      <Skeleton height={40} />
                    </Table.Td>
                  ))}
                </Table.Tr>
              </>
            ) : (
              requestCallbackData?.map((data, index) => (
                <Table.Tr>
                  <Table.Td>
                    {data?.date_created
                      ? moment(data?.date_created).format("DD-MM-YYYY")
                      : ""}
                  </Table.Td>
                  <Table.Td>{data?.patient_profile_id?.first_name}</Table.Td>
                  <Table.Td>{data?.patient_profile_id?.last_name}</Table.Td>
                  <Table.Td>
                    {data?.patient_profile_id?.primary_contact_no}
                  </Table.Td>
                  <Table.Td>
                    {data?.patient_profile_id?.alternate_contact_no}
                  </Table.Td>
                  <Table.Td>
                    {data?.patient_profile_id?.secondary_alternate_contact_no}
                  </Table.Td>
                  <Table.Td>{data?.remarks}</Table.Td>
                  <Table.Td>
                    {data?.audio ? (
                      <audio controls>
                        <source src={data.audio} type="audio/mpeg" />
                        Your browser does not support the audio element.
                      </audio>
                    ) : (
                      "No audio available"
                    )}
                  </Table.Td>
                  <Table.Td>
                  <EButton
                      variant="outline"
                      size={"xs"}
                      className=""
                      onClick={() => {
                        setSelectedPatient(data);
                        setIsOpenFeedBackModal(true);
                      }}
                    >
                      Add Feedback
                    </EButton>
                  </Table.Td>

                </Table.Tr>
              ))
            )}
          </Table.Tbody>
        </ETable>
       { 
       requestCallbackData?.length && totalPage > 1 ?
       <div className="flex justify-center">
          <Pagination
           value={activePage}
           total={totalPage}
           onChange={setActivePage}
           />
           </div> : <></>
           }  

      </div>

      <EModal
        isOpen={isOpenFeedBackModal}
        title={
          selectedPatient?.patient_profile_id?.first_name +
          " " +
          selectedPatient?.patient_profile_id?.last_name +
          (selectedPatient?.patient_profile_id?.primary_contact_no
            ? " (" + selectedPatient?.patient_profile_id?.primary_contact_no + ")"
            : "")
        }
        size="sm"
        setIsOpen={() => {
          setIsOpenFeedBackModal(false);
        }}
      >
        <div>
          <ETextArea
            value={centerFeedback}
            onChange={(e) => {
              setCenterFeedback(e?.target?.value);
            }}
            className="mt-5"
            label="Feedback"
          />
        </div>
        <EButton
          disabled={
            !centerFeedback
          }
          onClick={() => handleSubmitFeedBack()}
          className="mt-5 w-full flex items-center justify-center"
        >
          Submit
        </EButton>
      </EModal>
    </PageLayout>
  );
}
