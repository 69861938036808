import { Checkbox, Grid, Image } from "@mantine/core";
import {
  ESectionDivider,
  ETextInput,
  ECheckBox,
  ESelect,
  ELabelView,
  ENumberInput,
  ETextArea,
} from "../../../../../libs/ui";
import useGallStonesForm from "../hooks/useGallStonesForm";

export default function GallstonesForm() {
  const {
    documentedOn,
    setDocumentedOn,
    stoolFreq,
    setStoolFreq,
    bristolStoolScore,
    setBristolStoolScore,
    symptomStatus,
    setSymptomStatus,
    severity,
    setSeverity,
    painDescription,
    setPainDescription,
    typeOfPain,
    setTypeOfPain,
    painLocation,
    setPainLocation,
    painIntensity,
    setPainIntensity,
    painDuration,
    setPainDuration,
    painRadiation,
    setPainRadiation,
    precipitatedBy,
    setPrecipitatedBy,
    textInputs,
    setTextInputs,
    vomiting,
    setVomiting,
    nausea,
    setNausea,
    frequency,
    setFrequency,
    wasSurgeryAdvised,
    setWasSurgeryAdvised,
    wasSurgeryDone,
    setWasSurgeryDone,
    reliefOfPain,
    setReliefOfPain,
    hospitalization,
    setHospitalization,
    totalNoOfHospitalization,
    setTotalNoOfHospitalization,
    associatedSymptoms,
    setAssociatedSymptoms,
    descriptionOfSymptoms,
    setDescriptionOfSymptoms,
    frequencyMoreThanAnnualDetails,
    setFrequencyMoreThanAnnualDetails,
    typeOfPainRemarks,
    setTypeOfPainRemarks,
    gallstoneStatus,
    setGallstoneStatus,
    symptomsOfDyspepsia,
    setSymptomsOfDyspepsia,
    dyspepsiaScoringIndex,
    setDyspepsiaScoringIndex,
    finalLeadScoreForDyspepsia,
    scoringIndex,
    painAtNight,
    setPainAtNight,
    painAtExertion,
    setPainAtExertion,
    gallstoneFrequency,
    setGallstoneFrequency,
  } = useGallStonesForm();

  return (
    <div>
      <div className="flex gap-12">
        <h3 className="mb-3">Gallstones</h3>
        <div className="grid grid-cols-3 gap-4">
          <ECheckBox
            label="Asymptomatic"
            size="md"
            className="w-1/6"
            name="Asymptomatic"
            checked={gallstoneStatus === "Asymptomatic"}
            onChange={(e) => setGallstoneStatus(e?.target?.checked ? "Asymptomatic" : "")}
          />
          <ECheckBox
            label="Symptomatic"
            size="md"
            className="w-1/6"
            name="Symptomatic"
            checked={gallstoneStatus === "Symptomatic"}
            onChange={(e) => setGallstoneStatus(e?.target?.checked ? "Symptomatic" : "")}
          />
        </div>
      </div>

      <ESectionDivider title="Description of symptoms" />
      <div>
        <ETextArea
          placeholder="Description of symptoms"
          size="md"
          className="mb-2"
          value={descriptionOfSymptoms}
          onChange={(e) => setDescriptionOfSymptoms(e.target.value)}
        />
      </div>

      <Grid>
        <Grid.Col span={4}>
          <ETextInput
            size="md"
            // className="mt-"
            label="When was GS first detected"
            placeholder="When was GS first detected"
            value={textInputs?.whenSurgeryDetected}
            onChange={(e) => {
              setTextInputs({
                ...textInputs,
                whenSurgeryDetected: e.target.value,
              });
            }}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <ETextInput
            size="md"
            // className="mt-"
            label="Duration of stones (months)"
            placeholder="Duration of stones"
            value={textInputs?.durationOfStones}
            onChange={(e) => {
              setTextInputs({
                ...textInputs,
                durationOfStones: e.target.value,
              });
            }}
          />
        </Grid.Col>

        <Grid.Col span={4} className="flex flex-row gap-1">
          <ETextInput
            size="md"
            className="grow"
            label="Symptom Duration"
            maxLength={10}
            placeholder="First time"
            value={textInputs?.durationOfSymptoms ?? ""}
            onChange={(e) => {
              setTextInputs({
                ...textInputs,
                durationOfSymptoms: e.target.value,
              });
            }}
          />
        </Grid.Col>
      </Grid>
      <ESectionDivider title="How was it detected" />
      <div className="flex">
        <ECheckBox
          label="Incidental"
          size="md"
          className="w-1/6"
          name="howWasDetected"
          checked={textInputs?.howWasItDetected === "Incidental"}
          onChange={(e) =>
            setTextInputs({
              ...textInputs,
              howWasItDetected: e?.target?.checked ? "Incidental" : "",
              howWasItDetectedRemarks:""
            })
          }
        />
        {textInputs?.howWasItDetected === "Incidental" && (
          <ETextInput
            size="md"
            className="mr-2"
            label=""
            placeholder="incidental Remarks"
            value={textInputs?.howWasItDetectedRemarks}
            onChange={(e) => {
              setTextInputs({
                ...textInputs,
                howWasItDetectedRemarks: e.target.value,
              });
            }}
          />
        )}
        <ECheckBox
          label="On investigation of (symptoms)"
          name="howWasDetected"
          size="md"
          className="w-1/4"
          checked={textInputs?.howWasItDetected === "Investigation of symptoms"}
          onChange={(e) =>
            setTextInputs({
              ...textInputs,
              howWasItDetected: e?.target?.checked ? "Investigation of symptoms" : "",
              howWasItDetectedRemarks:""
            })
          }
        />
        {textInputs?.howWasItDetected == "Investigation of symptoms" && (
          <ETextInput
            size="md"
            // className="mt-"
            label=""
            placeholder="Investigation of symptoms Remarks"
            value={textInputs?.howWasItDetectedRemarks}
            onChange={(e) => {
              setTextInputs({
                ...textInputs,
                howWasItDetectedRemarks: e.target.value,
              });
            }}
          />
        )}
      </div>
      <ESectionDivider title="Documented on" />

      <div className="flex flex-wrap">
        {documentedOn?.map((each, index) => (
          <Checkbox
            key={index + "documented on"}
            className="w-1/6"
            size="md"
            checked={each?.selected}
            label={each?.label}
            onChange={(e) => {
              const updatedDocumentedOn = JSON.parse(
                JSON.stringify(documentedOn)
              );
              updatedDocumentedOn[index].selected =
                !updatedDocumentedOn[index].selected;
              setDocumentedOn(updatedDocumentedOn);
            }}
          />
        ))}
      </div>
      <ESectionDivider title="Symptom status" />

      <div className="flex flex-wrap">
        {symptomStatus?.map((each, index) => (
          <Checkbox
            key={index + "symptom status"} // Adding a unique key for each checkbox
            className={`${
              each?.label ===
              "Biliary colic (intense epigastric pain lasting >30 mins)"
                ? `w-1/3`
                : `w-1/6`
            } mb-2`}
            size="md"
            checked={each?.selected}
            label={each?.label}
            onChange={(e) => {
              let updatedSymptomStatus = JSON.parse(
                JSON.stringify(symptomStatus)
              );
              if (index === 0) {
                updatedSymptomStatus.forEach((item: any, index: number) => {
                  item.selected = false;
                });
              }
              if (index !== 0) {
                updatedSymptomStatus[0].selected = false;
              }
              updatedSymptomStatus[index].selected = !each?.selected;
              setSymptomStatus(updatedSymptomStatus);
            }}
          />
        ))}
      </div>
      <ESectionDivider title="Severity" />

      <div className="flex flex-wrap gap-4 items-end">
        {severity?.map((each, index) => (
          <Checkbox
            key={index + "severity"} // Adding a unique key for each checkbox
            className="w-1/12"
            size="md"
            checked={each?.selected}
            label={each?.label}
            onChange={(e) => {
              const updatedSeverity = [...severity];
              let isItTrue = updatedSeverity[index].selected;
              updatedSeverity.forEach((item: any, index: number) => {
                item.selected = false;
              });
              updatedSeverity[index].selected = !isItTrue;

              setSeverity(updatedSeverity);
            }}
          />
        ))}
      </div>

      <ESectionDivider title="Pain Description onset" />
      <div className="flex flex-wrap">
        {painDescription?.map((each, index) => (
          <Checkbox
            key={index + "pain description"}
            className="w-1/6 mb-2"
            size="md"
            checked={each?.selected}
            label={each?.label}
            onChange={(e) => {
              const updatedPainDescription = [...painDescription];
              updatedPainDescription[index].selected =
                !updatedPainDescription[index].selected;

              setPainDescription(updatedPainDescription);
            }}
          />
        ))}
      </div>

      <ESectionDivider title="Type of pain" />
      <div className="flex flex-wrap items-end">
        {typeOfPain?.map((each, index) => (
          <Checkbox
            key={index + "type of pain"}
            className="w-1/6"
            size="md"
            checked={each?.selected}
            label={each?.label}
            onChange={() => {
              setTypeOfPainRemarks("");
              const updatedTypeOfPain = [...typeOfPain];
              let isItTrue = updatedTypeOfPain[index].selected;
              updatedTypeOfPain.forEach((each) => {
                each.remarks = "";
                each.selected = false;
              });
              updatedTypeOfPain[index].selected = !isItTrue;
              setTypeOfPain(updatedTypeOfPain);
            }}
          />
        ))}
        <ETextInput
          size="md"
          // label="First Name"
          required
          // readOnly={viewOnly}
          placeholder="Type of pain"
          value={typeOfPainRemarks}
          onChange={(e) => {
            setTypeOfPainRemarks(e.target.value);
          }}
        />
      </div>

      <ESectionDivider title="Pain Location" />

      <div className="flex flex-wrap items-end">
        {painLocation?.map((each, index) => (
          <Checkbox
            key={index + "pain location"}
            className="w-1/5 mb-2"
            size="md"
            checked={each?.selected}
            label={each?.label}
            onChange={() => {
              if (each?.label == "Others") {
                const updatedPainLocation = [...painLocation];
                updatedPainLocation[index].selected =
                  !updatedPainLocation[index].selected;
                updatedPainLocation[index].value = "";
                setPainLocation(updatedPainLocation);
              } else {
                const updatedPainLocation = [...painLocation];
                updatedPainLocation[index].selected =
                  !updatedPainLocation[index].selected;
                setPainLocation(updatedPainLocation);
              }
            }}
          />
        ))}
        {painLocation?.find((e) => e?.label == "Others")?.selected ? (
          <ETextInput
            placeholder="Enter others"
            value={painLocation?.find((e) => e?.label == "Others")?.value}
            onChange={(event) => {
              const updatedPainLocation = [...painLocation];
              const _index = painLocation.findIndex(
                (e) => e?.label == "Others"
              );
              updatedPainLocation[_index].value = event?.target.value;
              setPainLocation(updatedPainLocation);
            }}
          />
        ) : (
          <></>
        )}
      </div>
      <ESectionDivider title="Intensity" />

      <div className="flex flex-wrap">
        {painIntensity?.map((each, index) => (
          <Checkbox
            key={index + "pain intensity"}
            className="w-1/6"
            size="md"
            checked={each?.selected}
            label={each?.label}
            onChange={() => {
              const updatedPainIntensity = [...painIntensity];
              let isItTrue = updatedPainIntensity[index].selected;
              updatedPainIntensity.forEach((each, index) => {
                each.selected = false;
              });
              updatedPainIntensity[index].selected = !isItTrue;
              setPainIntensity(updatedPainIntensity);
            }}
          />
        ))}
      </div>
      <ESectionDivider title="Duration" />

      <div className="flex flex-wrap items-end">
        {painDuration?.map((each, index) => (
          <Checkbox
            key={index + "pain duration"}
            className="w-1/6"
            size="md"
            checked={each?.selected}
            label={each?.label}
            onChange={() => {
              const updatedPainDuration = [...painDuration];
              let isItTrue = updatedPainDuration[index].selected;
              updatedPainDuration.forEach(
                (each) => ((each.selected = false), (each.remarks = ""))
              );
              updatedPainDuration[index].selected = !isItTrue;
              setPainDuration(updatedPainDuration);
            }}
          />
        ))}

        {painDuration[3]?.selected ? (
          <ETextInput
            size="md"
            // label="First Name"
            required
            // readOnly={viewOnly}
            placeholder="Pain duration"
            value={painDuration[3]?.remarks}
            onChange={(e) => {
              let updatePainDuration = JSON.parse(JSON.stringify(painDuration));
              updatePainDuration[3].remarks = e.target.value;
              setPainDuration(updatePainDuration);
            }}
          />
        ) : (
          <></>
        )}
      </div>

      <ESectionDivider title="Radiation" />

      <div className="flex flex-wrap items-end">
        {painRadiation?.map((each, index) => (
          <Checkbox
            key={index + "pain radiation"}
            className="w-1/6"
            size="md"
            checked={each?.selected}
            label={each?.label}
            onChange={(event) => {
              if (each?.label === "Others") {
                const updatedPainRadiation = [...painRadiation];
                updatedPainRadiation[0].selected = false;
                updatedPainRadiation[index].selected =
                  !updatedPainRadiation[index].selected;
                updatedPainRadiation[index].value = "";
                setPainRadiation(updatedPainRadiation);
              } else if (each?.label === "No radiation") {
                let updatedPainRadiation = painRadiation.map((e) => ({
                  ...e,
                  selected: false,
                }));
                if (event.target.checked) {
                  updatedPainRadiation[index].selected =
                    !updatedPainRadiation[index].selected;
                  updatedPainRadiation[index].value = "";
                }
                setPainRadiation(updatedPainRadiation);
              } else {
                const updatedPainRadiation = [...painRadiation];
                updatedPainRadiation[0].selected = false;
                updatedPainRadiation[index].selected =
                  !updatedPainRadiation[index].selected;
                setPainRadiation(updatedPainRadiation);
              }
            }}
          />
        ))}
        {painRadiation?.find((e) => e?.label == "Others")?.selected ? (
          <ETextInput
            placeholder="Enter others"
            className="w-1/6"
            value={painRadiation?.find((e) => e?.label == "Others")?.value}
            onChange={(event) => {
              const updatedPainRadiation = [...painRadiation];
              const _index = painRadiation.findIndex(
                (e) => e?.label == "Others"
              );
              updatedPainRadiation[_index].value = event?.target.value;
              setPainRadiation(updatedPainRadiation);
            }}
          />
        ) : (
          <></>
        )}
      </div>

      <ESectionDivider title="Nausea & Vomiting" />

      <div className="flex flex-wrap">
        <Checkbox
          className="w-1/6"
          size="md"
          checked={nausea}
          label={"Nausea"}
          onChange={() => {
            setNausea(!nausea);
          }}
        />
        <Checkbox
          className="w-1/6"
          size="md"
          checked={vomiting}
          label={"Vomiting"}
          onChange={() => {
            setVomiting(!vomiting);
          }}
        />
      </div>

      <ESectionDivider title="Pain at Night" />

      <div className="flex flex-wrap">
        <Checkbox
          className="w-1/6"
          size="md"
          checked={painAtNight === "No"}
          label={"No"}
          onChange={(e) => {
            setPainAtNight(e?.target?.checked ? "No" : "");
          }}
        />
        <Checkbox
          className="w-1/6"
          size="md"
          checked={painAtNight === "Yes"}
          label={"Yes"}
          onChange={(e) => {
            setPainAtNight(e?.target?.checked ? "Yes" : "");
          }}
        />
      </div>

      <ESectionDivider title="Pain at Exertion" />

      <div className="flex flex-wrap">
        <Checkbox
          className="w-1/6"
          size="md"
          checked={painAtExertion === "No"}
          label={"No"}
          onChange={(e) => {
            setPainAtExertion(e.target.checked ? "No" : "");
          }}
        />
        <Checkbox
          className="w-1/6"
          size="md"
          checked={painAtExertion === "Yes"}
          label={"Yes"}
          onChange={(e) => {
            setPainAtExertion(e.target.checked ? "Yes" : "");
          }}
        />
      </div>

      <ESectionDivider title="Precipitated by" />

      <div className="flex flex-wrap">
        {precipitatedBy?.map((each, index) => (
          <Checkbox
            key={index + "precipitated by"}
            className={each.label.length > 15 ? ` w-1/4` : `w-1/6`}
            size="md"
            checked={each?.selected}
            label={each?.label}
            onChange={() => {
              if (each?.label === "None") {
                setPrecipitatedBy(
                  precipitatedBy.map((e, i) => ({ ...e, selected: i === 0 }))
                );
              } else if (each?.label == "Others") {
                const updatedPrecipitatedBy = [...precipitatedBy];
                updatedPrecipitatedBy[index].selected =
                  !updatedPrecipitatedBy[index].selected;
                updatedPrecipitatedBy[index].value = "";
                updatedPrecipitatedBy[0].selected = false;
                setPrecipitatedBy(updatedPrecipitatedBy);
              } else {
                const updatedPrecipitatedBy = [...precipitatedBy];
                updatedPrecipitatedBy[index].selected =
                  !updatedPrecipitatedBy[index].selected;
                updatedPrecipitatedBy[0].selected = false;
                setPrecipitatedBy(updatedPrecipitatedBy);
              }
            }}
          />
        ))}
        {precipitatedBy?.find((e) => e?.label == "Others")?.selected ? (
          <ETextInput
            placeholder="Enter others"
            className="w-1/6"
            value={precipitatedBy?.find((e) => e?.label == "Others")?.value}
            onChange={(event) => {
              const updatedPrecipitatedBy = [...precipitatedBy];
              const _index = precipitatedBy.findIndex(
                (e) => e?.label == "Others"
              );
              updatedPrecipitatedBy[_index].value = event?.target.value;
              setPrecipitatedBy(updatedPrecipitatedBy);
            }}
          />
        ) : (
          <></>
        )}
      </div>

      <ESectionDivider title="Frequency" />

      <div className="flex flex-wrap gap-2">
        <ENumberInput
          maxLength={5}
          label="times/day"
          placeholder="Enter frequency times/day"
          className="w-1/6"
          value={gallstoneFrequency?.day}
          onChange={(e) => {
            setGallstoneFrequency({
              ...gallstoneFrequency,
              day: e + "",
              inFrequent: "",
            });
          }}
        />

        <ENumberInput
          maxLength={5}
          label="times/week"
          placeholder="Enter frequency times/week"
          className="w-1/6"
          value={gallstoneFrequency?.week}
          onChange={(e) => {
            setGallstoneFrequency({
              ...gallstoneFrequency,
              week: e + "",
              inFrequent: "",
            });
          }}
        />

        <ENumberInput
          maxLength={5}
          label="times/month"
          placeholder="Enter frequency times/month"
          className="w-1/6"
          value={gallstoneFrequency?.month}
          onChange={(e) => {
            setGallstoneFrequency({
              ...gallstoneFrequency,
              month: e + "",
              inFrequent: "",
            });
          }}
        />

        <ENumberInput
          maxLength={5}
          label="times/year"
          placeholder="Enter frequency times/year"
          className="w-1/6"
          value={gallstoneFrequency?.year}
          onChange={(e) => {
            setGallstoneFrequency({
              ...gallstoneFrequency,
              year: e + "",
              inFrequent: "",
            });
          }}
        />
      </div>

      <ETextArea
        className="w-1/2 mt-2"
        label="If infrequent, describe"
        placeholder="Enter frequency times/year"
        value={gallstoneFrequency?.inFrequent}
        onChange={(e) => {
          setGallstoneFrequency({
            week: "",
            month: "",
            year: "",
            day: "",
            inFrequent: e.target.value,
          });
        }}
      />

      <ESectionDivider title="Relief of Pain Achieved by" />

      <div className="flex flex-wrap">
        {reliefOfPain?.map((each, index) => (
          <Checkbox
            key={index + "relief of pain"}
            className="w-1/6 mb-2"
            size="md"
            checked={each?.selected}
            label={each?.label}
            onChange={() => {
              const updatedReliefOfPain = [...reliefOfPain];
              updatedReliefOfPain[index].selected =
                !updatedReliefOfPain[index].selected;

              setReliefOfPain(updatedReliefOfPain);
            }}
          />
        ))}
      </div>

      <ESectionDivider title="Hospitalization" />
      <div className="flex">
        <ECheckBox
          label="Required"
          size="md"
          name="hospital"
          className="w-1/6"
          checked={hospitalization === "Required"}
          onChange={(e) => setHospitalization(e?.target.checked ? "Required" : "")}
        />
        <ECheckBox
          label="Not Required"
          name="hospital"
          size="md"
          className="w-1/6"
          checked={hospitalization === "Not Required"}
          onChange={(e) => setHospitalization(e?.target.checked ? "Not Required" : "")}
        />
      </div>
      <ETextInput
        label="Total no of hospitalizations"
        size="md"
        className="w-1/4 mt-4"
        placeholder="Enter no of hospitalizations"
        value={totalNoOfHospitalization ?? ""}
        onChange={(e) => setTotalNoOfHospitalization(e?.target?.value)}
      />

      <ESectionDivider title="Associated Symptoms" classes="mt-5" />

      <div className="flex flex-wrap">
        {associatedSymptoms?.map((each, index) => (
          <Checkbox
            key={index + "associated symptoms"}
            className="w-1/6 mb-2"
            size="md"
            checked={each?.selected}
            label={each?.label}
            onChange={() => {
              const updatedAssociatedSymptoms = [...associatedSymptoms];
              updatedAssociatedSymptoms[index].selected =
                !updatedAssociatedSymptoms[index].selected;

              setAssociatedSymptoms(updatedAssociatedSymptoms);
            }}
          />
        ))}
      </div>

        <ESectionDivider title="Bristol stool score" />
        <div className="flex justify- gap-16">
        <Image
         src={require("../../../../../assets/images/Bristol-stool.jpeg")}
         alt="bristol stool score"
         h={400}
        //  w={800}
         fit="fill"
       />
          <div>
        <ETextInput
          label="Stool frequency"
          placeholder="Stool frequency (In last one week)"
          value={stoolFreq}
          onChange={(e) => setStoolFreq(e?.target?.value)}
        />
        <div className="flex gap-2">
          <ETextInput
            label="Usual Score"
            placeholder="Usual Score"
            value={bristolStoolScore?.usual}
            onChange={(e) =>
              setBristolStoolScore({
                ...bristolStoolScore,
                usual: e?.target.value,
              })
            }
          />

          <ETextInput
            label="Worst Score"
            placeholder="worst Score"
            value={bristolStoolScore?.worst}
            onChange={(e) =>
              setBristolStoolScore({
                ...bristolStoolScore,
                worst: e?.target.value,
              })
            }
          />
        </div>
        
        </div>
      </div>

      <Grid className="mt-8">
        <Grid.Col span={5}>
          <ELabelView
            label="Was surgery advised"
            value={
              <div className="flex items- gap-3">
                <Checkbox
                  size="md"
                  checked={wasSurgeryAdvised.status ?? false}
                  label={
                    <span className="text-base font-semibold text-black">
                      Yes
                    </span>
                  }
                  onChange={(e) => {
                    setWasSurgeryAdvised({
                      status: e ?.target.checked ? true : null ,
                      when: "",
                    });
                  }}
                />
                <Checkbox
                  size="md"
                  checked={wasSurgeryAdvised.status === false}
                  label={
                    <span className="text-base font-semibold text-black">
                      No
                    </span>
                  }
                  onChange={(e) => {
                    setWasSurgeryAdvised({
                      status: e?.target.checked ? false : null,
                      when: "",
                    });
                  }}
                />

                {wasSurgeryAdvised.status && (
                  <ETextInput
                    placeholder="When advised"
                    value={wasSurgeryAdvised?.when}
                    onChange={(e) =>
                      setWasSurgeryAdvised({
                        status: true,
                        when: e?.target.value,
                      })
                    }
                  />
                )}
              </div>
            }
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <ELabelView
            label="Was surgery Done"
            value={
              <div className="flex gap-3 items-start">
                <Checkbox
                  size="md"
                  checked={wasSurgeryDone.status === true}
                  label={
                    <span className="text-base font-semibold text-black">
                      Yes
                    </span>
                  }
                  onChange={(e) => {
                    setWasSurgeryDone({ status: e?.target.checked ? true : null, when: "" });
                  }}
                />
                <Checkbox
                  size="md"
                  checked={wasSurgeryDone.status === false}
                  label={
                    <span className="text-base font-semibold text-black">
                      No
                    </span>
                  }
                  onChange={(e) => {
                    setWasSurgeryDone({
                      status: e.target.checked ? false : null,
                      when: "",
                    });
                  }}
                />
                <div>
                  <ESelect
                    data={[
                      " Symptoms subsided with time",
                      "Was not convinced for surgery",
                      "Economic reasons",
                      "Family reasons",
                      "Tried alternative medicine",
                      "Long waiting list",
                      "Others",
                    ]}
                    readOnly={!!wasSurgeryDone?.status}
                    disabled={!!wasSurgeryDone?.status}
                    value={
                      wasSurgeryDone?.when
                        ? wasSurgeryDone?.when?.includes("Others")
                          ? "Others"
                          : wasSurgeryDone?.when
                        : null
                    }
                    placeholder="Select Reason"
                    onChange={(e) =>
                      setWasSurgeryDone({
                        status: e === "Yes",
                        when: e ?? "",
                      })
                    }
                  />

                  {wasSurgeryDone?.when?.includes("Others") ? (
                    <ETextInput
                      className="mt-2"
                      placeholder="Enter your reason"
                      value={wasSurgeryDone?.when
                        ?.split(",")
                        ?.slice(1)
                        ?.join(",")}
                      onChange={(e) =>
                        setWasSurgeryDone({
                          ...wasSurgeryDone,
                          when: "Others," + e?.target.value,
                        })
                      }
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            }
          />
        </Grid.Col>
      </Grid>

      <ESectionDivider
        title="Dyspepsia Symptoms"
        subTitle="Symptoms of dyspepsia"
      />
      <div className="flex flex-col">
        {symptomsOfDyspepsia?.map((each, index) => (
          <div className="flex gap-3 justify-between">
            <Checkbox
              size="md"
              className="mt-4"
              checked={each?.selected}
              label={each?.label}
              onChange={() => {
                let temp = [...symptomsOfDyspepsia];
                if (temp[index].selected) {
                  temp[index].remarks = "";
                }
                temp[index].selected = !temp[index].selected;
                setSymptomsOfDyspepsia(temp);
              }}
            />
            {each?.selected ? (
              <ETextInput
                size="xs"
                className="w-1/2 pt-2"
                placeholder={
                  each?.label.includes("≥")
                    ? "Exact ___ months"
                    : "Enter Remarks"
                }
                value={each?.remarks}
                onChange={(e) => {
                  let temp = [...symptomsOfDyspepsia];
                  temp[index].remarks = e.target.value;
                  setSymptomsOfDyspepsia(temp);
                }}
              />
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>

      <ESectionDivider
        title="Dyspepsia Symptoms"
        subTitle="Dyspepsia Scoring Index"
        end={
          <p className="text-slate-500 text-sm">
            Final Lead Score:
            <span className="text-base text-slate-600">
            {" "}
              {finalLeadScoreForDyspepsia
                ? +finalLeadScoreForDyspepsia > 15
                  ? "Severe"
                  : +finalLeadScoreForDyspepsia > 8
                  ? "Moderate"
                  : +finalLeadScoreForDyspepsia > 4
                  ? "Mild"
                  : "Very mild"
                : ""} 
            </span>
          </p>
        }
      />
      <div className="flex flex-wrap">
        {dyspepsiaScoringIndex?.map((each, index) => (
          <div className="flex items-end justify-between px-3 mb-3 w-1/2 gap-3">
            <Checkbox
              size="md"
              key={index}
              checked={each?.selected}
              label={each?.label}
              onChange={() => {
                let temp = [...dyspepsiaScoringIndex];
                if (temp[index].selected) {
                  temp[index].score = "";
                }
                temp[index].selected = !temp[index].selected;
                setDyspepsiaScoringIndex(temp);
              }}
            />
            {each?.selected ? (
              <ESelect
                size="xs"
                className="w-1/3"
                data={scoringIndex.map((item) => ({
                  label: item.label,
                  value: item.score,
                }))}
                placeholder="Select"
                value={each?.score}
                onChange={(e) => {
                  let temp = [...dyspepsiaScoringIndex];
                  temp[index].score = e || "";
                  setDyspepsiaScoringIndex(temp);
                }}
              />
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
