import { Checkbox, Grid, Space } from "@mantine/core";
import { ENumberInput, ESectionDivider } from "../../../../../libs/ui";
import useAnthropometricMeasurements from "../hooks/useAnthropometricMeasurements";
import SecondaryPageLayout from "../../../../../libs/ui/src/SecondaryPageLayout/SecondaryPageLayout";
import { useEffect } from "react";

export default function AnthropometricMeasurementForm() {
  const {
    ecogPerformanceStatus,
    setEcogPerformanceStatus,
  } = useAnthropometricMeasurements();

  return (
    <SecondaryPageLayout>
      <div>
        <ESectionDivider
          title="Clinical Examination"
          subTitle="ECOG Performance Status"
        />

        <div className="flex flex-col gap-4">
          {ecogPerformanceStatus?.map((each, index) => (
            <Checkbox
              key={index}
              size="md"
              checked={each?.selected}
              label={each?.label}
              onChange={() => {
                const updatedEcogPerformanceStatus = [...ecogPerformanceStatus];
                let isItTrue = updatedEcogPerformanceStatus[index].selected;

                updatedEcogPerformanceStatus.forEach((each) => {
                  each.selected = false;
                });
                updatedEcogPerformanceStatus[index].selected = !isItTrue;

                setEcogPerformanceStatus(updatedEcogPerformanceStatus);
              }}
            />
          ))}
        </div>

        
      </div>
    </SecondaryPageLayout>
  );
}
