import { useContext, useEffect, useState } from "react";
import { PatientContext } from "../../../PatientsContext";
import { IPayloadForSurgery } from "../../../../../types";
import { useDirectusApi } from "../../../../../libs/data-access";
import { useSearchParams } from "react-router-dom";
import { showToast } from "../../../../../utils/utility";
import { FileWithPath } from "@mantine/dropzone";
import useUploadFiles from "../../../../Hooks/useUploadFiles";

export default function useSurgery() {
  const {
    activeTab,
    isBtnNextClick,
    setIsBtnLoading,
    handleNextTab,
    setIsBtnDisable,
    isFormUpdating,
    setIsFormUpdating,
  } = useContext(PatientContext);

  const { handleUploadMedia, handleDeleteAwsImage, handleGetPreviewUrl } =
    useUploadFiles();

  const [searchParams] = useSearchParams();
  const patientId = searchParams.get("patient_id");
  const visit_id = searchParams.get("visit_id");

  const { postDirectData, fetchDirectData, patchDirectData } = useDirectusApi();
  const [payloadForSurgery, setPayloadForSurgery] =
    useState<IPayloadForSurgery>({} as IPayloadForSurgery);

  const [surgeryDate, setSurgeryDate] = useState<any>();
  const [outsideStudyCenter, setOutSideStudyCenter] = useState<boolean>(false);
  const [centerName, setCenterName] = useState<string>("");

  const [files, setFiles] = useState<FileWithPath[]>([]);
  const [isMediaBtnLoading, setIsMediaBtnLoading] = useState(false);
  const [mediaFiles, setMediaFiles] = useState<string[]>([]);
  const [uploadFileModalIsOpen, setUploadFileModalIsOpen] = useState(false);
  const [previousId, setPreviousId] = useState<number | undefined>(undefined);
  const [isOpenDeleteConfirmModal, setIsOpenDeleteConfirmModal] =
    useState<boolean>(false);

  useEffect(() => {
    setIsBtnDisable(false);
    if (isBtnNextClick && activeTab == "surgery") {
      handleSubmitSurgeryForm();
    }
  }, [isBtnNextClick]);

  useEffect(() => {
    if (activeTab == "surgery") {
      getSurgeryDetailsByVisitId();
    }
  }, [activeTab]);

  const handleChangeSurgery = (
    key: keyof IPayloadForSurgery,
    value: string | boolean | number | null | any[]
  ) => {
    setPayloadForSurgery((prev) => ({ ...prev, [key]: value }));
  };

  const handleSubmitSurgeryForm = () => {
    if (patientId && visit_id) {
      let payload: IPayloadForSurgery = {
        ...payloadForSurgery,
        status: "published",
        within_study_centre: outsideStudyCenter ? "no" : "yes",
        user_id: patientId,
        visit_id: visit_id,
        outside_study_center_name: centerName,
      };
      postSurgery(payload);
    } else {
      showToast({
        type: "error",
        message: "User not found",
      });
    }
  };

  const postSurgery = async (payload: IPayloadForSurgery) => {
    setIsBtnLoading(true);
    if (isFormUpdating) {
      const res = await patchDirectData(
        `items/surgery_gs_patients/${previousId}`,
        payload
      );
      if (res?.isSuccess) {
        showToast({
          type: "success",
          title: "Updated successfully",
          message: "Surgery updated successfully",
        });
        handleNextTab();
      } else {
        showToast({
          type: "error",
          title: "Error",
          message: res?.message,
        });
      }
    } else {
      const res = await postDirectData("items/surgery_gs_patients", payload);
      if (res?.isSuccess) {
        showToast({
          type: "success",
          title: "Added successfully",
          message: "Surgery added successfully",
        });
        handleNextTab();
      } else {
        showToast({
          type: "error",
          title: "Error",
          message: res?.message,
        });
      }
    }

    setIsBtnLoading(false);
  };

  const handleUploadFileNamesInDb = async (uploadedFiles: string[]) => {
    if (patientId && visit_id) {
      const uniqueSet = new Set([...uploadedFiles, ...mediaFiles]);
      let payload: IPayloadForSurgery = {
        ...payloadForSurgery,
        status: "published",
        within_study_centre: outsideStudyCenter ? "no" : "yes",
        user_id: patientId,
        visit_id: visit_id,
        outside_study_center_name: centerName,
        media_files: JSON.stringify(Array.from(uniqueSet)),
      };
      if (isFormUpdating) {
        const res = await patchDirectData(
          `items/surgery_gs_patients/${previousId}`,
          payload
        );
        if (res?.isSuccess) {
          showToast({
            type: "success",
            title: "Successfully",
            message: "Media uploaded successfully",
          });
          getSurgeryDetailsByVisitId();
        } else {
          showToast({
            type: "error",
            title: "error",
            message: res.message || "Error encountered while adding media",
          });
        }
      } else {
        const res = await postDirectData(`items/surgery_gs_patients`, payload);
        if (res?.isSuccess) {
          getSurgeryDetailsByVisitId();
          showToast({
            type: "success",
            title: "Successfully",
            message: "Media uploaded successfully",
          });
        } else {
          showToast({
            type: "error",
            title: "error",
            message: res.message || "Error encountered while adding media",
          });
        }
      }
    } else {
      showToast({
        type: "error",
        message: "Undefined user",
      });
    }
    setIsMediaBtnLoading(false);
  };

  const getSurgeryDetailsByVisitId = async () => {
    const res = await fetchDirectData(
      `items/surgery_gs_patients?filter[visit_id][_eq]=${visit_id}`
    );
    if (res.isSuccess) {
      if (res?.data?.length) {
        setIsFormUpdating(true);
        const data: IPayloadForSurgery = res.data[0];
        setPayloadForSurgery(data);
        setPreviousId(data?.id);
        setMediaFiles(JSON.parse(data?.media_files) || []);

        Object.keys(data).map((key) => {
          if (isKeyOfPayloadForSurgery(key)) {
            if (key == "within_study_centre") {
              setOutSideStudyCenter(data[key] == "yes" ? false : true);
              setCenterName(data["outside_study_center_name"]);
            } else {
              handleChangeSurgery(
                key as keyof IPayloadForSurgery,
                data[key] as any
              );
            }
          }
        });
      } else {
        setIsFormUpdating(false);
      }
    }
    setUploadFileModalIsOpen(false);
  };

  const isKeyOfPayloadForSurgery = (
    key: keyof IPayloadForSurgery | string | number | symbol
  ): key is keyof IPayloadForSurgery => {
    // Implement logic to check if the key belongs to IPayloadForSurgery
    // For example, you can check against known keys or use instanceof or other checks
    return true; // Replace with your logic
  };

  const handleUploadFiles = async (files: FileWithPath[]) => {
    setIsMediaBtnLoading(true);
    const uploadedFiles = await handleUploadMedia(
      visit_id + "",
      patientId + "",
      "surgery",
      files
    );
    if (uploadedFiles?.length > 0) {
      handleUploadFileNamesInDb(uploadedFiles);
    } else {
      showToast({
        type: "error",
        title: "Error",
        message: "Error uploading media files",
      });
    }
  };

  const handleDeleteImageFromAws = async (fileName: string) => {
    const status = await handleDeleteAwsImage(
      fileName,
      visit_id + "",
      patientId + "",
      "histology"
    );
    if (status) {
      setIsOpenDeleteConfirmModal(false);
      handleDeleteMediaFromDb(fileName);
    } else {
      showToast({
        type: "error",
        title: "error",
        message: "Error encountered while deleting media",
      });
    }
  };

  const handleOpenMediaInNewTab = (fileName: string) => {
    let url = handleGetPreviewUrl(
      fileName,
      visit_id + "",
      patientId + "",
      "histology"
    );
    window.open(url, "_blank");
  };

  const handleDeleteMediaFromDb = async (fileName: string) => {
    let payload = {
      media_files: JSON.stringify(mediaFiles.filter((e) => e != fileName)),
    };
    const res = await patchDirectData(
      `items/surgery_gs_patients/${previousId}`,
      payload
    );
    if (res?.isSuccess) {
      showToast({
        type: "success",
        title: "Successfully",
        message: "Media deleted successfully",
      });
      getSurgeryDetailsByVisitId();
    } else {
      showToast({
        type: "error",
        title: "error",
        message: res.message || "Error encountered while deleting media",
      });
    }
  };

  return {
    centerName,
    outsideStudyCenter,
    surgeryDate,
    setSurgeryDate,
    setOutSideStudyCenter,
    setCenterName,
    payloadForSurgery,
    handleChangeSurgery,
    mediaFiles,
    files,
    setFiles,
    handleUploadFiles,
    uploadFileModalIsOpen,
    setUploadFileModalIsOpen,
    isMediaBtnLoading,
    handleOpenMediaInNewTab,
    handleDeleteImageFromAws,
    isOpenDeleteConfirmModal,
    setIsOpenDeleteConfirmModal,
  };
}
