import { Checkbox, Grid, Group, Radio } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import {
  EButton,
  ECheckBox,
  EModal,
  ENumberInput,
  ESectionDivider,
  ESelect,
  ETextInput,
  ETimeInput,
  FormSectionDivider,
} from "../../../../../libs/ui";
import useRadiology from "../hooks/useRadiology";
import moment from "moment";
import { MdOutlinePermMedia } from "react-icons/md";
import EMediaItem from "../../../../../libs/ui/src/EMediaItem";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { HiOutlineArrowUpTray } from "react-icons/hi2";
import EMultiCheckBoxToString from "../../../../../libs/ui/src/CheckBox/EMultiCheckBoxToString";

export default function Radiology() {
  const {
    gbef,
    setGbef,
    payloadForRadiology,
    setPayloadForRadiology,
    files,
    mediaFiles,
    setFiles,
    handleUploadFiles,
    uploadFileModalIsOpen,
    setUploadFileModalIsOpen,
    isMediaBtnLoading,
    handleOpenMediaInNewTab,
    handleDeleteImageFromAws,
    isOpenDeleteConfirmModal,
    setIsOpenDeleteConfirmModal,
    handleCheckboxChange,
    gbefStaus,
    setReasonForGBEFNotDone,
    reasonforGBEFNotDone
  } = useRadiology();

  const fastingVolume =
    Number(gbef[0]?.gb_length) &&
      Number(gbef[0]?.gb_breadth) &&
      Number(gbef[0]?.gb_height)
      ? Number(gbef[0]?.gb_length) *
      Number(gbef[0]?.gb_breadth) *
      Number(gbef[0]?.gb_height) *
      0.52
      : "";
  const postprandial30Volume =
    Number(gbef[1]?.gb_length) &&
      Number(gbef[1]?.gb_breadth) &&
      Number(gbef[1]?.gb_height)
      ? Number(gbef[1]?.gb_length) *
      Number(gbef[1]?.gb_breadth) *
      Number(gbef[1]?.gb_height) *
      0.52
      : "";
  const postprandial60Volume =
    Number(gbef[1]?.gb_length) &&
      Number(gbef[1]?.gb_breadth) &&
      Number(gbef[1]?.gb_height)
      ? Number(gbef[2]?.gb_length) *
      Number(gbef[2]?.gb_breadth) *
      Number(gbef[2]?.gb_height) *
      0.52
      : "";

  return (
    <div className="grid grid-cols-12">
      <div className="pb-8 col-span-9 border-r pr-3">
        <div className="flex justify-between">
          <DateInput
            size="md"
            label="Date of Radiology"
            placeholder="Select date"
            value={
              payloadForRadiology?.date
                ? new Date(payloadForRadiology?.date)
                : undefined
            }
            onChange={(value) => {
              setPayloadForRadiology({
                ...payloadForRadiology,
                date: moment(value).format("YYYY-MM-DD"),
              });
            }}
          />
          <ETextInput
            size="md"
            label="USG Done By"
            placeholder="Enter name"
            value={payloadForRadiology?.usg_done_by}
            onChange={(e) => {
              setPayloadForRadiology({
                ...payloadForRadiology,
                usg_done_by: e.target.value,
              });
            }}
          />
        </div>
        <div className="grid grid-cols-4 gap-3 mt-5">
          <Checkbox
            size="md"
            labelPosition="left"
            label="Outside study centre"
            checked={
              payloadForRadiology?.out_side_study_center === "Yes"
                ? true
                : false
            }
            onChange={(e) => {
              console.log(e.target.checked);
              setPayloadForRadiology({
                ...payloadForRadiology,
                out_side_study_center: e.target.checked ? "Yes" : "No",
                out_side_study_center_name: "",
              });
            }}
          />
          {payloadForRadiology?.out_side_study_center === "Yes" ? (
            <ETextInput
              placeholder="Enter name"
              className="mt-1"
              value={payloadForRadiology?.out_side_study_center_name}
              onChange={(e) => {
                setPayloadForRadiology({
                  ...payloadForRadiology,
                  out_side_study_center_name: e.target.value,
                });
              }}
            />
          ) : (
            <></>
          )}
        </div>
        <ESectionDivider title="Fasting" />

        <div className="flex flex-row gap-5">
          <ECheckBox
            checked={
              payloadForRadiology?.fasting === "fasting > 8hrs" ? true : false
            }
            label="Fasting > 8hrs"
            onChange={(e) => {
              setPayloadForRadiology({
                ...payloadForRadiology,
                fasting: e.target.checked ? "fasting > 8hrs" : "",
              });
            }}
          />
          <ECheckBox
            label="Fasting < 8hrs"
            checked={
              payloadForRadiology?.fasting === "fasting < 8hrs" ? true : false
            }
            onChange={(e) => {
              setPayloadForRadiology({
                ...payloadForRadiology,
                fasting: e.target.checked ? "fasting < 8hrs" : "",
              });
            }}
          />
          <ECheckBox
            label="No"
            checked={payloadForRadiology?.fasting === "No" ? true : false}
            onChange={(e) => {
              setPayloadForRadiology({
                ...payloadForRadiology,
                fasting: e.target.checked ? "No" : "",
              });
            }}
          />
        </div>
        <ESectionDivider title="Gallbladder" />

        <div className="flex flex-row gap-5">
          <ECheckBox
            label="Distended"
            checked={payloadForRadiology?.gallbladder?.includes("Distended")}
            onChange={(e) => {
              const currentValues = payloadForRadiology?.gallbladder?.split(", ").filter(Boolean) || [];
              const updatedValues = e.target.checked
                ? [...currentValues, "Distended"]
                : currentValues.filter((val) => val !== "Distended");
              setPayloadForRadiology({
                ...payloadForRadiology,
                gallbladder: updatedValues.join(updatedValues.length > 1 ? ", " : ""), // Join with comma only if more than one item
              });
            }}
          />
          <ECheckBox
            label="Contracted"
            checked={payloadForRadiology?.gallbladder?.includes("Contracted")}
            onChange={(e) => {
              const currentValues = payloadForRadiology?.gallbladder?.split(", ").filter(Boolean) || [];
              const updatedValues = e.target.checked
                ? [...currentValues, "Contracted"]
                : currentValues.filter((val) => val !== "Contracted");
              setPayloadForRadiology({
                ...payloadForRadiology,
                gallbladder: updatedValues.join(updatedValues.length > 1 ? ", " : ""),
              });
            }}
          />
          <div className="flex flex-col gap-3">
            <ECheckBox
              label="Not assessed"
              checked={payloadForRadiology?.gallbladder?.includes("Not assessed")}
              onChange={(e) => {
                const currentValues = payloadForRadiology?.gallbladder?.split(", ").filter(Boolean) || [];
                const updatedValues = e.target.checked
                  ? [...currentValues, "Not assessed"]
                  : currentValues.filter((val) => val !== "Not assessed");
                setPayloadForRadiology({
                  ...payloadForRadiology,
                  gallbladder: updatedValues.join(updatedValues.length > 1 ? ", " : ""),
                });
              }}
            />
          </div>
        </div>


        {payloadForRadiology?.gallbladder?.length ? (
          <div className="flex flex-col gap-2 mt-1">
            <p >Due to: </p>
            <EMultiCheckBoxToString
              data={[
                "Wall echo shadow",
                "Obese",
                "Gas",
                "Porcelain GB"
              ]}
              value={payloadForRadiology?.gallbladder}
              onChange={(e) => {
                setPayloadForRadiology({
                  ...payloadForRadiology,
                  gallbladder: e,
                });
              }}
            />
          </div>
        ) : (
          <> </>
        )}

        <ESectionDivider title="Sludge" />

        <div className="flex flex-row gap-5">
          <ECheckBox
            label="Yes"
            checked={payloadForRadiology?.sludge === "Yes" ? true : false}
            onChange={(value) => {
              setPayloadForRadiology({
                ...payloadForRadiology,
                sludge: value.target.checked ? "Yes" : "",
              });
            }}
          />
          <ECheckBox
            label="No"
            checked={payloadForRadiology?.sludge === "No" ? true : false}
            onChange={(value) => {
              setPayloadForRadiology({
                ...payloadForRadiology,
                sludge: value.target.checked ? "No" : "",
              });
            }}
          />
        </div>

        <ESectionDivider title="GB polyp" />

        <div className="flex flex-row gap-5">
          <ECheckBox
            label="Yes"
            checked={payloadForRadiology?.gb_polyp === "Yes" ? true : false}
            onChange={(value) => {
              setPayloadForRadiology({
                ...payloadForRadiology,
                gb_polyp: value.target.checked ? "Yes" : "",
              });
            }}
          />
          <ECheckBox
            label="No"
            checked={payloadForRadiology?.gb_polyp === "No" ? true : false}
            onChange={(value) => {
              setPayloadForRadiology({
                ...payloadForRadiology,
                gb_polyp: value.target.checked ? "No" : "",
              });
            }}
          />
        </div>

        <ESectionDivider title="Stone" />

        <div className="flex flex-row gap-5">
          <ECheckBox
            label="Absent"
            checked={payloadForRadiology?.stone === "Absent" ? true : false}
            onChange={(value) => {
              setPayloadForRadiology({
                ...payloadForRadiology,
                stone: value.target.checked ? "Absent" : "",
                number_of_stones: "",
                size_of_stones: "",
              });
            }}
          />

          <ECheckBox
            label="Present"
            checked={
              payloadForRadiology?.stone?.includes("Present") ? true : false
            }
            onChange={(value) => {
              setPayloadForRadiology({
                ...payloadForRadiology,
                stone: value.target.checked ? "Present" : "",
                number_of_stones: "",
                size_of_stones: "",
              });
            }}
          />

          <div className="flex flex-col gap-2">
            {payloadForRadiology?.stone?.includes("Present") ? (
              <>
                <ECheckBox
                  label="Single stone"
                  checked={
                    payloadForRadiology?.stone === "Present, Single Stone"
                      ? true
                      : false
                  }
                  onChange={(value) => {
                    setPayloadForRadiology({
                      ...payloadForRadiology,
                      stone: value.target.checked
                        ? "Present, Single Stone"
                        : "Present",
                    });
                  }}
                />
                <ECheckBox
                  label="Multiple stone"
                  checked={
                    payloadForRadiology?.stone === "Present, Multiple Stone"
                      ? true
                      : false
                  }
                  onChange={(value) => {
                    setPayloadForRadiology({
                      ...payloadForRadiology,
                      stone: value.target.checked
                        ? "Present, Multiple Stone"
                        : "Present",
                    });
                  }}
                />
                <ECheckBox
                  label="GB Packed with stones"
                  checked={
                    payloadForRadiology?.stone ===
                      "Present, GB Packed with stones"
                      ? true
                      : false
                  }
                  onChange={(value) => {
                    setPayloadForRadiology({
                      ...payloadForRadiology,
                      stone: value.target.checked
                        ? "Present, GB Packed with stones"
                        : "Present",
                    });
                  }}
                />

                <div className="flex flex-row gap-4">
                  <ENumberInput
                    label="Number of stones"
                    value={payloadForRadiology?.number_of_stones}
                    onChange={(value) => {
                      setPayloadForRadiology({
                        ...payloadForRadiology,
                        number_of_stones: value + "",
                      });
                    }}
                  />
                  <ETextInput
                    label="Size of largest stones(mm)"
                    value={payloadForRadiology?.size_of_stones}
                    onChange={(e) => {
                      setPayloadForRadiology({
                        ...payloadForRadiology,
                        size_of_stones: e.target.value ?? "",
                      });
                    }}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>

        <ESectionDivider title="GB Wall" />
        <div>
          <div className="text-base font-medium">Normal</div>
          <div className="flex flex-row gap-5">
            <ETextInput
              label={"Fundus (mm)"}
              placeholder="Enter fundus"
              className="mt-1"
              value={payloadForRadiology?.gb_wall_fundus}
              onChange={(event) => {
                setPayloadForRadiology({
                  ...payloadForRadiology,
                  gb_wall_fundus: event?.target.value ?? "",
                });
              }}
            />
            <ETextInput
              label={"Body (mm)"}
              placeholder="Enter body"
              className="mt-1"
              value={payloadForRadiology?.gb_wall_body}
              onChange={(event) => {
                setPayloadForRadiology({
                  ...payloadForRadiology,
                  gb_wall_body: event.target.value ?? "",
                });
              }}
            />
            <ETextInput
              label={"Neck (mm)"}
              placeholder="Enter neck"
              className="mt-1"
              value={payloadForRadiology?.gb_wall_neck}
              onChange={(event) => {
                setPayloadForRadiology({
                  ...payloadForRadiology,
                  gb_wall_neck: event.target.value ?? "",
                });
              }}
            />
          </div>
        </div>

        <div className=" mt-4">
          <div className="text-base font-medium">Thickened</div>
          <div className="flex flex-row gap-5 mt-2">
            <ECheckBox
              label="Focal"
              checked={
                payloadForRadiology?.gb_wall_thickened === "Focal"
                  ? true
                  : false
              }
              onChange={(value) => {
                setPayloadForRadiology({
                  ...payloadForRadiology,
                  gb_wall_thickened: value.target.checked ? "Focal" : "",
                });
              }}
            />
            <ECheckBox
              label="Diffuse"
              checked={
                payloadForRadiology?.gb_wall_thickened === "Diffuse"
                  ? true
                  : false
              }
              onChange={(value) => {
                setPayloadForRadiology({
                  ...payloadForRadiology,
                  gb_wall_thickened: value.target.checked ? "Diffuse" : "",
                });
              }}
            />
            <ECheckBox
              label="Asymmetrical"
              checked={
                payloadForRadiology?.gb_wall_thickened === "Asymmetrical"
                  ? true
                  : false
              }
              onChange={(value) => {
                setPayloadForRadiology({
                  ...payloadForRadiology,
                  gb_wall_thickened: value.target.checked ? "Asymmetrical" : "",
                });
              }}
            />
            <ECheckBox
              label="Symmetrical"
              checked={
                payloadForRadiology?.gb_wall_thickened === "Symmetrical"
                  ? true
                  : false
              }
              onChange={(value) => {
                setPayloadForRadiology({
                  ...payloadForRadiology,
                  gb_wall_thickened: value.target.checked ? "Symmetrical" : "",
                });
              }}
            />
          </div>
        </div>

        <div className=" mt-4">
          <div className="text-base font-medium">Layered appearance</div>
          <div className="flex flex-row gap-5 mt-2">
            <ECheckBox
              label="Yes"
              checked={
                payloadForRadiology?.gb_wall_layered_appearance === "Yes"
                  ? true
                  : false
              }
              onChange={(value) => {
                setPayloadForRadiology({
                  ...payloadForRadiology,
                  gb_wall_layered_appearance: value.target.checked ? "Yes" : "",
                });
              }}
            />
            <ECheckBox
              label="No"
              checked={
                payloadForRadiology?.gb_wall_layered_appearance === "No"
                  ? true
                  : false
              }
              onChange={(value) => {
                setPayloadForRadiology({
                  ...payloadForRadiology,
                  gb_wall_layered_appearance: value.target.checked ? "No" : "",
                });
              }}
            />
          </div>
        </div>
        <div className=" mt-4">
          <div className="text-base font-medium">Intramural cyst</div>
          <div className="flex flex-row gap-5 mt-2">
            <ECheckBox
              label="Yes"
              checked={payloadForRadiology?.gb_wall_intramural_cyst === "Yes"}
              onChange={(value) => {
                setPayloadForRadiology({
                  ...payloadForRadiology,
                  gb_wall_intramural_cyst: value.target.checked ? "Yes" : "",
                });
              }}
            />
            <ECheckBox
              label="No"
              checked={payloadForRadiology?.gb_wall_intramural_cyst === "No"}
              onChange={(value) => {
                setPayloadForRadiology({
                  ...payloadForRadiology,
                  gb_wall_intramural_cyst: value.target.checked ? "No" : "",
                });
              }}
            />
          </div>
        </div>
        <div className=" mt-4">
          <div className="text-base font-medium">Intramural echogenic foci</div>
          <div className="flex flex-row gap-5 mt-2">
            <ECheckBox
              label="Yes"
              checked={
                payloadForRadiology?.gb_wall_intramural_echogenic_foci === "Yes"
              }
              onChange={(value) => {
                setPayloadForRadiology({
                  ...payloadForRadiology,
                  gb_wall_intramural_echogenic_foci: value.target.checked
                    ? "Yes"
                    : "",
                });
              }}
            />
            <ECheckBox
              label="No"
              checked={
                payloadForRadiology?.gb_wall_intramural_echogenic_foci === "No"
              }
              onChange={(value) => {
                setPayloadForRadiology({
                  ...payloadForRadiology,
                  gb_wall_intramural_echogenic_foci: value.target.checked
                    ? "No"
                    : "",
                });
              }}
            />
          </div>
        </div>

        <ESectionDivider title="GB interface with liver" />
        <div className="flex flex-row gap-5 mt-4">
          <ECheckBox
            label="Distinct"
            checked={
              payloadForRadiology?.gb_interface_with_liver === "Distinct"
            }
            onChange={(value) => {
              setPayloadForRadiology({
                ...payloadForRadiology,
                gb_interface_with_liver: value.target.checked ? "Distinct" : "",
              });
            }}
          />
          <ECheckBox
            label="Not Distinct"
            checked={
              payloadForRadiology?.gb_interface_with_liver === "Not Distinct"
            }
            onChange={(value) => {
              setPayloadForRadiology({
                ...payloadForRadiology,
                gb_interface_with_liver: value.target.checked
                  ? "Not Distinct"
                  : "",
              });
            }}
          />
        </div>

        <ESectionDivider title="Fatty Liver" />
        <div className="flex flex-row gap-5 mt-4">
          <ECheckBox
            label="No"
            checked={payloadForRadiology?.fatty_liver === "No"}
            onChange={(value) => {
              setPayloadForRadiology({
                ...payloadForRadiology,
                fatty_liver: value.target.checked ? "No" : "",
                grade_of_fatty_liver: "",
              });
            }}
          />
          <div className="flex flex-col gap-2">
            <ECheckBox
              checked={payloadForRadiology?.fatty_liver === "Yes"}
              onChange={(value) => {
                setPayloadForRadiology({
                  ...payloadForRadiology,
                  fatty_liver: value.target.checked ? "Yes" : "",
                  grade_of_fatty_liver: "",
                });
              }}
              label="Yes"
            />
            {payloadForRadiology?.fatty_liver === "Yes" ? (
              // <ETextInput
              //   label="Grade of Fatty Liver"
              //   placeholder="Grade of Fatty Liver"
              //   value={payloadForRadiology?.grade_of_fatty_liver}
              //   onChange={(value) => {
              //     setPayloadForRadiology({
              //       ...payloadForRadiology,
              //       grade_of_fatty_liver: value.target.value,
              //     });
              //   }}
              // />
              <ESelect
                label="Grade of Fatty Liver"
                placeholder="Select Grade of Fatty Liver"
                value={payloadForRadiology?.grade_of_fatty_liver}
                onChange={(value) => {
                  setPayloadForRadiology({
                    ...payloadForRadiology,
                    grade_of_fatty_liver: value || "",
                  })
                }}
                data={[
                  "Grade 1", "Grade 2", "Grade 3"
                ]}
              />
            ) : (
              <></>
            )}
          </div>
        </div>

        <ESectionDivider title="Any complications" />

        <div className="flex flex-row flex-wrap gap-5">
          <ECheckBox
            label="IHBR"
            checked={payloadForRadiology?.any_complications === "IHBR"}
            onChange={(value) => {
              setPayloadForRadiology({
                ...payloadForRadiology,
                any_complications: value.target.checked ? "IHBR" : "",
              });
            }}
          />
          <ECheckBox
            label="Biliary invasion"
            checked={
              payloadForRadiology?.any_complications === "Biliary invasion"
            }
            onChange={(value) => {
              setPayloadForRadiology({
                ...payloadForRadiology,
                any_complications: value.target.checked
                  ? "Biliary invasion"
                  : "",
              });
            }}
          />
          <ECheckBox
            label="Vascular invasion"
            checked={
              payloadForRadiology?.any_complications === "Vascular invasion"
            }
            onChange={(value) => {
              setPayloadForRadiology({
                ...payloadForRadiology,
                any_complications: value.target.checked
                  ? "Vascular invasion"
                  : "",
              });
            }}
          />
          <ECheckBox
            label="Liver SOL"
            checked={payloadForRadiology?.any_complications === "Liver SOL"}
            onChange={(value) => {
              setPayloadForRadiology({
                ...payloadForRadiology,
                any_complications: value.target.checked ? "Liver SOL" : "",
              });
            }}
          />
          <ECheckBox
            label="Calcified GB"
            checked={payloadForRadiology?.any_complications === "Calcified GB"}
            onChange={(value) => {
              setPayloadForRadiology({
                ...payloadForRadiology,
                any_complications: value.target.checked ? "Calcified GB" : "",
              });
            }}
          />
          <ECheckBox
            label="Empyema GB"
            checked={payloadForRadiology?.any_complications === "Empyema GB"}
            onChange={(value) => {
              setPayloadForRadiology({
                ...payloadForRadiology,
                any_complications: value.target.checked ? "Empyema GB" : "",
              });
            }}
          />
          <ECheckBox
            label="Mirizzi syndrome"
            checked={
              payloadForRadiology?.any_complications === "Mirizzi syndrome"
            }
            onChange={(value) => {
              setPayloadForRadiology({
                ...payloadForRadiology,
                any_complications: value.target.checked
                  ? "Mirizzi syndrome"
                  : "",
              });
            }}
          />
          <ECheckBox
            label="Murphy’s sign"
            checked={payloadForRadiology?.any_complications === "Murphy’s sign"}
            onChange={(value) => {
              setPayloadForRadiology({
                ...payloadForRadiology,
                any_complications: value.target.checked ? "Murphy’s sign" : "",
              });
            }}
          />
          <ECheckBox
            label="Pericholecystic collection"
            checked={
              payloadForRadiology?.any_complications ===
              "Pericholecystic collection"
            }
            onChange={(value) => {
              setPayloadForRadiology({
                ...payloadForRadiology,
                any_complications: value.target.checked
                  ? "Pericholecystic collection"
                  : "",
              });
            }}
          />
        </div>

        <ESectionDivider
          title="Standard meal for GBEF"
          subTitle="4 slice of bread + 200 ml full cream milk+20g Amul butter"
          classes="mt-8"
        />

        {/* <FormSectionDivider titleCenter title={"GBEF Done or Not"} /> */}
        <div className="gap-2 flex flex-col">

          <div className="flex flex-row gap-4">
            <ECheckBox
              label="GBEF Done"
              checked={gbefStaus === 'done'}
              onChange={() => handleCheckboxChange('done')}
            />
            <ECheckBox
              label="GBEF Not Done"
              checked={gbefStaus === 'not done'}
              onChange={() => handleCheckboxChange('not done')}
            />
          </div>

          {gbefStaus === 'not done' ? (
            <ETextInput
              label="Why GBEF Not Done?"
              value={reasonforGBEFNotDone}
              className="w-1/2"
              onChange={(e) => setReasonForGBEFNotDone(e.target.value)}
              placeholder="Enter Why GBEF Not Done"
            />
          ) : <></>}

        </div>

        <FormSectionDivider titleCenter title={"Fasting"} />

        <div className="flex flex-row gap-4">
          <ETimeInput
            size="md"
            label="Time"
            value={gbef[0]?.time}
            onChange={(value) => {
              let newGbef = [...gbef];
              newGbef[0].time = value?.target.value;
              setGbef(newGbef);
            }}
          />

          <ENumberInput
            size="md"
            label="GB length"
            value={gbef[0]?.gb_length}
            onChange={(value) => {
              let newGbef = [...gbef];
              newGbef[0].gb_length = value + "";
              setGbef(newGbef);
            }}
          />
          <ENumberInput
            size="md"
            label="GB breadth"
            value={gbef[0]?.gb_breadth}
            onChange={(value) => {
              let newGbef = [...gbef];
              newGbef[0].gb_breadth = value + "";
              setGbef(newGbef);
            }}
          />
          <ENumberInput
            size="md"
            label="GB height"
            value={gbef[0]?.gb_height}
            onChange={(value) => {
              let newGbef = [...gbef];
              newGbef[0].gb_height = value + "";
              setGbef(newGbef);
            }}
          />
          <ETextInput
            size="md"
            label="Means GB volume"
            placeholder="L× B× H× 0.52"
            readOnly
            disabled
            value={fastingVolume ? fastingVolume.toFixed(2) : ""}
          />
        </div>

        <FormSectionDivider titleCenter title={"Postprandial At 30min"} />

        <div className="flex flex-row gap-4">
          <ETimeInput
            size="md"
            label="Time"
            value={gbef[1]?.time}
            onChange={(value) => {
              let newGbef = [...gbef];
              newGbef[1].time = value?.target.value;
              setGbef(newGbef);
            }}
          />

          <ENumberInput
            size="md"
            label="GB length"
            value={gbef[1]?.gb_length}
            onChange={(value) => {
              let newGbef = [...gbef];
              newGbef[1].gb_length = value + "";
              setGbef(newGbef);
            }}
          />
          <ENumberInput
            size="md"
            label="GB breadth"
            value={gbef[1]?.gb_breadth}
            onChange={(value) => {
              let newGbef = [...gbef];
              newGbef[1].gb_breadth = value + "";
              setGbef(newGbef);
            }}
          />
          <ENumberInput
            size="md"
            label="GB height"
            value={gbef[1]?.gb_height}
            onChange={(value) => {
              let newGbef = [...gbef];
              newGbef[1].gb_height = value + "";
              setGbef(newGbef);
            }}
          />
          <ETextInput
            size="md"
            label="Means GB volume"
            placeholder="L× B× H× 0.52"
            readOnly
            disabled
            value={postprandial30Volume ? postprandial30Volume.toFixed(2) : ""}
          />
        </div>
        <FormSectionDivider titleCenter title={"Postprandial At 60min"} />

        <div className="flex flex-row gap-4">
          <ETimeInput
            size="md"
            label="Time"
            value={gbef[2]?.time}
            onChange={(value) => {
              let newGbef = [...gbef];
              newGbef[2].time = value?.target.value;
              setGbef(newGbef);
            }}
          />

          <ENumberInput
            size="md"
            label="GB length"
            value={gbef[2]?.gb_length}
            onChange={(value) => {
              let newGbef = [...gbef];
              newGbef[2].gb_length = value + "";
              setGbef(newGbef);
            }}
          />
          <ENumberInput
            size="md"
            label="GB breadth"
            value={gbef[2]?.gb_breadth}
            onChange={(value) => {
              let newGbef = [...gbef];
              newGbef[2].gb_breadth = value + "";
              setGbef(newGbef);
            }}
          />
          <ENumberInput
            size="md"
            label="GB height"
            value={gbef[2]?.gb_height}
            onChange={(value) => {
              let newGbef = [...gbef];
              newGbef[2].gb_height = value + "";
              setGbef(newGbef);
            }}
          />
          <ETextInput
            size="md"
            label="Means GB volume"
            placeholder="L× B× H× 0.52"
            readOnly
            disabled
            value={postprandial60Volume ? postprandial60Volume.toFixed(2) : ""}
          />
        </div>

        <div className="flex gap-4 mt-4">
          <ETextInput
            size="md"
            label="GBEF 30min"
            placeholder="(Fasting GB volume-Postprandial at 30min volume/Fasting GB volume) x 100"
            readOnly
            disabled
            value={
              fastingVolume && postprandial30Volume
                ? (
                  ((fastingVolume - postprandial30Volume) / fastingVolume) *
                  100
                ).toFixed(2)
                : ""
            }
          />

          <ETextInput
            size="md"
            label="GBEF 60min"
            placeholder="(Fasting GB volume-Postprandial at 60min volume/Fasting GB volume) x 100"
            readOnly
            disabled
            value={
              fastingVolume && postprandial60Volume
                ? (
                  ((fastingVolume - postprandial60Volume) / fastingVolume) *
                  100
                ).toFixed(2)
                : ""
            }
          />
        </div>
      </div>

      <div className="col-span-3  px-3 ">
        <div
          className=" mx-5 p-5 w-full flex flex-col items-center justify-center border border-slate-400 border-dashed  rounded-md cursor-pointer"
          onClick={() => {
            setUploadFileModalIsOpen(true);
          }}
        >
          <MdOutlinePermMedia size={33} />
          <div className="mt-2 text-lg font-semibold">
            Upload Image / PDF / Video
          </div>
        </div>

        <div className="mt-5">
          {mediaFiles.length > 0 ? (
            <ESectionDivider title="Uploaded Media" />
          ) : (
            <></>
          )}
          {mediaFiles?.map((item, index) => {
            return (
              <EMediaItem
                item={item}
                handleOpenMediaInNewTab={handleOpenMediaInNewTab}
                setIsOpenDeleteConfirmModal={setIsOpenDeleteConfirmModal}
                isOpenDeleteConfirmModal={isOpenDeleteConfirmModal}
                handleDeleteImageFromAws={handleDeleteImageFromAws}
              />
            );
          })}
        </div>
      </div>

      <EModal
        isOpen={uploadFileModalIsOpen}
        title={"Upload Media"}
        size="sm"
        setIsOpen={() => {
          setUploadFileModalIsOpen(false);
        }}
        onClose={()=>{
          setFiles([]);
        }}
      >
        <div className="flex justify-end">
          <div className="flex-row">
            <Dropzone
              className="w-80"
              onDrop={(file) => {
                setFiles(file);
              }}
              accept={[
                MIME_TYPES.png,
                MIME_TYPES.jpeg,
                MIME_TYPES.webp,
                MIME_TYPES.pdf,
                MIME_TYPES.mp4,
                MIME_TYPES.heic
              ]}
            >
              <div className="flex justify-center flex-col items-center text-center">
                <MdOutlinePermMedia size={33} />
                {files?.length > 0 ? (
                  <p>Upload Again Picture / pdf /Video</p>
                ) : (
                  <p>Upload Picture / pdf / Video</p>
                )}
              </div>
            </Dropzone>

            <div className="mt-4 max-h-44 overflow-y-scroll">
              {files?.map((file, index) => {
                return (
                  <div
                    key={index}
                    className="gap-2 lowercase text-sm font-medium"
                  >
                    {index + 1 + ". " + file.name}
                  </div>
                );
              })}
            </div>

            {!files?.length ? (
              <></>
            ) : (
              <div className=" flex gap-3 justify-around mt-8">
                <EButton
                  variant="secondary"
                  className="mt-4"
                  onClick={() => {
                    setFiles([]);
                    setUploadFileModalIsOpen(false);
                  }}
                >
                  Cancel
                </EButton>
                <EButton
                  className="mt-4"
                  loading={isMediaBtnLoading}
                  onClick={() => handleUploadFiles(files)}
                >
                  <HiOutlineArrowUpTray className="mr-2" />
                  Upload Media
                </EButton>
              </div>
            )}
          </div>
        </div>
      </EModal>
    </div>
  );
}
