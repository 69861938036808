import { useContext, useEffect, useState } from "react";
import {
  IInvestigation,
  IInvestigationBiochemistry,
  IInvestigationDataBiochemistry,
  IInvestigationDataHaematology,
  IInvestigationDataLft,
  IInvestigationHaematology,
  IInvestigationLiverFunctionTest,
} from "../../../../../types";
import {
  DefaultInvestigationBioChemistry,
  DefaultInvestigationHematology,
  DefaultInvestigationLft,
} from "../../../../../DefaultValue";
import moment from "moment";
import { useDirectusApi } from "../../../../../libs/data-access";
import { showToast } from "../../../../../utils/utility";
import { useSearchParams } from "react-router-dom";
import useUploadFiles from "../../../../Hooks/useUploadFiles";
import { FileWithPath } from "@mantine/dropzone";
import { PatientNewVisitContext } from "../../../PatientsContext";

export default function useInvestigation() {
  const {
    setIsBtnLoading,
    isBtnNextClick,
    activeTab,
    handleNextTab,
    isFormUpdating,
    setIsFormUpdating,
  } = useContext(PatientNewVisitContext);
  const [searchParams] = useSearchParams();
  const patient_id = searchParams.get("patient_id");
  const visit_id = searchParams.get("visit_id");
  const { postDirectData, fetchDirectData, patchDirectData } = useDirectusApi();
  const { handleUploadMedia, handleGetPreviewUrl, handleDeleteAwsImage } =
    useUploadFiles();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [investigationId, setInvestigationId] = useState<number | undefined>(
    undefined
  );
  const [haematology, setHaematology] = useState<
    IInvestigationDataHaematology[]
  >(DefaultInvestigationHematology);
  const [lft, setLft] = useState<IInvestigationDataLft[]>(
    DefaultInvestigationLft
  );
  const [bioChemistry, setBioChemistry] = useState<
    IInvestigationDataBiochemistry[]
  >(DefaultInvestigationBioChemistry);
  const [dateTime, setDateTime] = useState<string>("");

  const [files, setFiles] = useState<FileWithPath[]>([]);
  const [isUploadedMedia, setIsUploadedMedia] = useState(false);
  const [isMediaBtnLoading, setIsMediaBtnLoading] = useState(false);
  const [mediaFiles, setMediaFiles] = useState<string[]>([]);
  const [uploadFileModalIsOpen, setUploadFileModalIsOpen] = useState(false);
  const [isOpenDeleteConfirmModal, setIsOpenDeleteConfirmModal] = useState<boolean>(false);

  useEffect(() => {
    if (isBtnNextClick && activeTab == "investigation") {
      handleSubmitInvestigationForm();
    }
  }, [isBtnNextClick]);

  useEffect(() => {
    if (activeTab == "investigation") {
      getDetailsByVisitId();
    }
  }, [activeTab]);

  type keys = (typeof DefaultInvestigationBioChemistry)[number]["name"];

  const handleChangeBioChemistry = (
    key: keyof IInvestigationBiochemistry | "default",
    value: string,
    whichValue: "first" | "second"
  ) => {
    if (key == "default") {
      setBioChemistry(DefaultInvestigationBioChemistry);
    } else {
      const data = bioChemistry.map((e) => {
        if (e?.key == key) {
          if (whichValue == "second") {
            return { ...e, value2: value };
          } else {
            return { ...e, value1: value };
          }
        } else {
          return e;
        }
      });
      setBioChemistry(data);
    }
  };

  const handleChangeHaematology = (
    key: keyof IInvestigationHaematology | "default",
    value: string,
    whichValue: "first" | "second"
  ) => {
    if (key == "default") {
      setHaematology(DefaultInvestigationHematology);
    }
    // else if(key == "dlc"){
    //   const data = haematology.map((e) => {
    //     if (e?.key == key) {
    //       if (whichValue == "second") {
    //         return { ...e, value2: value+"-" };
    //       } else {
    //         return { ...e, value1: value+"-" };
    //       }
    //     } else {
    //       return e;
    //     }
    //   });
    //   setHaematology(data);
    // }
    else {
      const data = haematology.map((e) => {
        if (e?.key == key) {
          if (whichValue == "second") {
            return { ...e, value2: value };
          } else {
            return { ...e, value1: value };
          }
        } else {
          return e;
        }
      });
      setHaematology(data);
    }
  };

  const handleChangeLft = (
    key: keyof IInvestigationLiverFunctionTest | "default",
    value: string,
    whichValue: "first" | "second"
  ) => {
    if (key == "default") {
      setLft(DefaultInvestigationLft);
    } else {
      const data = lft.map((e) => {
        if (e?.key == key) {
          if (whichValue == "second") {
            return { ...e, value2: value };
          } else {
            return { ...e, value1: value };
          }
        } else {
          return e;
        }
      });
      setLft(data);
    }
  };

  const handlePayload = () => {
    let haematologyTransformedArray: any[] = [];
    let bioChemistryTransformedArray: any[] = [];
    let lftTransformedArray: any[] = [];

    haematology.forEach((item) => {
      const transformedObj: any = {};
      transformedObj[item.key] =
        item?.value1 || item?.value2 ? item.value1 + "," + item?.value2 : "";
      haematologyTransformedArray.push(transformedObj);
    });

    bioChemistry.forEach((item) => {
      const transformedObj: any = {};
      transformedObj[item.key] =
        item?.value1 || item?.value2 ? item.value1 + "," + item?.value2 : "";
      bioChemistryTransformedArray.push(transformedObj);
    });

    lft.forEach((item) => {
      const transformedObj: any = {};
      transformedObj[item.key] =
        item?.value1 || item?.value2 ? item.value1 + "," + item?.value2 : "";
      lftTransformedArray.push(transformedObj);
    });
    // if (patient_id && visit_id) {
    const payload: IInvestigation = {
      user_id: patient_id + "",
      visit_id: visit_id + "",
      date_time: dateTime ? dateTime: moment().format("YYYY-MM-DD HH:mm"),
      haematology: haematologyTransformedArray,
      liver_function_test: lftTransformedArray,
      biochemistry: bioChemistryTransformedArray,
      media_files: JSON.stringify(mediaFiles),
    };

    return payload;
    // }
  };

  const handleSubmitInvestigationForm = () => {
    let payload = handlePayload();
    postInvestigationForm(payload);
  };
  // API FUNCTION

  const getDetailsByVisitId = async () => {
    setIsLoading(true);
    const res = await fetchDirectData(
      `items/investigations?filter[visit_id][_eq]=${visit_id}`
    );
    if (res.isSuccess) {
      if (res.data.length) {
        setIsFormUpdating(true);
        setDateTime(res?.data[0]?.date_time);
        let data: IInvestigation = res?.data[0];
        setInvestigationId(data?.id);
        setMediaFiles(JSON.parse(data?.media_files || "[]"));
        if (data?.haematology?.length) {
          let stateData = haematology;
          data?.haematology?.forEach((e) => {
            const index = stateData.findIndex(
              (each) =>
                each?.key?.toLowerCase()?.replaceAll(" ", "_") ==
                Object.entries(e)[0][0]?.toLowerCase()?.replaceAll(" ", "_")
            );
            stateData[index].value1 = Object.entries(e)[0][1].split(",")[0];
            stateData[index].value2 = Object.entries(e)[0][1].split(",")[1];
          });
          setHaematology(stateData);
        }

        if (data?.liver_function_test?.length) {
          let stateData = lft;
          data?.liver_function_test?.forEach((e) => {
            const index = stateData.findIndex(
              (each) =>
                each?.key?.toLowerCase()?.replaceAll(" ", "_") ==
                Object.entries(e)[0][0]?.toLowerCase()?.replaceAll(" ", "_")
            );
            stateData[index].value1 = Object.entries(e)[0][1].split(",")[0];
            stateData[index].value2 = Object.entries(e)[0][1].split(",")[1];
          });
          setLft(stateData);
        }

        if (data?.biochemistry?.length) {
          let stateData = bioChemistry;
          data?.biochemistry?.forEach((e) => {
            const index = stateData.findIndex(
              (each) =>
                each?.key?.toLowerCase()?.replaceAll(" ", "_") ==
                Object.entries(e)[0][0]?.toLowerCase()?.replaceAll(" ", "_")
            );
            stateData[index].value1 = Object.entries(e)[0][1].split(",")[0];
            stateData[index].value2 = Object.entries(e)[0][1].split(",")[1];
          });
          setBioChemistry(stateData);
        }
      } else {
        setDateTime("");
        setInvestigationId(undefined);
        setMediaFiles([]);
        setHaematology(DefaultInvestigationHematology);
        setLft(DefaultInvestigationLft);
        setBioChemistry(DefaultInvestigationBioChemistry);
        setIsFormUpdating(false);
      }
    } else {
      setDateTime("");
      setInvestigationId(undefined);
      setMediaFiles([]);
      setHaematology(DefaultInvestigationHematology);
      setLft(DefaultInvestigationLft);
      setBioChemistry(DefaultInvestigationBioChemistry);
      showToast({
        type: "error",
        title: "Error",
        message: res?.message || "Something went wrong",
      });
    }
    setUploadFileModalIsOpen(false);
  };
  const postInvestigationForm = async (payload: IInvestigation) => {
    setIsBtnLoading(true);
    if (isFormUpdating) {
      const resp = await patchDirectData(
        `items/investigations/${investigationId}`,
        payload
      );
      if (resp.isSuccess) {
        showToast({
          type: "success",
          message: "Investigation form updated successfully",
        });
        handleNextTab();
      } else {
        showToast({
          type: "error",
          title: "Try again to submit from",
          message: resp?.message || "Something went wrong",
        });
      }
    } else {
      const resp = await postDirectData(`items/investigations`, payload);
      if (resp.isSuccess) {
        showToast({
          type: "success",
          message: "Investigation form submitted successfully",
        });
        handleNextTab();
      } else {
        showToast({
          type: "error",
          title: "Try again to submit from",
          message: resp?.message || "Something went wrong",
        });
      }
    }
    setIsBtnLoading(false);
  };

  const handleUploadFiles = async (files: FileWithPath[]) => {
    setIsMediaBtnLoading(true);
    const uploadedFiles = await handleUploadMedia(
      visit_id + "",
      patient_id + "",
      "investigation",
      files
    );
    if (uploadedFiles?.length > 0) {
      handleUploadFileNamesInDb(uploadedFiles);
    } else {
      showToast({
        type: "error",
        title: "Error",
        message: "Error uploading media files",
      });
    }
  };

  const handleUploadFileNamesInDb = async (uploadedFiles: string[]) => {
    const uniqueSet = new Set([...uploadedFiles, ...mediaFiles]);
    if (patient_id && visit_id) {
      let payload = handlePayload();
      payload = {
        ...payload,
        media_files: JSON.stringify(Array.from(uniqueSet)),
      };
      if (isFormUpdating) {
        // payload.id = previousId;

        const res = await patchDirectData(
          `items/investigations/${investigationId}`,
          payload
        );
        if (res?.isSuccess) {
          showToast({
            type: "success",
            title: "Successfully",
            message: "Media uploaded successfully",
          });
          getDetailsByVisitId();
        } else {
          showToast({
            type: "error",
            title: "error",
            message: res.message || "Error encountered while adding media",
          });
        }
      } else {
        const res = await postDirectData(`items/investigations`, payload);
        if (res?.isSuccess) {
          getDetailsByVisitId();
          showToast({
            type: "success",
            title: "Successfully",
            message: "Media uploaded successfully",
          });
        } else {
          showToast({
            type: "error",
            title: "error",
            message: res.message || "Error encountered while adding media",
          });
        }
      }
    } else {
      showToast({
        type: "error",
        message: "Undefined user",
      });
    }
    setIsMediaBtnLoading(false);
  };

  const handleDeleteImageFromAws = async (fileName: string) => {
    const status = await handleDeleteAwsImage(
      fileName,
      visit_id + "",
      patient_id + "",
      "investigation"
    );
    if (status) {
      setIsOpenDeleteConfirmModal(false)
      handleDeleteMediaFromDb(fileName);
    } else {
      showToast({
        type: "error",
        title: "error",
        message: "Error encountered while deleting media",
      });
    }
  };

  const handleOpenMediaInNewTab = (fileName: string) => {
    let url = handleGetPreviewUrl(
      fileName,
      visit_id + "",
      patient_id + "",
      "investigation"
    );
    window.open(url, "_blank");
  };

  const handleDeleteMediaFromDb = async (fileName: string) => {
    let payload = {
      media_files: JSON.stringify(mediaFiles.filter((e) => e != fileName)),
    };
    const res = await patchDirectData(
      `items/investigations/${investigationId}`,
      payload
    );
    if (res?.isSuccess) {
      showToast({
        type: "success",
        title: "Successfully",
        message: "Media deleted successfully",
      });
      getDetailsByVisitId();
    } else {
      showToast({
        type: "error",
        title: "error",
        message: res.message || "Error encountered while deleting media",
      });
    }
  };

  return {
    haematology,
    handleChangeHaematology,
    lft,
    handleChangeLft,
    bioChemistry,
    handleChangeBioChemistry,
    handleNextTab,
    setUploadFileModalIsOpen,
    uploadFileModalIsOpen,
    handleOpenMediaInNewTab,
    handleDeleteImageFromAws,
    setFiles,
    handleUploadFiles,
    mediaFiles,
    isMediaBtnLoading,
    files,
    dateTime,
    setDateTime,
    isOpenDeleteConfirmModal,
    setIsOpenDeleteConfirmModal
  };
}
