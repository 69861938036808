import { Select, SelectProps } from '@mantine/core'

export function ESelect(props:Readonly<SelectProps>) {
  return (
    <Select
    label={props.label}
    size={props.size}
    className={props.className}
    required={props.required}
    type={props.type}
    description={props.description}
    defaultValue={props.defaultValue}
    nothingFoundMessage={props.nothingFoundMessage}
    value={props.value}
    data={props.data}
    searchable ={props.searchable ?? true}
    disabled={props.disabled}
    readOnly={props.readOnly}
    placeholder={props.placeholder ?? "Select"}
    error={props.error}
    onChange={props.onChange}
    clearable
    
    />
  )
}
