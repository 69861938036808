import React from 'react';

interface IProps {
  title: string;
  classes?: string;
  titleCenter?: boolean;
}

export function FormSectionDivider({ title, classes ,titleCenter }: IProps) {
  return (
    <div
      className={` flex items-center justify-between mt-5 ${
        classes && classes
      }`}
    >
      {titleCenter ? <div className="flex-1 h-[1.5px] bg-slate-200 mr-4"></div>:<></>}
      <div className="text-sm font-semibold capitalize mr-4">{title}</div>
      <div className="flex-1 h-[1.5px] bg-slate-200"></div>
    </div>
  );
}
