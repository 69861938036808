import React from 'react'
import { CiMail } from 'react-icons/ci';
import { FaMailchimp, FaPhoneAlt, FaUserAlt } from 'react-icons/fa';
import { IoMail } from 'react-icons/io5';

export default function SupportTeam() {
    const teams = [
        {
            title: "Research Team",
            description: "The research team is responsible for all academic and investigative work. They work on new innovations and discoveries.",
            member: "Dr. Radhika",
            contact: "9646404092",
        },
        {
            "title": "Technical Team",
            "description": "The technical team handles all technology-related tasks, ensuring everything runs smoothly on the technical side. For any technical queries, please email us.",
            "member": "Support Team",
            "contact": "techteamindiastone@gmail.com"
        }
    ];

    return (
        <div className="min-h-screen bg-gray-100">
            {/* Hero Section */}
            <div className="relative">
                {/* <img
              className="w-full h-64 object-cover"
              src="https://via.placeholder.com/1500x600?text=Our+Amazing+Support+Teams"
              alt="Support Teams"
            /> */}
                <div className=" inset-0 flex items-center justify-center pt-20">
                    <h1 className="text-5xl font-bold text-white bg-black bg-opacity-50 px-4 py-2 rounded-lg">
                        Our Support Teams
                    </h1>
                </div>
            </div>

            {/* Team Cards */}
            <div className="flex flex-col items-center justify-center p-6 space-y-6">
                <h2 className="text-3xl font-semibold text-gray-800 mb-4 text-center">
                    Meet the Teams Who Keep Things Running Smoothly
                </h2>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                    {teams.map((team, index) => (
                        <div
                            key={index}
                            className="bg-white shadow-xl rounded-lg p-6 max-w-[400px] text-center border-t-4 border-blue-500"
                        >
                            {/* Team Title */}
                            <h2 className="text-2xl font-semibold text-blue-600 mb-4">
                                {team.title}
                            </h2>

                            {/* Description */}
                            <p className="text-sm text-gray-600 italic mb-4">
                                {team.description}
                            </p>

                            {/* Member Name with Icon */}
                            <p className="text-lg text-gray-700 mb-2 flex items-center justify-center gap-2">
                                <FaUserAlt className=" text-blue-500" /> {/* User Icon */}
                                <span className="font-semibold">Member:</span> {team.member}
                            </p>


                            {
                                index === 0
                                    ?
                                    <p className="text-lg text-gray-700 flex items-center justify-center gap-2">
                                        <FaPhoneAlt className="text-blue-500" />
                                        <span className="font-semibold">Contact: </span> {team.contact}
                                    </p>
                                    :
                                    <p className="text-lg text-gray-700 flex items-center justify-center gap-2">
                                        <IoMail className="text-blue-500" /> {/* Phone Icon */}
                                        {team.contact}
                                    </p>
                            }
                        </div>
                    ))}
                </div>
            </div>
        </div>)
}

