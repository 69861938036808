import { useContext, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Anchor, Breadcrumbs, TextInput, Image, Avatar, Button, Modal } from "@mantine/core";
import { HiBars3BottomLeft, HiListBullet } from "react-icons/hi2";

import { AppContext } from "../../../../AppContext";

export function ETopbar() {
  const { 
    setIsSidebarCollapsed,
  } = useContext(AppContext);

  // const navigate = useNavigate();
  return (
    <div className="flex w-full items-center">
      
    </div>
  );
}
