import { LoadingOverlay, Slider } from "@mantine/core";
import { ECheckBox, EInfoPanel, ESectionDivider } from "../../../../libs/ui";
import useQualityOfLife from "./hooks/useQualityOfLife";
import { FaHeartbeat } from "react-icons/fa";

export default function QualityOfLife() {
  const { isLoading, qualityOfLife, setQualityOfLife } = useQualityOfLife();

  const marks = [
    { value: 5, label: "5" },
    { value: 10, label: "10" },
    { value: 15, label: "15" },
    { value: 20, label: "20" },
    { value: 25, label: "25" },
    { value: 30, label: "30" },
    { value: 35, label: "35" },
    { value: 40, label: "40" },
    { value: 45, label: "45" },
    { value: 50, label: "50" },
    { value: 55, label: "55" },
    { value: 60, label: "60" },
    { value: 65, label: "65" },
    { value: 70, label: "70" },
    { value: 75, label: "75" },
    { value: 80, label: "80" },
    { value: 85, label: "85" },
    { value: 90, label: "90" },
    { value: 95, label: "95" },
    { value: 100, label: "100" },
  ];

  return (
    <>
      <div className="grid grid-cols-12">
        {/* <div className="col-span-12 border-r pr-3"> */}
        <div className="col-span-12">
          <LoadingOverlay
            visible={isLoading}
            loaderProps={{ color: "blue", type: "bars" }}
          />
          <ESectionDivider title="MOBILITY" />
          {[
            "I have no problems in walking about",
            "I have slight problems in walking about",
            "I have moderate problems in walking about",
            "I have severe problems in walking about",
            "I am unable to walk about",
          ].map((e) => (
            <ECheckBox
              label={e}
              className="mb-3"
              checked={qualityOfLife.mobility === e}
              onChange={(event) => {
                setQualityOfLife((prev) => ({ ...prev, mobility: event.target.checked ? e : "" }));
              }}
            />
          ))}

          <ESectionDivider title="SELF CARE" />
          {[
            "I have no problems in bathing or dressing myself",
            "I have slight problems in bathing or dressing myself",
            "I have moderate problems in bathing or dressing myself",
            "I have severe problems in bathing or dressing myself",
            "I am unable to bathe or dress myself",
          ].map((e) => (
            <ECheckBox
              label={e}
              className="mb-3"
              checked={qualityOfLife.self_care === e}
              onChange={(event) => {
                setQualityOfLife((prev) => ({ ...prev, self_care: event.target.checked ?e :"" }));
              }}
            />
          ))}

          <ESectionDivider title="USUAL ACTIVITIES" subTitle="(e.g. work, study, housework, family or leisure activities)"/>
          {[
            "I have no problems doing my usual activities",
            "I have slight problems doing my usual activities",
            "I have moderate problems doing my usual activities",
            "I have severe problems doing my usual activities",
            "I am unable to do my usual activities",
          ].map((e) => (
            <ECheckBox
              label={e}
              className="mb-3"
              checked={qualityOfLife.usual_activity === e}
              onChange={(event) => {
                setQualityOfLife((prev) => ({ ...prev, usual_activity: event.target.checked ? e : "" }));
              }}
            />
          ))}

          <ESectionDivider title="PAIN / DISCOMFORT" />
          {[
            "I have no pain or discomfort",
            "I have slight pain or discomfort",
            "I have moderate pain or discomfort",
            "I have severe pain or discomfort",
            "I have extreme pain or discomfort",
          ].map((e) => (
            <ECheckBox
              key={e}
              label={e}
              className="mb-3"
              checked={qualityOfLife.pain_description === e}
              onChange={(event) => {
                setQualityOfLife((prev) => ({ ...prev, pain_description: event.target.checked ? e :"" }));
              }}
            />
          ))}

          <ESectionDivider title="ANXIETY / DEPRESSION" />
          {[
            "I am not anxious or depressed",
            "I am slightly anxious or depressed",
            "I am moderately anxious or depressed",
            "I am severely anxious or depressed",
            "I am extremely anxious or depressed",
          ].map((e) => (
            <ECheckBox
              key={e}
              label={e}
              className="mb-3"
              checked={qualityOfLife.anxiety_depression === e}
              onChange={(event) => {
                setQualityOfLife((prev) => ({
                  ...prev,
                  anxiety_depression: event.target.checked ? e : '',
                }));
              }}
            />
          ))}
        </div>
        {/* <div className="col-span-4 pt-20">
            </div> */}
      </div>
      {/* Health Scroll Slider */}
      <div className="px-3 my-14 border pt-5 bg-white rounded-md">
        <div className="w-2/3">
          <EInfoPanel
            title="We would like to know how good or bad your health is
                 TODAY.
                 This scale is numbered from 0 to 100.
                 100 means the best health you can imagine.
                 0 means the worst health you can imagine.
                 Mark an X on the scale to indicate how your health is TODAY."
          />
        </div>

        <div className="flex items-center gap-5 mt-10 mb-5">
          <div className="text-red-500  text-lg font-semibold border border-red-500 rounded-md p-3">
            <p>The worst health </p>
            <p> you can imagine</p>
          </div>
          <Slider
            className="grow"
            thumbSize={40}
            marks={marks}
            thumbLabel="Health"
            value={+qualityOfLife?.health_scale}
            color={
              +qualityOfLife?.health_scale < 30
                ? "red"
                : +qualityOfLife?.health_scale < 50
                ? "orange"
                : +qualityOfLife?.health_scale < 75
                ? "yellow"
                : "green"
            }
            thumbChildren={<FaHeartbeat size={20} />}
            onChange={(e) =>
              setQualityOfLife({ ...qualityOfLife, health_scale: String(e) })
            }
          />
          <div className="text-green-500 text-lg font-semibold border-green-500 rounded-md border p-3">
            <p>The best health </p>
            <p> you can imagine</p>
          </div>
        </div>
      </div>
    </>
  );
}
