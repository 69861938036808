import { Checkbox, CheckboxProps } from '@mantine/core'
import React from 'react'

export function ECheckBox(props:CheckboxProps) {
  return (
    <Checkbox
    label={props.label}
    size={props.size}
    className={props.className}
    type={props.type}
    description={props.description}
    checked={props.checked}
    placeholder={props.placeholder}
    error={props.error}
    onChange={props.onChange}
    disabled={props?.disabled}
    />
  )
}
