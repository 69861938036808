import React, { useContext, useEffect, useState } from "react";
import { PatientContext } from "../../../PatientsContext";
import { IPayloadForAnthropometricMeasurements } from "../../../../../types";
import { useSearchParams } from "react-router-dom";
import { useDirectusApi } from "../../../../../libs/data-access";
import { showToast } from "../../../../../utils/utility";

export default function useAnthropometricMeasurements() {
  const {
    activeTab,
    isBtnNextClick,
    setIsBtnDisable,
    handleNextTab,
    isFormUpdating,
    setIsFormUpdating,
  } = useContext(PatientContext);

  const [searchParams] = useSearchParams();
  const patient_id = searchParams.get("patient_id");
  const visit_id = searchParams.get("visit_id");
  const { postDirectData, fetchDirectData, patchDirectData } = useDirectusApi();

  const [isLoading, setIsLoading] = useState(false);
  const [anthropometricID, setAnthropometricID] = useState<number | undefined>(
    undefined
  );

  const [ecogPerformanceStatus, setEcogPerformanceStatus] = React.useState<
    { label: string; value: number; selected: boolean }[]
  >([
    {
      label:
        "Fully Active, able to carry on all pre-disease performance without restriction",
      value: 1,
      selected: false,
    },
    {
      label:
        "Restricted in physically strenuous activity, but ambulatory and able to carry out work of a light or sedentary nature. E.g. light house work, office work",
      value: 2,
      selected: false,
    },
    {
      label:
        "Ambulatory and capable of all self-care, but unable to carry out any work activities; up and about >50% of waking hours",
      value: 3,
      selected: false,
    },
    {
      label:
        "Capable of only limited self-care; confined to bed or chair > 50% of waking hours",
      value: 4,
      selected: false,
    },
    {
      label:
        "Completely disabled: cannot carry on any self-care; totally confined to bed or chair",
      value: 5,
      selected: false,
    },
    { label: "Dead", value: 6, selected: false },
  ]);

  useEffect(() => {
    setIsBtnDisable(false);
    if (isBtnNextClick && activeTab == "anthropometric") {
      handleSubmitAnthropometricMeasurements();
    }
  }, [isBtnNextClick]);

  useEffect(() => {
    if (activeTab == "anthropometric") {
      getDetailsByVisitId();
    }
  }, [activeTab]);

  const handlePayloadForAnthropometricMeasurements = (
    userId: string,
    visit_id: string
  ) => {
    const payload: IPayloadForAnthropometricMeasurements = {
      user_id: userId,
      visit_id: visit_id,
      clinical_examination_ecog_performance_status:
        ecogPerformanceStatus?.filter((item, index) => item.selected)[0]?.label,
    };

    return payload;
  };

  // APi Function

  const getDetailsByVisitId = async () => {
    setIsLoading(true);
    const res = await fetchDirectData(
      `items/anthropometric_measurements?filter[visit_id][_eq]=${visit_id}`
    );
    if (res.isSuccess) {
      if (res?.data?.length) {
        setIsFormUpdating(true);
        let data: IPayloadForAnthropometricMeasurements = res?.data[0];
        setAnthropometricID(data?.id);

        if (data?.clinical_examination_ecog_performance_status?.length) {
          let stateData = ecogPerformanceStatus;
          const index = stateData.findIndex(
            (each) =>
              each?.label?.toLowerCase()?.replaceAll(" ", "_") ==
              data?.clinical_examination_ecog_performance_status
                ?.toLowerCase()
                ?.replaceAll(" ", "_")
          );
          stateData[index].selected = true;
          setEcogPerformanceStatus(stateData);
        }
      } else {
        setIsFormUpdating(false);
      }
    } else {
      showToast({
        type: "error",
        message: "Something went wrong",
      });
    }
    setIsLoading(false);
  };

  const handleSubmitAnthropometricMeasurements = async () => {
    if (patient_id && visit_id) {
      const payload = handlePayloadForAnthropometricMeasurements(
        patient_id,
        visit_id
      );
      //   postAnthropometricMeasurements(payload);
      if (isFormUpdating) {
        const res = await patchDirectData(
          `items/anthropometric_measurements/${anthropometricID}`,
          payload
        );
        if (res.isSuccess) {
          showToast({
            type: "success",
            title: "Clinical Examination",
            message: "Clinical Examination updated successfully",
          });
          handleNextTab();
        } else {
          showToast({
            type: "error",
            title: "Please try again",
            message: res.message,
          });
        }
      } else {
        const res = await postDirectData(
          `items/anthropometric_measurements`,
          payload
        );
        if (res.isSuccess) {
          showToast({
            type: "success",
            title: "Added gallstones history successfully",
            message: "Gallstones history added",
          });
          handleNextTab();
        } else {
          showToast({
            type: "error",
            title: "Please try again",
            message: res.message,
          });
        }
      }
    } else {
      showToast({
        type: "error",
        title: "Please try again",
        message: "Invalid User",
      });
    }
  };

  return {
    ecogPerformanceStatus,
    setEcogPerformanceStatus,
  };
}
