import { Dispatch, SetStateAction, createContext } from "react";

export interface IPatientContext {
      isBtnLoading: boolean | undefined;
      setIsBtnLoading : Dispatch<SetStateAction<boolean>>;
      isFormUpdating: boolean | undefined;
      setIsFormUpdating : Dispatch<SetStateAction<boolean>>;
      handleNextTab : ()=>void;
      handleBackTab : ()=>void
      // isBtnDisable: boolean | undefined;
      isBtnNextClick: boolean;
      isBtnBackClick: boolean;
      // setIsBtnDisable : Dispatch<SetStateAction<boolean>>;
      progress: number | undefined;
      setProgress : Dispatch<SetStateAction<number>>
      activeTab: string | undefined;
      setActiveTab : Dispatch<SetStateAction<string>>
      activeSubTab: number ;
      setActiveSubTab : Dispatch<SetStateAction<number>>
};

export const PatientNewVisitContext = createContext<IPatientContext>({
     isBtnLoading: false,
     setIsBtnLoading: ()=> {},
     isFormUpdating: false,
     setIsFormUpdating: ()=> {},
     handleNextTab:()=>{},
     handleBackTab:()=>{},
//      isBtnDisable: true,
     isBtnBackClick: false,
     isBtnNextClick: false,
//      setIsBtnDisable: ()=> {},
     progress:0,
     setProgress: ()=> {},
     activeTab:"",
     setActiveTab: ()=> {},
     activeSubTab:1,
     setActiveSubTab: ()=> {}
});