import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HiChevronLeft, HiHome } from "react-icons/hi2";
import { IoMenu } from "react-icons/io5";

import { Modal, Image } from "@mantine/core";

import { EButton } from "../..";
import ENavItem from "./components/navItem/ENavItem";

import "./ENavbar.scss";

import { AppContext } from "../../../../AppContext";
import { IRoute, ROLES, ROUTES } from "../../../../Routes/routes";
import {
  checkRouteBasedOnRoles,
  matchRoute,
} from "../../../../utils/routeUtility";
import { BsMenuButton } from "react-icons/bs";
import { FiLogOut } from "react-icons/fi";

type Props = {
  role: string[] | undefined;
};

export function ENavbar() {
  const {
    isSidebarCollapsed,
    setIsSidebarCollapsed,
    handleLogout,
    role,
    user,
    currentRotes,
    setCurrentRoutes,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [activeRoute, setActiveRoute] = useState<IRoute>({} as IRoute);
  const [logOutModalOpened, setLogOutModalOpened] = useState<boolean>(false);
  const [subNavActiveIndex, setSubNavActiveIndex] = useState<number[]>([]);

  useEffect(() => {
    setActiveRoute(matchRoute(location.pathname));
  }, [location.pathname]);

  const subMenuRecursion = (submenu: IRoute[]): IRoute[] => {
    return submenu
      .map((sub) => (sub?.submenu ? subMenuRecursion(sub?.submenu) : sub))
      .flat();
  };

  const filterRoutesByRoles = (
    routes: IRoute[],
    roleToFilter: string[] | undefined
  ) => {
    const r: IRoute[] = routes
      ?.filter((each) => each.icon)
      ?.filter((route: IRoute) => {
        if (
          // Array.isArray(route.rolesAllowed)
          // &&
          checkRouteBasedOnRoles(roleToFilter, route?.rolesAllowed as string[])
        ) {
          if (route.submenu && route.submenu.length > 0) {
            route.submenu = filterRoutesByRoles(route.submenu, roleToFilter);
          }
          return true;
        } else if (route.submenu && route.submenu.length > 0) {
          route.submenu = filterRoutesByRoles(route.submenu, roleToFilter);
          return route.submenu && route.submenu.length > 0;
        }
        return false;
      });

    return r;
  };

  function getRouteByIndex(routes: IRoute[], indices: number[]) {
    let currentRoutes = routes;
    for (let i = 0; i < indices.length; i++) {
      const index = indices[i];
      if (
        currentRoutes &&
        Array.isArray(currentRoutes) &&
        currentRoutes[index]
      ) {
        currentRoutes = currentRoutes[index].submenu as IRoute[];
      } else {
        return []; // Invalid index or route does not exist
      }
    }
    return currentRoutes;
  }

  const activeRouteArray = useMemo(() => {
    const allRoutes = filterRoutesByRoles(ROUTES.COMMON_ROUTES, role);

    if (subNavActiveIndex?.length) {
      let nestedRoute: IRoute[] | undefined = allRoutes;

      nestedRoute = getRouteByIndex(nestedRoute, subNavActiveIndex)?.filter(
        (each) => each.icon
      );
      // nestedRoute.filter(each => each.icon);

      // subNavActiveIndex.forEach(each => {
      //   // let route = nestedRoute?.filter(each =>
      //   //   checkRouteBasedOnRoles(role, each?.rolesAllowed as string[]))

      //   nestedRoute = nestedRoute
      //     ?
      //     nestedRoute[each]?.submenu
      //       ?
      //       nestedRoute[each].submenu
      //       :
      //       nestedRoute
      //     :
      //     allRoutes;
      // });
      // console.groupEnd();

      if (nestedRoute?.[0]?.path) {
        navigate(nestedRoute[0].path);
      }

      // setCurrentRoutes(nestedRoute)
      return nestedRoute;
    } else {
      navigate("/");
      return allRoutes;
    }
  }, [role, subNavActiveIndex]);
  return (
    <>
      <div className="head">
        <div className={`w-full flex ${isSidebarCollapsed ? "justify-center" : "justify-end"} `}>
        <IoMenu
        className={`${isSidebarCollapsed ? "" : "-mr-2"}  cursor-pointer  `}
         size={32}
         color="white"
         onClick={()=>setIsSidebarCollapsed(!isSidebarCollapsed)}
        />
        </div>
        <div className="flex overflow-hidden justify-center">
        <Image
          maw={80}
          className="rounded-xl overflow-hidden"
          // mx="auto"
           src={require("../../../../assets/pgi-logo.jpeg")}
              alt="India_stone.png"
        />
        </div>
         </div>
         <div className="p-2">
        {/* {isSidebarCollapsed ? <h3>NP</h3> : <h3>NIPUN Portal</h3>} */}
          <div className="gap-3">
            {!isSidebarCollapsed ? (
              <div>
                <div className="text-2xl font-bold">
                  Indiastone Study
                </div>
                <div className="opacity-70 pb-2 text-md font-medium">
                  PGIMER, Chandigarh
                </div>
                <div className="mt-5 flex border-b pb-3 items-center justify-between">
                  <div>
                    <p className="text-md font-medium capitalize">{(user?.first_name ?? "") +" " + (user?.last_name ??"")}</p>
                    <p className="text-sm opacity-50 capitalize">{user?.role?.name}</p>
                  </div>
                  <div
                    className="flex flex-col items-end cursor-pointer bg-white/20 p-2 rounded hover:bg-white/30 hover:cursor-pointer"
                    onClick={() => setLogOutModalOpened(true)}
                  >
                    <FiLogOut size={16} />
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
      </div>

      {/* BACK BUTTON FOR ACTIVE SUBMENU */}
      {subNavActiveIndex?.length ? (
        <div className="mt-6">
          <div
            className="w-fit rounded-lg bg-white/10  p-3 py-2 pr-4 mx-3 flex gap-1 items-center font-semibold text-base cursor-pointer hover:bg-white/20 border-t border-white/10"
            onClick={() => {
              // setSubNavActiveIndex([]);
              // navigate("/");
              let nestedIndexes: number[] = JSON.parse(
                JSON.stringify(subNavActiveIndex)
              );
              const newNestedIndex = nestedIndexes.pop();
              newNestedIndex !== undefined &&
                setSubNavActiveIndex(nestedIndexes);
            }}
          >
            <HiChevronLeft
              size={isSidebarCollapsed ? 24 : 0}
              fontWeight={700}
            />
            {!isSidebarCollapsed ? <span>Back</span> : <></>}
          </div>
          <div className="text-base ml-3 mt-8 pb-2 border-b border-white/10 font-semibold">
            {/* Detailed KPIs */}
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="body">
        <div className="nav-group grid gap-2 mt-12">
          {activeRouteArray &&
            activeRouteArray.length > 0 &&
            activeRouteArray
              ?.filter((each) => each.icon !== undefined)
              .map((route: IRoute, index: number) => {
                return (
                  <ENavItem
                    key={"route" + index}
                    parentIndex={index}
                    icon={<route.icon /> || <HiHome />}
                    label={
                      <span className="flex items-center">
                        <span>{route?.label}</span>
                        {/* {role?.includes('Employee') &&
              ((user?.personaL_PROFILE_STATUS === 'APPROVED' &&
                route?.path?.includes(
                  'employee/employee-profile'
                )) ||
                (user?.servicE_PROFILE_STATUS === 'APPROVED' &&
                  route?.path?.includes(
                    'employee/service-profile'
                  ))) && <VerifiedIconL />} */}
                      </span>
                    }
                    handleClick={() => {
                      // if (route.isSubmenu) {
                      //   setSubNavActiveIndex(prev => [...prev, index]);
                      //   // route?.submenu && navigate(route?.submenu[0].path.replace(':mkcl', mkcl || ''));
                      //   route.submenu && navigate(route.submenu[0].path);
                      // } else {
                      // navigate(route.path.replace(':mkcl', mkcl || ''));
                      navigate(route.path);
                      //   }
                    }}
                    isActive={activeRoute?.path?.includes(route.path)}
                    hasSubMenu={route.isSubmenu}
                    // subMenu={route.submenu}
                    setHoverIndex={(e) => {
                      setSubNavActiveIndex((prev) => [...prev.concat(e)]);
                    }}
                  />
                );
              })}
        </div>
      </div>

      {/* <div className="foot">
          <div className="border--1"></div>
          <ENavItem icon={<AdjustmentsIcon />} label="Help & Support" />
        <ENavItem icon={<BellIcon />} label="Notification" />
        </div> */}

      {/* <div
        className="flex flex-col items-end cursor-pointer"
        onClick={() => setLogOutModalOpened(true)}
      >
        <FiLogOut
          size={20}
        />
      </div> */}

      <Modal
        opened={logOutModalOpened}
        onClose={() => setLogOutModalOpened(false)}
        title="Are you sure, you want to logout?"
      >
        <div className="flex justify-end">
          <EButton
            size="sm"
            className="btn-light"
            onClick={() => {
              handleLogout();
              setLogOutModalOpened(false);
            }}
          >
            Yes, Logout
          </EButton>
        </div>
      </Modal>
    </>
  );
}

export default ENavbar;
