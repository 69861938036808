import { MultiSelect, MultiSelectProps } from '@mantine/core'
import { on } from 'events';
import React from 'react'
import { ETextInput } from '../TextInput/ETextInput';
interface EMultiSelectProps extends Omit<MultiSelectProps, 'value' | 'onChange'> {
    value: string; // Override value to be a string
    onChange: (value: string) => void; // onChange should return a string
}
export function EMultiSelectToString(props: EMultiSelectProps) {
    let otherIndex= props?.value?.split(",")?.findIndex((item) => item.split("-").includes("Others") || item.includes("Others") )
    return (
        <>
        <MultiSelect
            label={props.label}
            size={props.size}
            className={props.className}
            type={props.type}
            data={props.data}
            required={props.required}
            description={props.description}
            value={
                props?.value.length ?
                    props?.value.split(",")
                    : []
            }
            placeholder={props.placeholder}
            error={props.error}
            onChange={(eventValue => {
                if (!eventValue?.length) {
                    props.onChange("")
                }
                else {
                    let noOptionPresent = props?.value?.split(",")?.findIndex((item) => item.split(" ").includes("No"))
                    let noOption = eventValue?.findIndex((item) => item.split(" ").includes("No"))
                    let finalDate = ""
                    if(noOptionPresent !== -1) {
                       eventValue.splice(noOptionPresent, 1)   // Remove No option and add New Item
                       finalDate = eventValue.join(",")
                    }
                    else if (noOption !== -1) {
                        finalDate = eventValue?.[noOption]    // Add Selected No option  to value
                    }
                    else {
                        finalDate = eventValue.join(",")
                    }

                    props.onChange(finalDate)
                }
            }
            )}
            searchable
            clearable
        />
         
         {
            otherIndex !== -1
            ? 
            <ETextInput
            label={"Other "+props.label}
            size={props.size}
            className={props.className} 
            type={props.type}
            value={props?.value[otherIndex]?.split("-")[1]}
            placeholder={"Enter details"}
            error={props.error}
            onChange={(event=>{
                let finalDate = props?.value?.split(",")
                 finalDate[otherIndex] = "Others-"+event.target.value
                props.onChange(finalDate.join(","))
            })}
            />
         :<></>}
        </>
    )
}
