import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Space,
  Table,
  Pagination,
} from "@mantine/core";
import PageLayout from "../../../PageLayout";
import { EPageTitle, EButton, ETable, EModal, ETextInput, ETextArea } from "../../../libs/ui";
import { BsSearch } from "react-icons/bs";
import { DateInput } from "@mantine/dates";
import useAdminPatients from "./hooks/useAdminPatients";
import moment from "moment";

export default function AdminPatientList() {
  const [searchParams] = useSearchParams();
  const centerId = searchParams.get("center_id");
  const center_name = searchParams.get("center_name");
  const center_state = searchParams.get("center_state");

  const {
    totalPage,
    currentPage,
    payloadForAddFollowUpRequest,
    selectedPatient,
    isFollowUpModalOpen,
    patientList,
    filterValue,
    setFilterValue,
    setIsFollowUpModalOpen,
    setSelectedPatient,
    handleAddFollowUpRequest,
    handleUpdatePayload,
    handleGetPatientList,
  } = useAdminPatients();

  useEffect(() => {
    handleGetPatientList(1, centerId ? centerId : "");
  }, [filterValue]);

  return (
    <PageLayout
      header={
        <EPageTitle
          title={center_name + (", " + (center_state || "")) + " Patient List"}
        />
      }
    >
      <div>
        <Space h={"md"} />
        <div className="flex justify-end mb-2">
          <ETextInput
            placeholder="Search"
            rightSection={
              <BsSearch
                className="cursor-pointer text-blue-600"
                size={22}
                onClick={() => { handleGetPatientList(1, centerId ? centerId : "") }}
              />}
            value={filterValue}
            onChange={(e) => setFilterValue(e?.target?.value)}
          />
        </div>
        {patientList?.length ? (
          <ETable hasEndButtons>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Name</Table.Th>
                {/* <Table.Th>Father Name</Table.Th> */}
                <Table.Th>Mobile Number</Table.Th>
                <Table.Th>Alternate Mobile Number</Table.Th>
                <Table.Th>Secondary Alternate Mobile Number</Table.Th>
                <Table.Th>Address</Table.Th>
                <Table.Th>Followup date</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {patientList?.map((e) => (
                <Table.Tr key={e.center_id}>
                  <Table.Td className="link">
                    <div>
                      {e?.first_name +
                        " " +
                        e?.last_name +
                        ` (${e?.age}, ${e?.gender?.charAt(0).toUpperCase()})`}
                    </div>
                  </Table.Td>
                  {/* <Table.Td>{e?.father_name}</Table.Td> */}
                  <Table.Td>{e?.primary_contact_no}</Table.Td>
                  <Table.Td>{e?.alternate_contact_no}</Table.Td>
                  <Table.Td>{e?.secondary_alternate_contact_no}</Table.Td>
                  <Table.Td>{e?.address}</Table.Td>
                  <Table.Td>
                    {e?.follow_ups[0]?.date_created
                      ? moment(e?.follow_ups[0]?.date_created)?.format(
                        "DD MMM YYYY hh:mm"
                      )
                      : "-"}
                  </Table.Td>
                  {/* <Table.Td>{e?.follow_ups[0]?.date_created ? e?.follow_ups[0]?.date_created : "-"}</Table.Td> */}
                  <Table.Td>
                    <EButton
                      variant="outline"
                      size={"xs"}
                      className=""
                      onClick={() => {
                        setSelectedPatient(e);
                        handleUpdatePayload("patient_id", e?.id);
                        handleUpdatePayload("date_created", new Date());
                        handleUpdatePayload("remarks", "");
                        setIsFollowUpModalOpen(true);
                      }}
                    >
                      Tele Followup
                    </EButton>
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </ETable>
        ) : (
          <div className="flex justify-center items-center h-full w-full">
            <p className="mt-5 text-2xl">No data found</p>
          </div>
        )}
        <div className="flex justify-between pb-8">
          <div> </div>
          <Pagination
            size="sm"
            value={currentPage}
            onChange={(e) => handleGetPatientList(e, centerId ? centerId : "")}
            total={totalPage}
          />
        </div>

      </div>
      <EModal
        isOpen={isFollowUpModalOpen}
        title={
          selectedPatient?.first_name +
          " " +
          selectedPatient?.last_name +
          (selectedPatient?.primary_contact_no
            ? " (" + selectedPatient?.primary_contact_no + ")"
            : "")
        }
        size="sm"
        setIsOpen={() => {
          setIsFollowUpModalOpen(false);
        }}
      >
        <div>
          <DateInput
            label="Date"
            disabled
            value={payloadForAddFollowUpRequest?.date_created}
            onChange={(e) => {
              handleUpdatePayload("date_created", e);
            }}
          />
          <ETextArea
            value={payloadForAddFollowUpRequest?.remarks}
            onChange={(e) => {
              handleUpdatePayload("remarks", e?.target?.value);
            }}
            className="mt-5"
            label="Remarks"
          />
        </div>
        <EButton
          disabled={
            !payloadForAddFollowUpRequest?.remarks ||
            !payloadForAddFollowUpRequest?.date_created
          }
          onClick={() => handleAddFollowUpRequest()}
          className="mt-5 w-full flex items-center justify-center"
        >
          Submit
        </EButton>
      </EModal>
    </PageLayout>
  );
}
