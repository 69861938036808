import React, { useContext, useEffect, useState } from "react";
import { useDirectusApi } from "../../../libs/data-access";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  IPatientInformation,
  IVisitList,
} from "../../../libs/data-access/types";
import axios from "axios";
import { API } from "../../../libs/data-access/constant";
import { hasOnlyAlpha, isValidPhone, showToast } from "../../../utils/utility";
import { AppContext } from "../../../AppContext";
import { IPatientInformationError } from "../../../utils/typeForValidorError";

const DefaultError: IPatientInformationError = {
  name: "",
  gender: "",
  age: "",
  address: "",
  houseNo: "",
  city: "",
  locality: "",
  state: "",
  postalCode: "",
  mobileNo: "",
  secondaryNo: "",
  alternateNo: "",
  HospitalNo: "",
  disease: "",
};

export default function usePatientsDetails() {
  const { fetchDirectData, postDirectData, patchDirectData } = useDirectusApi();
  const [searchParams] = useSearchParams();
  const patient_id = searchParams.get("patient_id");
  const navigate = useNavigate();
  const { setColorScheme } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const [isOpenVisitModal, setIsOpenVisitModal] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [patientDetails, setPatientDetails] = useState<IPatientInformation>();
  const [visitList, setVisitList] = useState<IVisitList[]>([]);
  const [uniqueNessCheck, setUniqueNessCheck] = useState<{
    mobileNo: string;
    alternateNo: string;
    secondaryNo: string;
  }>({
    mobileNo: "not_checked",
    alternateNo: "not_checked",
    secondaryNo: "not_checked",
  });
  const [error, setError] = useState<IPatientInformationError>(DefaultError);
  const [editProfile, setEditProfile] = useState<{
    first_name: string;
    last_name: string;
    primary_contact_no: number;
    alternate_contact_no: number;
    secondary_alternate_contact_no: number;
  }>({
    first_name: "",
    last_name: "",
    primary_contact_no: 0,
    alternate_contact_no: 0,
    secondary_alternate_contact_no: 0,
  });
  const [inclusionStatus, setInclusionStatus] = useState<"included" | "excluded" | "">("");
  const [updateInclusionStatus, setUpdateInclusionStatus] = useState<"included" | "excluded" | "">("");
  const [isOpenInfoModal, setIsOpenInfoModal] = useState<boolean>(false);
  const [consentFormUrl, setConsentFormUrl] = useState<string>();

  useEffect(() => {
    setColorScheme({ head: "", body: "", text: "", footer: "" });
    getPatientDetails();
    handleGetVisitList();
  }, []);

  const handleGetVisitList = () => {
    setVisitList([]);
    axios
      .get(`${API}custom/patient-visits?patientId=${patient_id}`)
      .then((res) => {
        setVisitList(res.data.data);
      })
      .catch((err) => {
        console.log("Error in fetching stats", err);
      });
  };

  // API FUNCTION

  const postStartVisit = async () => {
    if (patient_id) {
      const res = await postDirectData(`items/patient_visits`, {
        user_id: patient_id,
      });
      if (res.isSuccess) {
        showToast({
          type: "success",
          message: "Visit started successfully.",
        });
        navigate(
          `/index/patient/new-visit?patient_id=${patient_id}&visit_id=${res?.data?.id}`
        );
        // window.location.reload();
      } else {
        showToast({
          type: "error",
          message: "Error while starting a new visit",
        });
      }
    } else {
      showToast({
        type: "error",
        message: "Undefined user",
      });
    }
    setIsBtnLoading(false);
  };

  const getPatientDetails = async () => {
    setIsLoading(true);
    const res = await fetchDirectData(
      `items/patiant_profile?fields=*.*&filter[user_id]=${patient_id}`
    );
    if (res.isSuccess) {
      setPatientDetails(res.data[0]);
      setEditProfile({
        first_name: res.data[0]?.first_name || "",
        last_name: res.data[0]?.last_name || "",
        primary_contact_no: res.data[0]?.primary_contact_no || 0,
        alternate_contact_no: res.data[0]?.alternate_contact_no || 0,
        secondary_alternate_contact_no:
          res.data[0]?.secondary_alternate_contact_no || 0,
      });
      setInclusionStatus(res.data[0]?.inclusion_status);
      setConsentFormUrl(res.data[0]?.consent_from_url);
    } else {
      showToast({
        type: "error",
        title: "error",
        message: res.message || "Profile Details Not Found.",
      });
    }
    setIsLoading(false);
  };

  const handleError = (key: keyof IPatientInformationError, value: string) => {
    setError((prev) => ({ ...prev, [key]: value }));
  };

  const checkUniqueNumber = async (
    number: string | number,
    type: "mobileNo" | "alternateNo" | "secondaryNo"
  ) => {
    if (!isValidPhone(String(number))) {
      handleError(type, "Invalid mobile no.");
      return;
    } else {
      handleError(type, "");
    }
    const res = await fetchDirectData(
      `items/patiant_profile?filter={ "_or": [ { "primary_contact_no": { "_eq": "${number}" } }, { "alternate_contact_no": { "_eq": ${number} } }, { "secondary_alternate_contact_no": { "_eq": "${number}" } } ] }`
    );
    if (res.isSuccess) {
      if (res?.data?.length > 0) {
        handleError(type, "Mobile no. already exists.");
        setUniqueNessCheck({ ...uniqueNessCheck, [type]: "notUnique" });
      } else {
        setUniqueNessCheck({ ...uniqueNessCheck, [type]: "unique" });
      }
    } else {
      handleError(type, "Something went wrong. Please try again.");
      setUniqueNessCheck({ ...uniqueNessCheck, [type]: "error" });
    }
  };

  const handleChangeInfo = (
    key: keyof {
      first_name: string;
      last_name: string;
      primary_contact_no: number;
      alternate_contact_no: number;
      secondary_alternate_contact_no: number;
    },
    value: number | string | string[]
  ) => {
    setEditProfile((prev) => ({ ...prev, [key]: value }));
  };

  const handleValidPatientInformation = (patientInfo: {
    first_name: string;
    last_name: string;
    primary_contact_no: number;
    alternate_contact_no: number;
    secondary_alternate_contact_no: number;
  }) => {
    let isValidated = true;

    if (
      !patientInfo.first_name.trim() ||
      !hasOnlyAlpha(patientInfo?.first_name)
    ) {
      handleError("name", "Invalid name");
      isValidated = false;
    }
    if (!patientInfo.primary_contact_no) {
      handleError("mobileNo", "Required field mobile");
      isValidated = false;
    }
    if (!isValidPhone(String(patientInfo.primary_contact_no))) {
      handleError("mobileNo", "Invalid mobile no.");
      isValidated = false;
    }
    if (!isValidPhone(String(patientInfo.alternate_contact_no))) {
      handleError("alternateNo", "Invalid mobile no.");
      isValidated = false;
    }

    if (!isValidated) {
      // Display the generic error message
      showToast({
        type: "warning",
        message: " Please fill all required fields",
      });
    }
    return isValidated;
  };

  const updatePatientProfile = async () => {
    let isValid = handleValidPatientInformation(editProfile);

    if (!isValid) {
      return;
    }
    if (
      uniqueNessCheck.mobileNo === "notUnique" ||
      uniqueNessCheck.alternateNo === "notUnique" ||
      uniqueNessCheck.secondaryNo === "notUnique"
    ) {
      showToast({
        type: "warning",
        message: " Please fill all required fields",
      });
      return;
    }

    setIsBtnLoading(true);
    const res = await patchDirectData(
      `items/patiant_profile/${patientDetails?.id}`,
      editProfile
    );
    if (res?.isSuccess) {
      postStartVisit();
    } else {
      showToast({
        type: "error",
        message: "Something went wrong. Please try again.",
      });
      setIsBtnLoading(false);
    }
  };


  const handleUpdateInclusionStatus = async () => {
    setIsBtnLoading(true);
    let payload = {
      inclusion_status: updateInclusionStatus
    }
    const res = await patchDirectData(`items/patiant_profile/${patientDetails?.id}`, payload);
    if (res?.isSuccess) {
      setIsOpenInfoModal(false);
      setInclusionStatus(res?.data?.inclusion_status);
      showToast({
        type: "success",
        title: "Successfully",
        message: "Inclusion status updated successfully",
      });
    }
    else {
      showToast({
        type: "error",
        title: "Error",
        message: res?.message || "Error while updating inclusion status",
      })
      console.log("uploaded media file error", res?.message)
    }
    setIsBtnLoading(false);
  }

  return {
    patient_id,
    navigate,
    isBtnLoading,
    isOpenVisitModal,
    uniqueNessCheck,
    setUniqueNessCheck,
    setIsOpenVisitModal,
    isChecked,
    setIsChecked,
    handleChangeInfo,
    editProfile,
    checkUniqueNumber,
    handleGetVisitList,
    visitList,
    error,
    patientDetails,
    postStartVisit,
    updatePatientProfile,
    handleValidPatientInformation,
    setIsOpenInfoModal,
    isOpenInfoModal,
    setUpdateInclusionStatus,
    consentFormUrl,
    updateInclusionStatus,
    inclusionStatus,
    handleUpdateInclusionStatus
  };
}
