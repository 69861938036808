import { IDirectusUser } from "./types";

export const BASE64_INITIALS = "data:image/jpeg;base64,";

export const initialUser : IDirectusUser = {
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    role: {
        id:"",
        name:""
    }
};
