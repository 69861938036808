import React from "react";
import { Button, ButtonProps, Loader } from "@mantine/core";
import "./EButton.scss";

interface IProps {
  color?: string;
  children: React.ReactNode;
  variant?: "primary" | "secondary" | "clear" | "outline";
  size?: "xs" | "sm" | "md";
  onClick: () => void;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
}

export function EButton(props: any) {
  return (
    <div
      className={`btn btn--${props?.variant ? props?.variant : "primary"}${
        props?.disabled ? "--disabled" : ""
      } btn--${props?.size ? props.size : "md"} ${
        props?.className ? props.className : ""
      } `}
      onClick={() => !props?.disabled ? props.onClick() : {}}
      
      
    >
      {props?.loading ? (
        <Loader className="btn--loader" color="white" size={"xs"} />
      ) : (
        <>{props.children}</>
      )}
    </div>
  );
}
