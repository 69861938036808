import { useContext, useEffect, useState } from "react";
import { useDirectusApi } from "../../../libs/data-access";
import { IEmergencyContactList } from "../../../libs/data-access/types";
import { showToast } from "../../../utils/utility";
import { AppContext } from "../../../AppContext";

export default function useRequestCallback() {
  const { fetchDirectData , patchDirectData } = useDirectusApi();
  const { user } = useContext(AppContext);

  const [requestCallbackData, setRequestCallbackData] = useState<IEmergencyContactList[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenFeedBackModal, setIsOpenFeedBackModal] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);

  const [selectedPatient, setSelectedPatient] = useState<IEmergencyContactList>({} as IEmergencyContactList);
  const [centerFeedback, setCenterFeedback] = useState<string>("");
  let limits = 10;


  const getListByCenterId = async () => {
    setIsLoading(true);
    //  console.log("fetchDirectData");
    let res = await fetchDirectData(
      `items/emergency_contact?meta=filter_count&limit=${limits}&page=${activePage}&filter[status][_neq]=archived&filter[center_id]=${user?.id}&fields=remarks,id,date_created,audio,patient_profile_id.first_name,patient_profile_id.last_name, patient_profile_id.primary_contact_no, patient_profile_id.alternate_contact_no, patient_profile_id.secondary_alternate_contact_no`
    );

    if (res?.isSuccess) {
      if (res?.data?.data?.length > 0) {
        setRequestCallbackData(res?.data?.data);
        setTotalPage(res?.data?.meta?.filter_count / limits );
      }
    } else {
      showToast({
        message: "Error while fetching data",
        type: "error",
      });
    }
    setIsLoading(false);
  };

  const handleSubmitFeedBack = async () => {
    let payload = {
      center_feedback: centerFeedback,
      status: "archived"
    };

    const res = await patchDirectData(`items/emergency_contact/${selectedPatient?.id}`, payload);
    if (res?.isSuccess) {
      showToast({
        type: "success",
        title: "Query resolved",
        message:"Feedback submitted successfully",
      });
       setCenterFeedback("");
       setSelectedPatient({} as IEmergencyContactList)
       setIsOpenFeedBackModal(false) 
       getListByCenterId();
    } else {
      showToast({
        type: "error",
        title: "Error",
        message:
          res?.message ||
          "Error encountered while updating feedback request.",
      });
    }
  };

  return {
    isLoading,
    requestCallbackData,
    getListByCenterId,
    totalPage,
    activePage,
    setActivePage,
    isOpenFeedBackModal,
    setIsOpenFeedBackModal,
    selectedPatient,
    setSelectedPatient,
    centerFeedback,
    setCenterFeedback,
    handleSubmitFeedBack
  };
}
