import { AxiosResponse } from "axios";

import { IDirectusResponse, IResponse, res } from "./types";

/*
 * format return type for API success (Status Code: 200) response
 */
export const handleFormatResponse = (response: AxiosResponse<any, any>) => {
  const data: AxiosResponse<any> = response ?? res;
  let r = res;
  if (data.status) {
    return (r = {
      ...r,
      isSuccess: true,
      data: data?.data?.meta ? data?.data : data.data?.data,
      message: data.statusText ? String(data.statusText) : "success",
    });
  } else {
    return (r = {
      isSuccess: false,
      data: undefined,
      error: data.data,
      message: String(data.status) || "",
    });
  }
};

/*
 * format return type for API request fails
 */
export const handleFormatResponseForError = (error: any) => {
  let r = res;
  return (r = {
    isSuccess: false,
    data: undefined,
    error: error,
    message:
      error?.response?.data?.message ||
      error?.message ||
      "Request could not get processed!",
  });
};

export const camelCaseToTitleCase = (input: string): string => {
  // Split the string by uppercase letters
  const words = input.split(/(?=[A-Z])/);

  // Capitalize the first letter of each word and join them back together
  const titleCase = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return titleCase;
};

export function CommaSeparator(value: number) {
  if (!isNaN(value)) {
    const formattedNumber = new Intl.NumberFormat("en-IN").format(value);
    return formattedNumber;
  } else {
    return 0;
  }
}

export const statesMaster = [
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chhattisgarh',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jharkhand',
  'Karnataka',
  'Kerala',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Odisha',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telangana',
  'Tripura',
  'Uttar Pradesh',
  'Uttarakhand',
  'West Bengal',
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Daman and Diu",
  "Delhi",
  "Jammu and Kashmir",
  "Ladakh",
  "Lakshadweep",
  "Puducherry",
];

export const familyRelativeMember = [
  "Brother",
  "Sister",
  "Mother",
  "Father",
  "Son",
  "Daughter"
]

export const vehicleMaster = [
  "No Vehicle",
  "Bicycle",
  "Scooter",
  "Bike",
  "Car",
  "Others"
]

export const alcoholMaster =  [
  'Beer',
  'Wine',
  'Whiskey',
  'Vodka',
  'Rum',
  'IMFL',
  'Tequila',
  'Gin',
  'Brandy',
  'Desi'
]

export const  nonVegDietMaster =[
  "Fish",
  "Chicken",
  "Mutton",
  "Red meat"
]

export const waterResource =[
  "RO water",
  "Well water",
  "River Water",
  "Municipal committee water",
 "Tubewell water",
]

export const recruitmentMethod =[
  "Community Centers & through media",
  "Teaching /University Hospital",
  "Private clinic/ Hospital",
  "Corporate Hospital",
  "Out Patient Department (OPD)",
  "Cholecystectomy waiting list",
  "In Patient Department (IPD)",
  "None"
]