import React, { useContext, useEffect, useState } from "react";
import {
  ICancerMember,
  IGsMember,
  INewClinicalHistory,
} from "../../../../../libs/data-access/types";
import { PatientNewVisitContext } from "../../../PatientsContext";
import { useSearchParams } from "react-router-dom";
import { showToast } from "../../../../../utils/utility";
import { useDirectusApi } from "../../../../../libs/data-access";

const DefaultNewGsMember: IGsMember = {
  first_degree_relative: "",
  gs: "",
  at_what_age: 0,
  is_symptomatic: "",
  was_surgery_done: false,
  complication: "",
};
const DefaultNewCancerMember: ICancerMember = {
  first_degree_relative: "",
  age_at_diagnosis: 0,
  type_of_cancer: "",
  treatment_received: "",
  stage_of_cancer: "",
};

const DefaultClinicalHistory: INewClinicalHistory = {
  user_id: "",
  comorbidities: "",
  drug_history: [],
  typhoid: [],
  smoking: [],
  alcohol: [],
  exposure_to_industry: "",
  pesticide_use: [
    {
      nfc_status: "",
      for_how_long: "",
      which_one: "",
      personal_protective_gear_used: "",
    },
  ],
  no_of_brothers: "",
  no_of_sisters: "",
  no_of_sons: "",
  no_of_daughters: "",
  family_history: [],
  family_history_of_cancer: [],
  is_family_history_of_gbc: false,
  is_family_history_of_liver_cancer: false,
  is_family_history_of_liver_cirrhosis: false,
  visit_id: "",
  visit_remarks: "",
};

export default function useClinicalHistoryForm() {
  const {
    activeTab,
    setIsBtnLoading,
    isBtnNextClick,
    handleNextTab,
    isFormUpdating,
    setIsFormUpdating,
  } = useContext(PatientNewVisitContext);
  const [searchParams] = useSearchParams();
  const patient_id = searchParams.get("patient_id");
  const visit_id = searchParams.get("visit_id");

  const { postDirectData, fetchDirectData, patchDirectData } = useDirectusApi();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenModalCancerMember, setIsOpenModalCancerMember] =
    useState<boolean>(false);
  const [isOpenModalGsMember, setIsOpenModalGsMember] =
    useState<boolean>(false);

  const [newClinicalHistory, setNewClinicalHistory] =
    useState<INewClinicalHistory>(DefaultClinicalHistory);
  const [newCancerMember, setNewCancerMember] = useState<ICancerMember>(
    DefaultNewCancerMember
  );
  const [newGsMember, setNewGsMember] = useState<IGsMember>(DefaultNewGsMember);

  const [comorbidities, setComorbidities] = useState<
    { label: string; value: boolean; name?: string }[]
  >([
    { label: "Sickle cell anemia", value: false },
    { label: "Cirrhosis", value: false },
    { label: "IBD", value: false },
    { label: "DM", value: false },
    { label: "Asthma", value: false },
    { label: "Hypertension", value: false },
    { label: "CAD", value: false },
    { label: "Hepatitis", value: false },
    { label: "Stroke", value: false },
    { label: "Cancer", value: false },
    { label: "Surgery", value: false },
    { label: "None", value: false },
    { label: "Others", value: false, name: "" },
  ]);
  const [drugsHistory, setDrugsHistory] = useState<
    {
      label: string;
      value: boolean;
      nfc?: "Never" | "Former" | "Current";
      name?: string;
    }[]
  >([
    { label: "Hormone Replacement Therapy", value: false, nfc: "Never" },
    { label: "Total parentral nutrition", value: false, nfc: "Never" },
    { label: "Oral contraceptives", value: false, nfc: "Never" },
    { label: "Fibrates", value: false, nfc: "Never" },
    { label: "Statins", value: false, nfc: "Never" },
    { label: "NSAIDS", value: false, nfc: "Never" },
    { label: "Aspirin", value: false, nfc: "Never" },
    { label: "None", value: false, nfc: "Never" },
    { label: "Others", value: false, nfc: "Never", name: "" },
  ]);

  const [industries, setIndustries] = useState<
    { label: string; value: boolean; name?: string }[]
  >([
    { label: "Oil", value: false },
    { label: "Chemical", value: false },
    { label: "Miner", value: false },
    { label: "Textile", value: false },
    { label: "Paper", value: false },
    { label: "Wood dust", value: false },
    { label: "Paint dust", value: false },
    { label: "Fumes", value: false },
    { label: "Asbestos", value: false },
    { label: "Rubber", value: false },
    { label: "None", value: false },
    { label: "Others", value: false, name: "" },
  ]);

  const [symptomsOfDyspepsia, setSymptomsOfDyspepsia] = React.useState<
    { label: string; selected: boolean; remarks: string; value: number }[]
  >([
    {
      label: "Bothersome epigastric pain",
      selected: false,
      remarks: "",
      value: 1,
    },
    {
      label: "Bothersome epigastric burning",
      selected: false,
      remarks: "",
      value: 2,
    },
    {
      label: "Bothersome early satiety",
      selected: false,
      remarks: "",
      value: 3,
    },
    {
      label: "Bothersome postprandial fullness",
      selected: false,
      remarks: "",
      value: 4,
    },
    {
      label: "Overall duration of symptoms ≥ 3m",
      selected: false,
      remarks: "",
      value: 5,
    },
    {
      label: "Criteria fulfilled for ≥ 6m",
      selected: false,
      remarks: "",
      value: 6,
    },
  ]);

  const [dyspepsiaScoringIndex, setDyspepsiaScoringIndex] = React.useState<
    { label: string; selected: boolean; score: string }[]
  >([
    { label: "Epigastric pain", selected: false, score: "" },
    { label: "Retrosternal pain", selected: false, score: "" },
    { label: "Regurgitation", selected: false, score: "" },
    { label: "Nausea", selected: false, score: "" },
    {
      label: "Vomiting",
      selected: false,
      score: "",
    },
    { label: "Belching", selected: false, score: "" },
    { label: "Early Satiety", selected: false, score: "" },
    { label: "Dysphagia", selected: false, score: "" },
  ]);

  const [finalLeadScoreForDyspepsia, setFinalLeadScoreForDyspepsia] =
    useState<string>("");

  const [scoringIndex, setScoringIndex] = React.useState<
    { label: string; selected: boolean; score: string }[]
  >([
    { label: "Very mild", selected: false, score: "1" },
    { label: "Mild", selected: false, score: "2" },
    { label: "Moderate", selected: false, score: "3" },
    { label: "Severe", selected: false, score: "4" },
    { label: "Very severe", selected: false, score: "5" },
  ]);

  useEffect(() => {
    if (isBtnNextClick && activeTab == "clinical_history") {
      handleSubmitClinicalHistoryForm();
    }
  }, [isBtnNextClick]);

  useEffect(() => {
    if (activeTab == "clinical_history") {
      getDetailsByVisitId();
    }
  }, [activeTab]);

  useEffect(() => {
    let count = 0;
    let dataArr = dyspepsiaScoringIndex.filter((e) => e?.selected);
    dataArr?.forEach((each) => {
      count = each?.score ? count + +each?.score : count;
    });
    let finalData = dataArr?.length ? count / dataArr?.length : 0;
    setFinalLeadScoreForDyspepsia(
      finalData ? String(Math.round(finalData)) : ""
    );
  }, [dyspepsiaScoringIndex]);

  useEffect(() => {
    handleChangeClinicalHistory(
      "exposure_to_industry",
      industries
        ?.filter((item) => item.value)
        .map((item) =>
          item.label == "Others"
            ? item.name
              ? "Others-" + item?.name
              : ""
            : item.label
        )
        .join(", ")
    );
  }, [industries]);

  //   HANDLER FUNCTION

  const handleChangeGsMember = (
    key: keyof IGsMember | "default",
    value: number | string | boolean
  ) => {
    if (key === "default") {
      setNewGsMember(DefaultNewGsMember);
    } else {
      setNewGsMember((prev) => ({ ...prev, [key]: value }));
    }
  };
  const handleChangeCancerMember = (
    key: keyof ICancerMember | "default",
    value: number | string
  ) => {
    if (key === "default") {
      setNewCancerMember(DefaultNewCancerMember);
    } else {
      setNewCancerMember((prev) => ({ ...prev, [key]: value }));
    }
  };

  const handleChangeClinicalHistory = (
    key:
      | keyof INewClinicalHistory
      | "pesticide_use_for_how_long"
      | "pesticide_use_which_one"
      | "pesticide_use_gear_used",
    value: number | string | boolean | any[],
    subKey?: string
  ) => {
    let pesticide_use = newClinicalHistory?.pesticide_use ?? [
      {
        for_how_long: "",
        personal_protective_gear_used: "",
        which_one: "",
      },
    ];
    if (key == "pesticide_use_for_how_long") {
      const data = pesticide_use[0];
      const updatedDate = { ...data, for_how_long: String(value) };
      setNewClinicalHistory((prev) => ({
        ...prev,
        pesticide_use: [updatedDate],
      }));
    } else if (key == "pesticide_use_which_one") {
      const data = pesticide_use[0];
      const updatedDate = { ...data, which_one: String(value) };
      setNewClinicalHistory((prev) => ({
        ...prev,
        pesticide_use: [updatedDate],
      }));
    } else if (key == "pesticide_use_gear_used") {
      const data = pesticide_use[0];
      const updatedDate = {
        ...data,
        personal_protective_gear_used: String(value),
      };
      setNewClinicalHistory((prev) => ({
        ...prev,
        pesticide_use: [updatedDate],
      }));
    } else if (subKey) {
      const data: any[] = newClinicalHistory?.[key] as any[];
      // let data = newClinicalHistory?.[key];
      if (data) {
        const firstIndex = data[0];
        const updatedDate = { ...firstIndex, [subKey]: value };
        setNewClinicalHistory((prev) => ({ ...prev, [key]: [updatedDate] }));
      } else {
        setNewClinicalHistory((prev) => ({
          ...prev,
          [key]: [{ subKey: value }],
        }));
      }
    } else {
      setNewClinicalHistory((prev) => ({ ...prev, [key]: value }));
    }
  };

  const handleSubmitClinicalHistoryForm = () => {
    if (patient_id && visit_id) {
      let payload: INewClinicalHistory = {
        ...newClinicalHistory,
        user_id: patient_id,
        visit_id: visit_id,
        comorbidities: comorbidities
          ?.filter((item) => item.value)
          ?.map((item) =>
            item?.label == "Others"
              ? item?.name
                ? "Others-" + item?.name
                : ""
              : item?.label
          )
          .join(","),
        drug_history: drugsHistory?.map((e) => ({
          drug_name: `${e?.label}${e.name ? "+" + e?.name : ""}`,
          nfc_status: e?.nfc ? e?.nfc : "Never",
        })),
      };
      // const valid = handleValidClinicalHistory(payload);
      // if (valid) {
      postNewClinicalHistory(payload);
      // }
    } else {
      showToast({
        type: "error",
        title: "Please try again",
        message: "User id not found !",
      });
    }
  };

  // API FUNCTION

  const getDetailsByVisitId = async () => {
    setIsLoading(true);
    const res = await fetchDirectData(
      `items/clinical_history?filter[visit_id][_eq]=${visit_id}`
    );
    if (res.isSuccess) {
      if (res?.data?.length) {
        setIsFormUpdating(true);
        let data: INewClinicalHistory = res?.data[0];
        setNewClinicalHistory(data);
        if (data?.comorbidities) {
          if (data?.comorbidities?.split(",").length > 1) {
            let indus = comorbidities;
            data?.comorbidities?.split(",").forEach((item) => {
              let index = indus.findIndex((e) => e?.label === item.trim());
              let OtherIndex = indus.findIndex((e) => e?.label === "Others");
              if (item.includes("Others")) {
                indus[OtherIndex].value = true;
                indus[OtherIndex].name = item.split("-")?.[1];
              } else {
                if (index >= 0) {
                  indus[index].value = true;
                } else {
                  indus.push({
                    label: item.split("-")?.[1] ? item.split("-")?.[1] : item,
                    value: true,
                  });
                }
              }
            });
            setComorbidities(indus);
          } else {
            let indus = comorbidities;
            const index = indus.findIndex(
              (e) => e?.label === data?.comorbidities
            );
            if (data?.comorbidities?.includes("Others")) {
              let OtherIndex = indus.findIndex((e) => e?.label === "Others");

              indus[OtherIndex].value = true;
              indus[OtherIndex].name = data?.comorbidities.split("-")?.[1];
            } else {
              if (index) {
                indus[index].value = true;
                setComorbidities(indus);
              }
            }
          }
        }
        if (data?.drug_history) {
          if (data?.drug_history?.length > 1) {
            let newDrugs = drugsHistory;
            data?.drug_history?.forEach((item) => {
              const index = newDrugs.findIndex(
                (e) => e?.label === item?.drug_name
              );
              const OtherIndex = newDrugs.findIndex((e) =>
                e?.label.includes("Others")
              );
              if (index >= 0 && item?.nfc_status !== "Never") {
                newDrugs[index].value = true;
                newDrugs[index].nfc = item?.nfc_status as any;
              }
              if (item?.drug_name.includes("Others")) {
                newDrugs[OtherIndex].value = true;
                newDrugs[OtherIndex].nfc = item?.nfc_status as any;
                newDrugs[OtherIndex].name = item?.drug_name.split("+")?.[1];
              }
            });
            setDrugsHistory(newDrugs);
          } else {
            let newDrugs = drugsHistory;
            const index = newDrugs.findIndex(
              (e) => e?.label === data?.drug_history[0]?.drug_name
            );
            if (index) {
              newDrugs[index].value = true;
              newDrugs[index].nfc = data?.drug_history[0]?.nfc_status as any;

              setDrugsHistory(newDrugs);
            }
          }
        }

        if (data?.exposure_to_industry) {
          if (data?.exposure_to_industry?.split(",").length > 1) {
            let indus = industries;
            data?.exposure_to_industry?.split(",").forEach((item) => {
              const index = indus.findIndex((e) => e?.label === item.trim());
              if (index >= 0) {
                indus[index].value = true;
              } else {
                indus.push({
                  label: item.split("-")?.[1] ? item.split("-")?.[1] : item,
                  value: true,
                });
              }
            });
            // setIndustries(indus)
          } else {
            let indus = industries;
            const index = indus.findIndex(
              (e) => e?.label === data?.exposure_to_industry
            );
            if (index) {
              indus[index].value = true;
              // setIndustries(indus)
            }
          }
        }
      } else {
        setIsFormUpdating(false);
      }
    } else {
      showToast({
        type: "error",
        message: "Something went wrong",
      });
    }
    setIsLoading(false);
  };

  const postNewClinicalHistory = async (payload: INewClinicalHistory) => {
    setIsBtnLoading(true);
    if (isFormUpdating) {
      const res = await patchDirectData(
        `items/clinical_history/${newClinicalHistory?.id}`,
        payload
      );
      if (res?.isSuccess) {
        showToast({
          type: "success",
          title: "Successfully",
          message: "Clinical history updated successfully",
        });
        handleNextTab();
      } else {
        showToast({
          type: "error",
          title: "error",
          message:
            res.message || "Error encountered while updating clinical history.",
        });
      }
    } else {
      const res = await postDirectData(`items/clinical_history`, payload);
      if (res?.isSuccess) {
        showToast({
          type: "success",
          title: "Successfully",
          message: "Clinical history uploaded successfully",
        });
        handleNextTab();
      } else {
        showToast({
          type: "error",
          title: "error",
          message:
            res.message ||
            "Error encountered while uploading clinical history.",
        });
      }
    }
    setIsBtnLoading(false);
  };
  return {
    comorbidities,
    setComorbidities,
    drugsHistory,
    setDrugsHistory,
    isOpenModalCancerMember,
    setIsOpenModalCancerMember,
    isOpenModalGsMember,
    setIsOpenModalGsMember,
    newGsMember,
    handleChangeGsMember,
    newCancerMember,
    handleChangeCancerMember,
    newClinicalHistory,
    handleChangeClinicalHistory,
    symptomsOfDyspepsia,
    setSymptomsOfDyspepsia,
    dyspepsiaScoringIndex,
    setDyspepsiaScoringIndex,
    finalLeadScoreForDyspepsia,
    scoringIndex,
    industries,
    setIndustries,
  };
}
