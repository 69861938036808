
import axios from 'axios';
import React from 'react'
import { API } from '../constant';
import { handleFormatResponse, handleFormatResponseForError } from '../utility';

export function useDirectusApi() {

const fetchDirectData = async (endpoint: string) => {
    try {
       
        let response = await axios.get(`${API}${endpoint}`)
        const formattedResponse = handleFormatResponse(response);
        return formattedResponse;

    } catch (error: any) {
        const formattedError = handleFormatResponseForError(error);
        return formattedError;
    };
};  
const postDirectData = async (endpoint: string ,payload :any) => {
    try {
       
        let response = await axios.post(`${API}${endpoint}`,payload)
        const formattedResponse = handleFormatResponse(response);
        return formattedResponse;

    } catch (error: any) {
        const formattedError = handleFormatResponseForError(error);
        return formattedError;
    };
};  
const patchDirectData = async (endpoint: string ,payload :any) => {
    try {
       
        let response = await axios.patch(`${API}${endpoint}`,payload)
        const formattedResponse = handleFormatResponse(response);
        return formattedResponse;

    } catch (error: any) {
        const formattedError = handleFormatResponseForError(error);
        return formattedError;
    };
};  

return{
    fetchDirectData,
    postDirectData,
    patchDirectData
}
}

