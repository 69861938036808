import axios from "axios";
import React from "react";
import { API, API_JAVA } from "../libs/data-access/constant";
import { showToast } from "../utils/utility";

export default function useReport() {
  // const handleDownloadBaseLineReport = () => {
  //   axios({
  //     method: "get",
  //     url: `${API}custom/download-patient-report`,
  //     responseType: "arraybuffer",
  //   })
  //     .then((response) => {
  //       const blob = new Blob([response.data], {
  //         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //       });
  //       const link = document.createElement("a");
  //       link.href = window.URL.createObjectURL(blob);
  //       link.download = "patient_report.xlsx";
  //       link.click();

  //       showToast({
  //         type: "success",
  //         message: "Report Downloaded",
  //       });
  //     })
  //     .catch((error) => {
  //       console.error("Error downloading report", error);
  //       showToast({
  //         type: "error",
  //         message: "Error downloading report",
  //       });
  //     });
  // };


  const handleDownloadBaseLineReport = (
    centerId?: string, 
    diseaseCategory?: "asymptomatic_gallstone" | "symptomatic_gallstone" | "gallbladder_cancer" | "non_gallstone_control"
  ) => {
    
    // Base URL
    let endPoint = `${API_JAVA}excelForFirstVisit`;
  
    // Add query parameters conditionally
    if (centerId && diseaseCategory) {
      endPoint += `?CenterId=${centerId}&diseaseCategory=${diseaseCategory}`;
    } else if (centerId) {
      endPoint += `?CenterId=${centerId}`;
    } else if (diseaseCategory) {
      endPoint += `?diseaseCategory=${diseaseCategory}`;
    }
  
    // Triggering download by opening the URL in a new window/tab
    window.open(endPoint, "_blank");
  };


  const handleDownloadFollowUpReport = (
    centerId?: string, 
    diseaseCategory?: "asymptomatic_gallstone" | "symptomatic_gallstone" | "gallbladder_cancer" | "non_gallstone_control"
  ) => {
    
    // Base URL
    let endPoint = `${API_JAVA}excelForRepeatedVisit`;
  
    // Add query parameters conditionally
    if (centerId && diseaseCategory) {
      endPoint += `?CenterId=${centerId}&diseaseCategory=${diseaseCategory}`;
    } else if (centerId) {
      endPoint += `?CenterId=${centerId}`;
    } else if (diseaseCategory) {
      endPoint += `?diseaseCategory=${diseaseCategory}`;
    }
  
    // Triggering download by opening the URL in a new window/tab
    window.open(endPoint, "_blank");
  };
  
  return {
    handleDownloadBaseLineReport,
    handleDownloadFollowUpReport
  };
}
