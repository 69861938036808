import { CheckboxProps } from '@mantine/core';
import React from 'react';
import { ECheckBox } from './ECheckBox';

interface EMultiSelectProps extends Omit<CheckboxProps, 'value' | 'onChange' | 'data'> {
    value: string; // Override value to be a comma-separated string
    onChange: (value: string) => void; // onChange should return a string
    data: string[]; // List of options
}

const EMultiCheckBoxToString = (props: EMultiSelectProps) => {
    return (
        <>
            {props?.data?.map((item, index) => (
                <ECheckBox
                    key={index}
                    size={props.size}
                    error={props.error}   
                    label={item}
                    description={props.description}
                    checked={props.value.split(", ").includes(item)}
                    onChange={(event) => {
                        const currentValues = props.value.split(", ").filter(Boolean); // Splitting and filtering empty strings
                        const newValue = event.target.checked
                            ? [...currentValues, item] // Add item if checked
                            : currentValues.filter((val) => val !== item); // Remove item if unchecked
                        props.onChange(newValue.join(", ")); // Join back to comma-separated string
                    }}
                />
            ))}
        </>
    );
};

export default EMultiCheckBoxToString;
