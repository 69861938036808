import React, { useContext, useEffect, useState } from "react";
import {
  IGBEF,
  IPayloadForHistology,
  IPayloadForRadiology,
} from "../../../../../types";
import { DefaultValueForGBEF } from "../../../../../DefaultValue";
import { PatientNewVisitContext } from "../../../PatientsContext";
import { useSearchParams } from "react-router-dom";
import { useDirectusApi } from "../../../../../libs/data-access";
import { showToast } from "../../../../../utils/utility";
import { FileWithPath } from "@mantine/dropzone";
import useUploadFiles from "../../../../Hooks/useUploadFiles";

export default function useRadiology() {

  const {
    activeTab,
    isBtnNextClick,
    setIsBtnLoading,
    handleNextTab,
    isFormUpdating,
    setIsFormUpdating,
  } = useContext(PatientNewVisitContext);

  const { handleUploadMedia, handleDeleteAwsImage, handleGetPreviewUrl } =
    useUploadFiles();


  const [searchParams] = useSearchParams();
  const patientId = searchParams.get("patient_id");
  const visit_id = searchParams.get("visit_id");
  const { postDirectData, fetchDirectData, patchDirectData } = useDirectusApi();


  const [payloadForRadiology, setPayloadForRadiology] =
    useState<IPayloadForRadiology>({} as IPayloadForRadiology);
  const [gbef, setGbef] = useState<IGBEF[]>(DefaultValueForGBEF);

  const [files, setFiles] = useState<FileWithPath[]>([]);
  const [isUploadedMedia, setIsUploadedMedia] = useState(false);
  const [isMediaBtnLoading, setIsMediaBtnLoading] = useState(false);
  const [mediaFiles, setMediaFiles] = useState<string[]>([]);
  const [uploadFileModalIsOpen, setUploadFileModalIsOpen] = useState(false);
  const [previousId, setPreviousId] = useState<number | undefined>(undefined);
  const [isOpenDeleteConfirmModal, setIsOpenDeleteConfirmModal] =
    useState<boolean>(false);
  const [gbefStaus, setGbefStatus] = useState<'done' | 'not done' | null>(null);
  const [reasonforGBEFNotDone, setReasonForGBEFNotDone] = useState<string>('');

  const handleCheckboxChange = (state: 'done' | 'not done' | null) => {
    setGbefStatus(prevState => prevState === state ? null : state);
    setReasonForGBEFNotDone('');
    // if (state === 'done') {
    //   setReason(''); // Reset reason if "GBEF Done" is selected or deselected
    // }
  };

  useEffect(() => {
    if (isBtnNextClick && activeTab == "radiology") {
      handleSubmitRadiologyForm();
    }
  }, [isBtnNextClick]);

  useEffect(() => {
    if (activeTab == "radiology") {
      getRadiologyDetailsByVisitId();
    }
  }, [activeTab]);

  // HANDLER FUNCTION

  const handleUploadFiles = async (files: FileWithPath[]) => {
    setIsMediaBtnLoading(true);
    const uploadedFiles = await handleUploadMedia(
      visit_id + "",
      patientId + "",
      "radiology",
      files
    );
    if (uploadedFiles?.length > 0) {
      handleUploadFileNamesInDb(uploadedFiles);
    } else {
      showToast({
        type: "error",
        title: "Error",
        message: "Error uploading media files",
      });
    }
  };

  const handleUploadFileNamesInDb = async (uploadedFiles: string[]) => {
    if (patientId && visit_id) {
      const uniqueSet = new Set([...uploadedFiles, ...mediaFiles]);
      let payload: IPayloadForRadiology = {
        ...payloadForRadiology,
        gbef_status: gbefStaus || '',
        why_gbef_not_done: reasonforGBEFNotDone || '',
        visit_id: +visit_id,
        user_id: patientId,
        status: "published",
        media_files: JSON.stringify(Array.from(uniqueSet)),
        gallbladder_ejection_fraction: gbef?.map(e => ({
          ...e,
          mean_gb_volume: Number(e?.gb_length) &&
            Number(e?.gb_breadth) &&
            Number(e?.gb_height)
            ? (Number(e?.gb_length) *
              Number(e?.gb_breadth) *
              Number(e?.gb_height) *
              0.52).toFixed(2).toString()
            : ""
        }))
      };
      if (isFormUpdating) {
        // payload.id = previousId;

        const res = await patchDirectData(
          `items/radiology/${payloadForRadiology?.id}`,
          { media_files: JSON.stringify(Array.from(uniqueSet)) }
        );
        if (res?.isSuccess) {
          showToast({
            type: "success",
            title: "Successfully",
            message: "Media uploaded successfully",
          });
          getRadiologyDetailsByVisitId();
        } else {
          showToast({
            type: "error",
            title: "error",
            message: res.message || "Error encountered while adding media",
          });
        }
      } else {
        const res = await postDirectData(
          `items/radiology`,
          payload
        );
        if (res?.isSuccess) {
          getRadiologyDetailsByVisitId();
          showToast({
            type: "success",
            title: "Successfully",
            message: "Media uploaded successfully",
          });
        } else {
          showToast({
            type: "error",
            title: "error",
            message: res.message || "Error encountered while adding media",
          });
        }
      }
    } else {
      showToast({
        type: "error",
        message: "Undefined user",
      });
    }
    setIsMediaBtnLoading(false);
  };

  const handleOpenMediaInNewTab = (fileName: string) => {
    let url = handleGetPreviewUrl(
      fileName,
      visit_id + "",
      patientId + "",
      "radiology"
    );
    window.open(url, "_blank");
  };

  const handleDeleteImageFromAws = async (fileName: string) => {
    const status = await handleDeleteAwsImage(
      fileName,
      visit_id + "",
      patientId + "",
      "radiology"
    );
    if (status) {
      setIsOpenDeleteConfirmModal(false);
      handleDeleteMediaFromDb(fileName);
    } else {
      showToast({
        type: "error",
        title: "error",
        message: "Error encountered while deleting media",
      });
    }
  };

  const handleDeleteMediaFromDb = async (fileName: string) => {
    let payload = {
      media_files: JSON.stringify(mediaFiles.filter((e) => e != fileName)),
    };
    const res = await patchDirectData(
      `items/radiology/${payloadForRadiology?.id}`,
      payload
    );
    if (res?.isSuccess) {
      showToast({
        type: "success",
        title: "Successfully",
        message: "Media deleted successfully",
      });
      getRadiologyDetailsByVisitId();
    } else {
      showToast({
        type: "error",
        title: "error",
        message: res.message || "Error encountered while deleting media",
      });
    }
  };

  //  API FUNCTION

  const getRadiologyDetailsByVisitId = async () => {
    const res = await fetchDirectData(`items/radiology?filter[visit_id][_eq]=${visit_id}`);
    if (res.isSuccess) {
      if (res?.data?.length) {
        setPayloadForRadiology(res.data[0]);
        setGbef(res.data[0].gallbladder_ejection_fraction);
        setMediaFiles(JSON.parse(res?.data[0]?.media_files) || []);
        setGbefStatus(res.data[0].gbef_status);
        setReasonForGBEFNotDone(res.data[0].reason_for_not_done);
        setIsFormUpdating(true);
      } else {
        setIsFormUpdating(false);
      }
    } else {
      showToast({
        type: "error",
        message: "Something went wrong",
      });
    }
  };

  const handleSubmitRadiologyForm = async () => {
    if (visit_id && patientId) {
      let payload: IPayloadForRadiology = {
        ...payloadForRadiology,
        gbef_status: gbefStaus || '',
        why_gbef_not_done: reasonforGBEFNotDone || '',
        status: "published",
        visit_id: +visit_id,
        user_id: patientId,
        gallbladder_ejection_fraction: gbef?.map(e => ({
          ...e,
          mean_gb_volume: Number(e?.gb_length) &&
            Number(e?.gb_breadth) &&
            Number(e?.gb_height)
            ? (Number(e?.gb_length) *
              Number(e?.gb_breadth) *
              Number(e?.gb_height) *
              0.52).toFixed(2).toString()
            : ""
        }))
      }
      setIsBtnLoading(true);
      if (isFormUpdating) {
        const res = await patchDirectData(`items/radiology/${payloadForRadiology?.id}`, payload);
        if (res.isSuccess) {
          showToast({
            type: "success",
            message: "Radiology form updated successfully",
          });
          handleNextTab();
        } else {
          showToast({
            type: "error",
            message: res?.message || "Something went wrong",
          });
        }
      } else {
        const res = await postDirectData(`items/radiology`, payload);
        if (res.isSuccess) {
          showToast({
            type: "success",
            message: "Radiology form submitted successfully",
          });
          handleNextTab();
        } else {
          showToast({
            type: "error",
            message: res?.message || "Something went wrong",
          });
        }
      }
      setIsBtnLoading(false);
    }
    else {
      showToast(
        {
          type: "error",
          message: "Something went wrong",
        }
      )
    }
  }
  return {
    payloadForRadiology,
    gbef,
    setGbef,
    setPayloadForRadiology,
    files,
    mediaFiles,
    setFiles,
    handleUploadFiles,
    uploadFileModalIsOpen,
    setUploadFileModalIsOpen,
    isMediaBtnLoading,
    handleOpenMediaInNewTab,
    handleDeleteImageFromAws,
    isOpenDeleteConfirmModal,
    setIsOpenDeleteConfirmModal,
    handleCheckboxChange,
    gbefStaus,
    setReasonForGBEFNotDone,
    reasonforGBEFNotDone
  };

}
