type Props = {
  title: string;
  description?: string;
  end?: JSX.Element;
  titleImage?: JSX.Element ;
};

export function EPageTitle({ title,titleImage, description, end }: Readonly<Props>) {
  return (
    <div className="md:flex md:justify-between items-center mb-3 w-full mt-3">
        {/* {end ? <div className="right flex flex-row gap-1">{end}</div> : null} */}
      {
        titleImage ?
        <div className="mr-2">
          {titleImage}
        </div>
       :<></>
      }
      <div className="flex-1 mb-2 md:mb-0">
        <div className="text-3xl font-semibold text text-slate-900">
          {title}
        </div>
        <p className="text-base text-slate-500 font-medium pt-1">{description}</p>
      </div>
      {end ? <div className="right flex flex-row gap-1">{end}</div> : null}
    </div>
  );
}
