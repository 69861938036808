import { useState } from "react";
import {
  IPatientFollowupsDetails,
  IPatientInformation,
} from "../../../libs/data-access/types";
import { useDirectusApi } from "../../../libs/data-access";

export default function usePatientList() {
  const { fetchDirectData } = useDirectusApi();
  const [patientList, setPatientList] = useState<IPatientInformation[]>([]);
  const [totalPatients, setTotalPatients] = useState<number>(0)
  const [totalPage, setTotalPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState<string>("");
  const [latestFollowup, setLatestFollowup] =
    useState<IPatientFollowupsDetails>();

  const [selectedFilter, setSelectedFilter] = useState<"included" | "excluded" | "all">("all");
  const handleGetPatientList = async (cp: number, id?: string) => {
    setPatientList([]);
    setIsLoading(true);
    let [firstName, lastName] = filterValue.split(" ");

    const inclusionStatusFilter = selectedFilter !== "all"
      ? `&filter[_and][0][_and][0][inclusion_status][_eq]=${selectedFilter}`
      : "";

    let searchQuery = lastName ? `&filter[_and][0][_and][0][first_name][_icontains]=${firstName}
&filter[_and][0][_and][1][last_name][_icontains]=${lastName}
` : `&filter[_or][0][first_name][_icontains]=${firstName}
&filter[_or][1][last_name][_icontains]=${lastName || firstName}
&filter[_or][2][primary_contact_no][_icontains]=${filterValue}
&filter[_or][3][alternate_contact_no][_icontains]=${filterValue}
&filter[_or][4][hospital_no][_icontains]=${filterValue}
`

    let patientProfileData = await fetchDirectData(
      `items/patiant_profile?meta=filter_count&limit=10&page=${cp}&fields[]=id,age,alternate_contact_no,secondary_alternate_contact_no,recruitment_date,hospital_no,follow_ups.id,follow_ups.user_created,follow_ups.date_created,follow_ups.user_created_name,follow_ups.remarks,first_name,last_name,email,primary_contact_no,alternate_contact_no,secondary_alternate_contact_no,dob,gender,father_name,mother_name,address,center_id,whatsapp_no,postal_code,hospital_no,admission_no,ward_no,bed_no,physician_in_charge,user_id, surgeon_in_charge&filter[status]=published&sort[]=date_created${id ? `&filter[_and][0][_and][0][center_id][id][_eq]=${id}` : ""
      }${inclusionStatusFilter ? inclusionStatusFilter : ""}${filterValue ? searchQuery : ""
      }`
    );
    if (patientProfileData?.data) {
      setTotalPatients(patientProfileData?.data?.meta?.filter_count)
      setTotalPage(
        Math.ceil(patientProfileData?.data?.meta?.filter_count / 10)
      );
      setCurrentPage(cp)
      const formattedData: IPatientInformation[] = patientProfileData?.data?.data.map(
        (item: IPatientInformation) => {
          const sortedFollowUps = item.follow_ups?.sort(
            (a, b) =>
              new Date(b.date_created).getTime() -
              new Date(a.date_created).getTime()
          );
          return {
            ...item,
            primary_contact_no: item?.primary_contact_no,
            follow_ups: sortedFollowUps ? sortedFollowUps : [],
          };
        }
      );

      const leftForFollowup = formattedData
        ?.filter((item) => !item.follow_ups?.length)
        ?.sort((a, b) => a.first_name.localeCompare(b.first_name));

      let followUpListInDescendingOrder = formattedData
        ?.filter((item) => item.follow_ups.length > 0) // Filter out items with no follow-ups
        .sort(
          (a: IPatientInformation, b: IPatientInformation) =>
            new Date(a.follow_ups[0].date_created).getTime() -
            new Date(b.follow_ups[0].date_created).getTime()
        );

      followUpListInDescendingOrder = [
        ...leftForFollowup,
        ...followUpListInDescendingOrder,
      ];
      setPatientList(followUpListInDescendingOrder);
    } else {
      setPatientList([]);
    }
    setIsLoading(false)
  };

  return {
    isLoading,
    filterValue,
    setFilterValue,
    patientList,
    setPatientList,
    setSelectedFilter,
    selectedFilter,
    handleGetPatientList,
    totalPatients,
    totalPage,
    currentPage
  };
}
