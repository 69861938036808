import { TextInput, TextInputProps } from '@mantine/core'
import React from 'react'

export function ETextInput(props:TextInputProps) {
  return (
    <TextInput 
    label={props.label}
    size={props.size}
    rightSection={props.rightSection}
    rightSectionWidth={props.rightSectionWidth}
    className={props.className}
    type={props.type}
    description={props.description}
    value={props.value?.toString().replaceAll("undefined", "") }
    maxLength={props.maxLength ? props.maxLength : 100}
    placeholder={props.placeholder}
    error={props.error}
    onChange={props.onChange}
    required={props.required}
    disabled={props.disabled}
    readOnly={props.readOnly}
    />
  )
}
